export const ADMIN_PROJECTSOFTS_REQUEST = 'ADMIN_PROJECTSOFTS_REQUEST'
export const ADMIN_PROJECTSOFTS_SUCCESS = 'ADMIN_PROJECTSOFTS_SUCCESS'
export const ADMIN_PROJECTSOFTS_FAIL = 'ADMIN_PROJECTSOFTS_FAIL'

export const ALL_PROJECTSOFTS_REQUEST = 'ALL_PROJECTSOFTS_REQUEST'
export const ALL_PROJECTSOFTS_SUCCESS = 'ALL_PROJECTSOFTS_SUCCESS'
export const ALL_PROJECTSOFTS_FAIL = 'ALL_PROJECTSOFTS_FAIL'


export const NEW_PROJECTSOFT_REQUEST = 'NEW_PROJECTSOFT_REQUEST'
export const NEW_PROJECTSOFT_SUCCESS = 'NEW_PROJECTSOFT_SUCCESS'
export const NEW_PROJECTSOFT_RESET = 'NEW_PROJECTSOFT_RESET'
export const NEW_PROJECTSOFT_FAIL = 'NEW_PROJECTSOFT_FAIL'

export const DELETE_PROJECTSOFT_REQUEST = 'DELETE_PROJECTSOFT_REQUEST'
export const DELETE_PROJECTSOFT_SUCCESS = 'DELETE_PROJECTSOFT_SUCCESS'
export const DELETE_PROJECTSOFT_RESET = 'DELETE_PROJECTSOFT_RESET'
export const DELETE_PROJECTSOFT_FAIL = 'DELETE_PROJECTSOFT_FAIL'

export const UPDATE_PROJECTSOFT_REQUEST = 'UPDATE_PROJECTSOFT_REQUEST'
export const UPDATE_PROJECTSOFT_SUCCESS = 'UPDATE_PROJECTSOFT_SUCCESS'
export const UPDATE_PROJECTSOFT_RESET = 'UPDATE_PROJECTSOFT_RESET'
export const UPDATE_PROJECTSOFT_FAIL = 'UPDATE_PROJECTSOFT_FAIL'

export const PROJECTSOFT_DETAILS_REQUEST = 'PROJECTSOFT_DETAILS_REQUEST'
export const PROJECTSOFT_DETAILS_SUCCESS = 'PROJECTSOFT_DETAILS_SUCCESS'
export const PROJECTSOFT_DETAILS_FAIL = 'PROJECTSOFT_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'