import axios from 'axios'

import { 
    ALL_PROJECTPAINTINGS_REQUEST, 
    ALL_PROJECTPAINTINGS_SUCCESS, 
    ALL_PROJECTPAINTINGS_FAIL, 

    ADMIN_PROJECTPAINTINGS_REQUEST, 
    ADMIN_PROJECTPAINTINGS_SUCCESS, 
    ADMIN_PROJECTPAINTINGS_FAIL,

    NEW_PROJECTPAINTING_REQUEST, 
    NEW_PROJECTPAINTING_SUCCESS, 
    NEW_PROJECTPAINTING_FAIL, 

    DELETE_PROJECTPAINTING_REQUEST, 
    DELETE_PROJECTPAINTING_SUCCESS,
    DELETE_PROJECTPAINTING_FAIL,

    UPDATE_PROJECTPAINTING_REQUEST, 
    UPDATE_PROJECTPAINTING_SUCCESS,
    UPDATE_PROJECTPAINTING_FAIL, 

    PROJECTPAINTING_DETAILS_REQUEST,
    PROJECTPAINTING_DETAILS_SUCCESS,
    PROJECTPAINTING_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectpaintingConstants";

export const getProjectpaintings = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTPAINTINGS_REQUEST })

        let link = `/api/v1/projectpaintings?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectpaintings?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectpaintings?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTPAINTINGS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTPAINTINGS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectpainting = (projectpaintingData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTPAINTING_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectpainting/new`, projectpaintingData, config)

        dispatch({
            type: NEW_PROJECTPAINTING_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTPAINTING_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectpainting (Admin)
export const deleteProjectpainting = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTPAINTING_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectpainting/${id}`)

        dispatch({
            type: DELETE_PROJECTPAINTING_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTPAINTING_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectpainting (admin)
export const updateProjectpainting = (id, projectpaintingData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTPAINTING_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectpainting/${id}`, projectpaintingData, config)

        dispatch({
            type: UPDATE_PROJECTPAINTING_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTPAINTING_FAIL,
            payload: error.response.data.message
        })
    }
}


export const getProjectpaintingDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTPAINTING_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectpainting/${id}`)

        dispatch({
            type: PROJECTPAINTING_DETAILS_SUCCESS,
            payload: data.projectpainting
        })

    } catch (error) {
        dispatch({
            type: PROJECTPAINTING_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectpaintings = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTPAINTINGS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectpaintings`)

        dispatch({
            type: ADMIN_PROJECTPAINTINGS_SUCCESS,
            payload: data.projectpaintings
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTPAINTINGS_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}