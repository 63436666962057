import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Searchfabric = () => {


    const [keyword, setKeyword] = useState('');
    const navigate = useNavigate();

    const searchHandler = (e) => {
        e.preventDefault()

        if(keyword.trim()) {
            navigate(`/searchfabric/${keyword}`)
        } else{
            navigate('/')
        }
    }

  return (

    <form onSubmit={searchHandler}>
      <div className="input-group">
        <input
          type="text"
          id="search_field"
          className="form-control"
          placeholder="Enter Project Title..."
          onChange={(e) => setKeyword(e.target.value)}
        ></input>
        <div className="input-group-append">
          <button id="search_btn" className="btn">
            <i className="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </form>
  )
}

export default Searchfabric
