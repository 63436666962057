export const ADMIN_PROJECTWELDINGS_REQUEST = 'ADMIN_PROJECTWELDINGS_REQUEST'
export const ADMIN_PROJECTWELDINGS_SUCCESS = 'ADMIN_PROJECTWELDINGS_SUCCESS'
export const ADMIN_PROJECTWELDINGS_FAIL = 'ADMIN_PROJECTWELDINGS_FAIL'

export const ALL_PROJECTWELDINGS_REQUEST = 'ALL_PROJECTWELDINGS_REQUEST'
export const ALL_PROJECTWELDINGS_SUCCESS = 'ALL_PROJECTWELDINGS_SUCCESS'
export const ALL_PROJECTWELDINGS_FAIL = 'ALL_PROJECTWELDINGS_FAIL'

export const NEW_PROJECTWELDING_REQUEST = 'NEW_PROJECTWELDING_REQUEST'
export const NEW_PROJECTWELDING_SUCCESS = 'NEW_PROJECTWELDING_SUCCESS'
export const NEW_PROJECTWELDING_RESET = 'NEW_PROJECTWELDING_RESET'
export const NEW_PROJECTWELDING_FAIL = 'NEW_PROJECTWELDING_FAIL'

export const DELETE_PROJECTWELDING_REQUEST = 'DELETE_PROJECTWELDING_REQUEST'
export const DELETE_PROJECTWELDING_SUCCESS = 'DELETE_PROJECTWELDING_SUCCESS'
export const DELETE_PROJECTWELDING_RESET = 'DELETE_PROJECTWELDING_RESET'
export const DELETE_PROJECTWELDING_FAIL = 'DELETE_PROJECTWELDING_FAIL'

export const UPDATE_PROJECTWELDING_REQUEST = 'UPDATE_PROJECTWELDING_REQUEST'
export const UPDATE_PROJECTWELDING_SUCCESS = 'UPDATE_PROJECTWELDING_SUCCESS'
export const UPDATE_PROJECTWELDING_RESET = 'UPDATE_PROJECTWELDING_RESET'
export const UPDATE_PROJECTWELDING_FAIL = 'UPDATE_PROJECTWELDING_FAIL'

export const PROJECTWELDING_DETAILS_REQUEST = 'PROJECTWELDING_DETAILS_REQUEST'
export const PROJECTWELDING_DETAILS_SUCCESS = 'PROJECTWELDING_DETAILS_SUCCESS'
export const PROJECTWELDING_DETAILS_FAIL = 'PROJECTWELDING_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'