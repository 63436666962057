export const ADMIN_PROJECTPLANTS_REQUEST = 'ADMIN_PROJECTPLANTS_REQUEST'
export const ADMIN_PROJECTPLANTS_SUCCESS = 'ADMIN_PROJECTPLANTS_SUCCESS'
export const ADMIN_PROJECTPLANTS_FAIL = 'ADMIN_PROJECTPLANTS_FAIL'

export const ALL_PROJECTPLANTS_REQUEST = 'ALL_PROJECTPLANTS_REQUEST'
export const ALL_PROJECTPLANTS_SUCCESS = 'ALL_PROJECTPLANTS_SUCCESS'
export const ALL_PROJECTPLANTS_FAIL = 'ALL_PROJECTPLANTS_FAIL'

export const NEW_PROJECTPLANT_REQUEST = 'NEW_PROJECTPLANT_REQUEST'
export const NEW_PROJECTPLANT_SUCCESS = 'NEW_PROJECTPLANT_SUCCESS'
export const NEW_PROJECTPLANT_RESET = 'NEW_PROJECTPLANT_RESET'
export const NEW_PROJECTPLANT_FAIL = 'NEW_PROJECTPLANT_FAIL'

export const DELETE_PROJECTPLANT_REQUEST = 'DELETE_PROJECTPLANT_REQUEST'
export const DELETE_PROJECTPLANT_SUCCESS = 'DELETE_PROJECTPLANT_SUCCESS'
export const DELETE_PROJECTPLANT_RESET = 'DELETE_PROJECTPLANT_RESET'
export const DELETE_PROJECTPLANT_FAIL = 'DELETE_PROJECTPLANT_FAIL'

export const UPDATE_PROJECTPLANT_REQUEST = 'UPDATE_PROJECTPLANT_REQUEST'
export const UPDATE_PROJECTPLANT_SUCCESS = 'UPDATE_PROJECTPLANT_SUCCESS'
export const UPDATE_PROJECTPLANT_RESET = 'UPDATE_PROJECTPLANT_RESET'
export const UPDATE_PROJECTPLANT_FAIL = 'UPDATE_PROJECTPLANT_FAIL'

export const PROJECTPLANT_DETAILS_REQUEST = 'PROJECTPLANT_DETAILS_REQUEST'
export const PROJECTPLANT_DETAILS_SUCCESS = 'PROJECTPLANT_DETAILS_SUCCESS'
export const PROJECTPLANT_DETAILS_FAIL = 'PROJECTPLANT_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'