import { 
    ALL_PROJECTFURNITURES_REQUEST,
    ALL_PROJECTFURNITURES_SUCCESS, 
    ALL_PROJECTFURNITURES_FAIL, 

    ADMIN_PROJECTFURNITURES_REQUEST, 
    ADMIN_PROJECTFURNITURES_SUCCESS, 
    ADMIN_PROJECTFURNITURES_FAIL, 

    NEW_PROJECTFURNITURE_REQUEST, 
    NEW_PROJECTFURNITURE_SUCCESS, 
    NEW_PROJECTFURNITURE_RESET,
    NEW_PROJECTFURNITURE_FAIL, 

    DELETE_PROJECTFURNITURE_REQUEST, 
    DELETE_PROJECTFURNITURE_SUCCESS, 
    DELETE_PROJECTFURNITURE_RESET,
    DELETE_PROJECTFURNITURE_FAIL, 

    UPDATE_PROJECTFURNITURE_REQUEST, 
    UPDATE_PROJECTFURNITURE_SUCCESS, 
    UPDATE_PROJECTFURNITURE_RESET,
    UPDATE_PROJECTFURNITURE_FAIL, 

    PROJECTFURNITURE_DETAILS_REQUEST,
    PROJECTFURNITURE_DETAILS_SUCCESS,
    PROJECTFURNITURE_DETAILS_FAIL,
    CLEAR_ERRORS
 } from "../constants/projectfurnitureConstants";

 export const projectfurnituresReducer = (state = { projectfurnitures: [] }, action) => {
    switch(action.type) {
        case ALL_PROJECTFURNITURES_REQUEST:
        case ADMIN_PROJECTFURNITURES_REQUEST:
            return {
                loading: true,
                projectfurnitures: [] 
            }
        case ALL_PROJECTFURNITURES_SUCCESS:
            return {
                loading: false,
                projectfurnitures: action.payload.projectfurnitures,
                projectfurnituresCount: action.payload.projectfurnituresCount,
                resPerPage: action.payload.resPerPage
               
            }

            case ADMIN_PROJECTFURNITURES_SUCCESS:
                return {
                    loading: false,
                    projectfurnitures: action.payload
                    }
        case ALL_PROJECTFURNITURES_FAIL:
            case ADMIN_PROJECTFURNITURES_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}


export const newProjectfurnitureReducer = (state = { projectfurniture: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTFURNITURE_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTFURNITURE_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectfurniture: action.payload.projectfurniture
            } 
               
        case NEW_PROJECTFURNITURE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTFURNITURE_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectfurnitureReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTFURNITURE_REQUEST:
        case UPDATE_PROJECTFURNITURE_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTFURNITURE_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTFURNITURE_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTFURNITURE_FAIL:
        case UPDATE_PROJECTFURNITURE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTFURNITURE_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTFURNITURE_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}



export const projectfurnitureDetailsReducer = (state = {projectfurniture: {} }, action ) => {
    switch (action.type) {

        case  PROJECTFURNITURE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTFURNITURE_DETAILS_SUCCESS:
            return {
                loading: false,
                projectfurniture: action.payload
            }
        case  PROJECTFURNITURE_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}