import { 
    ALL_PROJECTCEILINGS_REQUEST ,
    ALL_PROJECTCEILINGS_SUCCESS, 
    ALL_PROJECTCEILINGS_FAIL, 

    ADMIN_PROJECTCEILINGS_REQUEST, 
    ADMIN_PROJECTCEILINGS_SUCCESS, 
    ADMIN_PROJECTCEILINGS_FAIL, 

    NEW_PROJECTCEILING_REQUEST, 
    NEW_PROJECTCEILING_SUCCESS, 
    NEW_PROJECTCEILING_RESET,
    NEW_PROJECTCEILING_FAIL, 

    DELETE_PROJECTCEILING_REQUEST, 
    DELETE_PROJECTCEILING_SUCCESS, 
    DELETE_PROJECTCEILING_RESET,
    DELETE_PROJECTCEILING_FAIL, 

    UPDATE_PROJECTCEILING_REQUEST, 
    UPDATE_PROJECTCEILING_SUCCESS, 
    UPDATE_PROJECTCEILING_RESET,
    UPDATE_PROJECTCEILING_FAIL, 

    PROJECTCEILING_DETAILS_REQUEST,
    PROJECTCEILING_DETAILS_SUCCESS,
    PROJECTCEILING_DETAILS_FAIL,
    CLEAR_ERRORS

} from "../constants/projectceilingConstants";

export const projectceilingsReducer = (state = { projectceilings: [] }, action) => {
    switch(action.type) {
        case ALL_PROJECTCEILINGS_REQUEST:
        case ADMIN_PROJECTCEILINGS_REQUEST:
            return {
                loading: true,
                projectceilings: [] 
            }
        case ALL_PROJECTCEILINGS_SUCCESS:
            return {
                loading: false,
                projectceilings: action.payload.projectceilings,
                projectceilingsCount: action.payload.projectceilingsCount,
                resPerPage: action.payload.resPerPage
               
            }
            case ADMIN_PROJECTCEILINGS_SUCCESS:
                return {
                    loading: false,
                    projectceilings: action.payload
                    }            

            
        case ALL_PROJECTCEILINGS_FAIL:
            case ADMIN_PROJECTCEILINGS_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}


export const newProjectceilingReducer = (state = { projectceiling: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTCEILING_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTCEILING_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectceiling: action.payload.projectceiling
            } 
               
        case NEW_PROJECTCEILING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTCEILING_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}


export const projectceilingReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTCEILING_REQUEST:
        case UPDATE_PROJECTCEILING_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTCEILING_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTCEILING_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTCEILING_FAIL:
        case UPDATE_PROJECTCEILING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTCEILING_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTCEILING_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectceilingDetailsReducer = (state = {projectceiling: {} }, action ) => {
    switch (action.type) {

        case  PROJECTCEILING_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTCEILING_DETAILS_SUCCESS:
            return {
                loading: false,
                projectceiling: action.payload
            }
        case  PROJECTCEILING_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}