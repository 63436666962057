export const ADMIN_PROJECTPAINTINGS_REQUEST = 'ADMIN_PROJECTPAINTINGS_REQUEST'
export const ADMIN_PROJECTPAINTINGS_SUCCESS = 'ADMIN_PROJECTPAINTINGS_SUCCESS'
export const ADMIN_PROJECTPAINTINGS_FAIL = 'ADMIN_PROJECTPAINTINGS_FAIL'

export const ALL_PROJECTPAINTINGS_REQUEST = 'ALL_PROJECTPAINTINGS_REQUEST'
export const ALL_PROJECTPAINTINGS_SUCCESS = 'ALL_PROJECTPAINTINGS_SUCCESS'
export const ALL_PROJECTPAINTINGS_FAIL = 'ALL_PROJECTPAINTINGS_FAIL'

export const NEW_PROJECTPAINTING_REQUEST = 'NEW_PROJECTPAINTING_REQUEST'
export const NEW_PROJECTPAINTING_SUCCESS = 'NEW_PROJECTPAINTING_SUCCESS'
export const NEW_PROJECTPAINTING_RESET = 'NEW_PROJECTPAINTING_RESET'
export const NEW_PROJECTPAINTING_FAIL = 'NEW_PROJECTPAINTING_FAIL'

export const DELETE_PROJECTPAINTING_REQUEST = 'DELETE_PROJECTPAINTING_REQUEST'
export const DELETE_PROJECTPAINTING_SUCCESS = 'DELETE_PROJECTPAINTING_SUCCESS'
export const DELETE_PROJECTPAINTING_RESET = 'DELETE_PROJECTPAINTING_RESET'
export const DELETE_PROJECTPAINTING_FAIL = 'DELETE_PROJECTPAINTING_FAIL'

export const UPDATE_PROJECTPAINTING_REQUEST = 'UPDATE_PROJECTPAINTING_REQUEST'
export const UPDATE_PROJECTPAINTING_SUCCESS = 'UPDATE_PROJECTPAINTING_SUCCESS'
export const UPDATE_PROJECTPAINTING_RESET = 'UPDATE_PROJECTPAINTING_RESET'
export const UPDATE_PROJECTPAINTING_FAIL = 'UPDATE_PROJECTPAINTING_FAIL'

export const PROJECTPAINTING_DETAILS_REQUEST = 'PROJECTPAINTING_DETAILS_REQUEST'
export const PROJECTPAINTING_DETAILS_SUCCESS = 'PROJECTPAINTING_DETAILS_SUCCESS'
export const PROJECTPAINTING_DETAILS_FAIL = 'PROJECTPAINTING_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'