import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Home from './components/Home';


import Shop from './components/Shop';
import ProductDetails from './components/product/ProductDetails';
import Ceiling from './components/Ceiling';
import ProjectceilingDetails from './components/ceiling/ProjectceilingDetails';
import Accesory from './components/Accesory';
import ProjectaccesoryDetails from './components/accesorry/ProjectaccesoryDetails';
import Architecture from './components/Architecture';
import ProjectarchDetails from './components/architecture/ProjectarchDetails';
import Fabric from './components/Fabric';
import ProjectfabricDetails from './components/fabric/ProjectfabricDetails';
import Fineart from './components/Fineart';
import ProjectartDetails from './components/art/ProjectartDetails';
import Floor from './components/Floor';
import ProjectfloorDetails from './components/floor/ProjectfloorDetails';
import Furniture from './components/Furniture';
import ProjectfurnitureDetails from './components/furniture/ProjectfurnitureDetails';
import Kitchen from './components/Kitchen';
import ProjectkitchenDetails from './components/kitchen/ProjectkitchenDetails';
import Landscape from './components/Landscape';
import ProjectlandscapeDetails from './components/landscape/ProjectlandscapeDetails';
import Lighting from './components/Lighting';
import ProjectlightingDetails from './components/lighting/ProjectlightingDetails';
import Painting from './components/Painting';
import ProjectpaintingDetails from './components/painting/ProjectpaintingDetails';
import Plants from './components/Plants';
import ProjectplantDetails from './components/plant/ProjectplantDetails';
import Softs from './components/Softs';
import ProjectsoftDetails from './components/soft/ProjectsoftDetails';
import Walls from './components/Walls';
import ProjectwallDetails from './components/wall/ProjectwallDetails';
import Water from './components/Water';
import ProjectwaterDetails from './components/water/ProjectwaterDetails';
import Welding from './components/Welding';
import ProjectweldingDetails from './components/welding/ProjectweldingDetails';

import Cart from './components/cart/Cart';
import Shipping from './components/cart/Shipping';
import ConfirmOrder from './components/cart/ConfirmOrder';
import Payment from './components/cart/Payment';
import OrderSuccess from './components/cart/OrderSuccess';

import ListOrders from './components/order/ListOrders';
import OrderDetails from './components/order/OrderDetails';
import OrdersList from './components/admin/OrdersList';
import ProcessOrder from './components/admin/ProcessOrder';
import UsersList from './components/admin/UsersList';
import UpdateUser from './components/admin/UpdateUser'
import ProductReviews from './components/admin/ProductReviews';

import Login from './components/user/Login';
import Register from './components/user/Register';
import Profile from './components/user/Profile';
import UpdateProfile from './components/user/UpdateProfile'
import UpdatePassword from './components/user/UpdatePassword';
import ForgotPassword from './components/user/ForgotPassword';
import NewPassword from './components/user/NewPassword';


import Dashboard from './components/admin/Dashboard';





import ProtectedRoute from './components/route/ProtectedRoute';
import { loadUser } from './actions/userActions';
import { useSelector } from 'react-redux'
import store from './store'
import axios from 'axios'


//Payment
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import ProductsList from './components/admin/ProductsList';
import NewProduct from './components/admin/NewProduct'
import UpdateProduct from './components/admin/UpdateProduct';


import NewProjectaccesory from './components/admin/NewProjectaccesory';
import UpdateProjectaccesory from './components/admin/UpdateProjectaccesory';

import NewProjectarch from './components/admin/NewProjectarch';
import UpdateProjectarch from './components/admin/UpdateProjectarch';

import NewProjectart from './components/admin/NewProjectart';
import UpdateProjectart from './components/admin/UpdateProjectart';

import NewProjectceiling from './components/admin/NewProjectceiling';
import UpdateProjectceiling from './components/admin/UpdateProjectceiling';

import NewProjectfabric from './components/admin/NewProjectfabric';
import UpdateProjectfabric from './components/admin/UpdateProjectfabric';

import NewProjectfloor from './components/admin/NewProjectfloor';
import UpdateProjectfloor from './components/admin/UpdateProjectfloor';

import NewProjectfurniture from './components/admin/NewProjectfurniture';
import UpdateProjectfurniture from './components/admin/UpdateProjectfurniture';

import NewProjectkitchen from './components/admin/NewProjectkitchen';
import UpdateProjectkitchen from './components/admin/UpdateProjectkitchen';

import NewProjectlandscape from './components/admin/NewProjectlandscape';
import UpdateProjectlandscape from './components/admin/UpdateProjectlandscape';

import NewProjectlighting from './components/admin/NewProjectlighting';
import UpdateProjectlighting from './components/admin/UpdateProjectlighting';

import NewProjectpainting from './components/admin/NewProjectpainting';
import UpdateProjectpainting from './components/admin/UpdateProjectpainting';

import NewProjectplant from './components/admin/NewProjectplant';
import UpdateProjectplant from './components/admin/UpdateProjectplant';

import NewProjectsoft from './components/admin/NewProjectsoft';
import UpdateProjectsoft from './components/admin/UpdateProjectsoft';

import NewProjectwall from './components/admin/NewProjectwall';
import UpdateProjectwall from './components/admin/UpdateProjectwall';

import NewProjectwater from './components/admin/NewProjectwater';
import UpdateProjectwater from './components/admin/UpdateProjectwater';


import NewProjectwelding from './components/admin/NewProjectwelding';
import UpdateProjectwelding from './components/admin/UpdateProjectwelding';


import ProjectaccesoriesList from './components/admin/ProjectaccesoriesList';
import ProjectarchsList from './components/admin/ProjectarchsList';
import ProjectartsList from './components/admin/ProjectartsList';
import ProjectceilingsList from './components/admin/ProjectceilingsList';
import ProjectfabricsList from './components/admin/ProjectfabricsList';
import ProjectfloorsList from './components/admin/ProjectfloorsList';
import ProjectfurnituresList from './components/admin/ProjectfurnituresList';
import ProjectkitchensList from './components/admin/ProjectkitchensList';
import ProjectlandscapesList from './components/admin/ProjectlandscapesList';
import ProjectlightingsList from './components/admin/ProjectlightingsList';
import ProjectpaintingsList from './components/admin/ProjectpaintingsList';
import ProjectplantsList from './components/admin/ProjectplantsList';
import ProjectsoftsList from './components/admin/ProjectsoftsList';
import ProjectwallsList from './components/admin/ProjectwallsList';
import ProjectwatersList from './components/admin/ProjectwatersList';
import ProjectweldingsList from './components/admin/ProjectweldingsList';



function App() {

  const [stripeApiKey, setStripeApiKey] = useState('');

  useEffect(() => {
    store.dispatch(loadUser())

    async function getStripeApiKey() {
      const { data } = await axios.get('/api/v1/stripeapi');
      setStripeApiKey(data.stripeApiKey)
    }

    getStripeApiKey();
  }, [])

  const { user, isAuthenticated, loading } = useSelector((state) => state.auth)
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="container container-fluid">
          <Routes> // added
            <Route path="/" element={<Home />} />
            <Route path="/Shop" element={<Shop />} />
            <Route path="/search/:keyword" element={<Shop />} />
            <Route path="/product/:id" element={<ProductDetails />} />
            <Route path="/Ceiling" element={<Ceiling />} />
            <Route path="/searchaccesory/:keyword" element={<Shop />} />
            <Route path="/searcharch/:keyword" element={<Shop />} />
            <Route path="/searchart/:keyword" element={<Shop />} />
            <Route path="/searchceiling/:keyword" element={<Shop />} />
            <Route path="/searchfabric/:keyword" element={<Shop />} />
            <Route path="/searchfloor/:keyword" element={<Shop />} />
            <Route path="/searchfurniture/:keyword" element={<Shop />} />
            <Route path="/searchkitchen/:keyword" element={<Shop />} />
            <Route path="/searchlandscape/:keyword" element={<Shop />} />
            <Route path="/searchlighting/:keyword" element={<Shop />} />
            <Route path="/searchpainting/:keyword" element={<Shop />} />
            <Route path="/searchplant/:keyword" element={<Shop />} />
            <Route path="/searchsoft/:keyword" element={<Shop />} />
            <Route path="/searchwall/:keyword" element={<Shop />} />
            <Route path="/searchwater/:keyword" element={<Shop />} />
            <Route path="/searchwelding/:keyword" element={<Shop />} />
            <Route path="/projectceiling/:id" element={<ProjectceilingDetails />} />
            <Route path="/Accesory" element={<Accesory />} />
            <Route path="/projectaccesory/:id" element={<ProjectaccesoryDetails />} />
            <Route path="/Architecture" element={<Architecture />} />
            <Route path="/projectarch/:id" element={<ProjectarchDetails />} />
            <Route path="/Fabric" element={<Fabric />} />
            <Route path="/projectfabric/:id" element={<ProjectfabricDetails />} />
            <Route path="/Fineart" element={<Fineart />} />
            <Route path="/projectart/:id" element={<ProjectartDetails />} />
            <Route path="/Floor" element={<Floor />} />
            <Route path="/projectfloor/:id" element={<ProjectfloorDetails />} />
            <Route path="/Furniture" element={<Furniture />} />
            <Route path="/projectfurniture/:id" element={<ProjectfurnitureDetails />} />
            <Route path="/Kitchen" element={<Kitchen />} />
            <Route path="/projectkitchen/:id" element={<ProjectkitchenDetails />} />
            <Route path="/Landscape" element={<Landscape />} />
            <Route path="/projectlandscape/:id" element={<ProjectlandscapeDetails />} />
            <Route path="/Lighting" element={<Lighting />} />
            <Route path="/projectlighting/:id" element={<ProjectlightingDetails />} />
            <Route path="/Painting" element={<Painting />} />
            <Route path="/projectpainting/:id" element={<ProjectpaintingDetails />} />
            <Route path="/Plants" element={<Plants />} />
            <Route path="/projectplant/:id" element={<ProjectplantDetails />} />
            <Route path="/Softs" element={<Softs />} />
            <Route path="/projectsoft/:id" element={<ProjectsoftDetails />} />
            <Route path="/Walls" element={<Walls />} />
            <Route path="/projectwall/:id" element={<ProjectwallDetails />} />
            <Route path="/Water" element={<Water />} />
            <Route path="/projectwater/:id" element={<ProjectwaterDetails />} />
            <Route path="/Welding" element={<Welding />} />
            <Route path="/projectwelding/:id" element={<ProjectweldingDetails />} />

            <Route path="/cart" element={<Cart />} />
            <Route path="/Shipping" element={<ProtectedRoute><Shipping /></ProtectedRoute>} /> 
            <Route path="/order/confirm" element={<ProtectedRoute><ConfirmOrder /></ProtectedRoute>} /> 
            <Route path="/success" element={<ProtectedRoute><OrderSuccess /></ProtectedRoute>} /> 
            

            {stripeApiKey && (
              <Route path="/payment" element={(<Elements stripe={loadStripe(stripeApiKey)}><Payment /></Elements>)} />
            )}


            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/password/forgot" element={<ForgotPassword />} />
            <Route path="/password/reset/:token" element={<NewPassword />} />

            <Route path="/me" element={<ProtectedRoute ><Profile /></ProtectedRoute>} /> 
            <Route path="/me/update" element={<ProtectedRoute><UpdateProfile /></ProtectedRoute>} /> 
            <Route path="/password/update" element={<ProtectedRoute><UpdatePassword /></ProtectedRoute>} /> 

            <Route path="/orders/me" element={<ProtectedRoute><ListOrders/></ProtectedRoute>} /> 
            <Route path="/order/:id" element={<ProtectedRoute><OrderDetails/></ProtectedRoute>} /> 
            <Route path="/admin/orders" element={<ProtectedRoute><OrdersList/></ProtectedRoute>} /> 
            <Route path="/admin/order/:id" element={<ProtectedRoute><ProcessOrder/></ProtectedRoute>} /> 
            <Route path="/admin/users" element={<ProtectedRoute><UsersList/></ProtectedRoute>} /> 
            <Route path="/admin/user/:id" element={<ProtectedRoute><UpdateUser/></ProtectedRoute>} /> 
            <Route path="/admin/reviews" element={<ProtectedRoute><ProductReviews/></ProtectedRoute>} /> 



          </Routes>
        </div>

        <Routes>
        <Route path="/dashboard" isAdmin={true} element={<ProtectedRoute><Dashboard/></ProtectedRoute>} /> 


        <Route path="/admin/products" isAdmin={true} element={<ProtectedRoute><ProductsList/></ProtectedRoute>} /> 
       <Route path="/admin/product" isAdmin={true} element={<ProtectedRoute><NewProduct/></ProtectedRoute>} /> 
       <Route path="/admin/product/:id" isAdmin={true} element={<ProtectedRoute><UpdateProduct/></ProtectedRoute>} /> 



       <Route path="/admin/projectaccesory" isAdmin={true} element={<ProtectedRoute><NewProjectaccesory/></ProtectedRoute>} />
       <Route path="/admin/projectaccesories" isAdmin={true} element={<ProtectedRoute><ProjectaccesoriesList/></ProtectedRoute>} /> 
       <Route path="/admin/projectaccesory/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectaccesory/></ProtectedRoute>} /> 


        <Route path="/admin/projectarch" isAdmin={true} element={<ProtectedRoute><NewProjectarch/></ProtectedRoute>} />
        <Route path="/admin/projectarchs" isAdmin={true} element={<ProtectedRoute><ProjectarchsList/></ProtectedRoute>} /> 
       <Route path="/admin/projectarch/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectarch/></ProtectedRoute>} /> 

        <Route path="/admin/projectart" isAdmin={true} element={<ProtectedRoute><NewProjectart/></ProtectedRoute>} />
        <Route path="/admin/projectarts" isAdmin={true} element={<ProtectedRoute><ProjectartsList/></ProtectedRoute>} /> 
        <Route path="/admin/projectart/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectart/></ProtectedRoute>} /> 

        <Route path="/admin/projectceiling" isAdmin={true} element={<ProtectedRoute><NewProjectceiling/></ProtectedRoute>} />
        <Route path="/admin/projectceilings" isAdmin={true} element={<ProtectedRoute><ProjectceilingsList/></ProtectedRoute>} /> 
        <Route path="/admin/projectceiling/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectceiling/></ProtectedRoute>} /> 

        <Route path="/admin/projectfabric" isAdmin={true} element={<ProtectedRoute><NewProjectfabric/></ProtectedRoute>} />
        <Route path="/admin/projectfabrics" isAdmin={true} element={<ProtectedRoute><ProjectfabricsList/></ProtectedRoute>} /> 
        <Route path="/admin/projectfabric/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectfabric/></ProtectedRoute>} /> 

        <Route path="/admin/projectfloor" isAdmin={true} element={<ProtectedRoute><NewProjectfloor/></ProtectedRoute>} />
        <Route path="/admin/projectfloors" isAdmin={true} element={<ProtectedRoute><ProjectfloorsList/></ProtectedRoute>} /> 
        <Route path="/admin/projectfloor/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectfloor/></ProtectedRoute>} /> 

        <Route path="/admin/projectfurniture" isAdmin={true} element={<ProtectedRoute><NewProjectfurniture/></ProtectedRoute>} />
        <Route path="/admin/projectfurnitures" isAdmin={true} element={<ProtectedRoute><ProjectfurnituresList/></ProtectedRoute>} /> 
        <Route path="/admin/projectfurniture/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectfurniture/></ProtectedRoute>} /> 

        <Route path="/admin/projectkitchen" isAdmin={true} element={<ProtectedRoute><NewProjectkitchen/></ProtectedRoute>} />
        <Route path="/admin/projectkitchens" isAdmin={true} element={<ProtectedRoute><ProjectkitchensList/></ProtectedRoute>} /> 
        <Route path="/admin/projectkitchen/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectkitchen/></ProtectedRoute>} /> 

        <Route path="/admin/projectlandscape" isAdmin={true} element={<ProtectedRoute><NewProjectlandscape/></ProtectedRoute>} />
        <Route path="/admin/projectlandscapes" isAdmin={true} element={<ProtectedRoute><ProjectlandscapesList/></ProtectedRoute>} /> 
        <Route path="/admin/projectlandscape/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectlandscape/></ProtectedRoute>} /> 

        <Route path="/admin/projectlighting" isAdmin={true} element={<ProtectedRoute><NewProjectlighting/></ProtectedRoute>} />
        <Route path="/admin/projectlightings" isAdmin={true} element={<ProtectedRoute><ProjectlightingsList/></ProtectedRoute>} /> 
        <Route path="/admin/projectlighting/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectlighting/></ProtectedRoute>} /> 

        <Route path="/admin/projectpainting" isAdmin={true} element={<ProtectedRoute><NewProjectpainting/></ProtectedRoute>} />
        <Route path="/admin/projectpaintings" isAdmin={true} element={<ProtectedRoute><ProjectpaintingsList/></ProtectedRoute>} /> 
        <Route path="/admin/projectpainting/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectpainting/></ProtectedRoute>} /> 

        <Route path="/admin/projectplant" isAdmin={true} element={<ProtectedRoute><NewProjectplant/></ProtectedRoute>} />
        <Route path="/admin/projectplants" isAdmin={true} element={<ProtectedRoute><ProjectplantsList/></ProtectedRoute>} /> 
        <Route path="/admin/projectplant/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectplant/></ProtectedRoute>} /> 

        <Route path="/admin/projectsoft" isAdmin={true} element={<ProtectedRoute><NewProjectsoft/></ProtectedRoute>} />
        <Route path="/admin/projectsofts" isAdmin={true} element={<ProtectedRoute><ProjectsoftsList/></ProtectedRoute>} /> 
        <Route path="/admin/projectsoft/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectsoft/></ProtectedRoute>} /> 

        <Route path="/admin/projectwall" isAdmin={true} element={<ProtectedRoute><NewProjectwall/></ProtectedRoute>} />
        <Route path="/admin/projectwalls" isAdmin={true} element={<ProtectedRoute><ProjectwallsList/></ProtectedRoute>} /> 
        <Route path="/admin/projectwall/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectwall/></ProtectedRoute>} /> 

        <Route path="/admin/projectwater" isAdmin={true} element={<ProtectedRoute><NewProjectwater/></ProtectedRoute>} />
        <Route path="/admin/projectwaters" isAdmin={true} element={<ProtectedRoute><ProjectwatersList/></ProtectedRoute>} /> 
        <Route path="/admin/projectwater/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectwater/></ProtectedRoute>} /> 

        <Route path="/admin/projectwelding" isAdmin={true} element={<ProtectedRoute><NewProjectwelding/></ProtectedRoute>} />
        <Route path="/admin/projectweldings" isAdmin={true} element={<ProtectedRoute><ProjectweldingsList/></ProtectedRoute>} /> 
        <Route path="/admin/projectwelding/:id" isAdmin={true} element={<ProtectedRoute><UpdateProjectwelding/></ProtectedRoute>} /> 
        
        </Routes>

{!loading && (!isAuthenticated || user.role !== 'admin') && (
  <Footer />
)}
        
      </div>
    </Router>
  );
}
export default App
