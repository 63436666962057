import {
     ALL_PROJECTFABRICS_REQUEST ,
     ALL_PROJECTFABRICS_SUCCESS, 
     ALL_PROJECTFABRICS_FAIL,
     
     ADMIN_PROJECTFABRICS_REQUEST, 
     ADMIN_PROJECTFABRICS_SUCCESS, 
     ADMIN_PROJECTFABRICS_FAIL, 

     NEW_PROJECTFABRIC_REQUEST, 
     NEW_PROJECTFABRIC_SUCCESS, 
     NEW_PROJECTFABRIC_RESET,
     NEW_PROJECTFABRIC_FAIL, 
 
     DELETE_PROJECTFABRIC_REQUEST, 
     DELETE_PROJECTFABRIC_SUCCESS, 
     DELETE_PROJECTFABRIC_RESET,
     DELETE_PROJECTFABRIC_FAIL, 
 
     UPDATE_PROJECTFABRIC_REQUEST, 
     UPDATE_PROJECTFABRIC_SUCCESS, 
     UPDATE_PROJECTFABRIC_RESET,
     UPDATE_PROJECTFABRIC_FAIL, 

     PROJECTFABRIC_DETAILS_REQUEST,
     PROJECTFABRIC_DETAILS_SUCCESS,
     PROJECTFABRIC_DETAILS_FAIL,
     CLEAR_ERRORS
    } from "../constants/projectfabricConstants";

    export const projectfabricsReducer = (state = { projectfabrics: [] }, action) => {
        switch(action.type) {
            case ALL_PROJECTFABRICS_REQUEST:
            case ADMIN_PROJECTFABRICS_REQUEST:
                return {
                    loading: true,
                    projectfabrics: [] 
                }
            case ALL_PROJECTFABRICS_SUCCESS:
                return {
                    loading: false,
                    projectfabrics: action.payload.projectfabrics,
                    projectfabricsCount: action.payload.projectfabricsCount,
                    resPerPage: action.payload.resPerPage
                   
                }

                case ADMIN_PROJECTFABRICS_SUCCESS:
                    return {
                        loading: false,
                        projectfabrics: action.payload
                        } 
            case ALL_PROJECTFABRICS_FAIL:
                case ADMIN_PROJECTFABRICS_FAIL:
                return {
                   loading: false,
                   error: action.payload 
                }
            case CLEAR_ERRORS:
                return {
                    ...state,
                    error: null
                }
            default:
                return state;
        }
    }

    export const newProjectfabricReducer = (state = { projectfabric: {} }, action ) => {
        switch(action.type) {
            case NEW_PROJECTFABRIC_REQUEST:
                return {
                    ...state,
                    loading: true
                }
                
            case NEW_PROJECTFABRIC_SUCCESS:
                    
                return {
                    loading: false,
                    success: action.payload.success,
                    projectfabric: action.payload.projectfabric
                } 
                   
            case NEW_PROJECTFABRIC_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
    
            case NEW_PROJECTFABRIC_RESET:
                return {
                    ...state,
                    success: false
                }    
            case CLEAR_ERRORS:
                return {
                    ...state,
                    error: null
                }
    
           default:
            return state 
        }
    }
    
    export const projectfabricReducer = (state = {}, action ) => {
        switch(action.type) {
            case DELETE_PROJECTFABRIC_REQUEST:
            case UPDATE_PROJECTFABRIC_REQUEST:
                return {
                    ...state,
                    loading: true
                }
                
            case DELETE_PROJECTFABRIC_SUCCESS:    
                return {
                    ...state,
                    loading: false,
                    isDeleted: action.payload
                } 
    
            case UPDATE_PROJECTFABRIC_SUCCESS:  
                return {
                    ...state,
                    loading: false,
                    isUpdated: action.payload
                } 
                   
            case DELETE_PROJECTFABRIC_FAIL:
            case UPDATE_PROJECTFABRIC_FAIL:
                return {
                    ...state,
                    error: action.payload
                }
    
            case DELETE_PROJECTFABRIC_RESET:
                return {
                    ...state,
                    isDeleted: false
                }  
                
                case UPDATE_PROJECTFABRIC_RESET:
                    return {
                        ...state,
                        isUpdate: false
                    }
            case CLEAR_ERRORS:
                return {
                    ...state,
                    error: null
                }
    
           default:
            return state 
        }
    }

    export const projectfabricDetailsReducer = (state = {projectfabric: {} }, action ) => {
        switch (action.type) {
    
            case  PROJECTFABRIC_DETAILS_REQUEST:
                return {
                    ...state,
                    loading: true
                }
            case  PROJECTFABRIC_DETAILS_SUCCESS:
                return {
                    loading: false,
                    projectfabric: action.payload
                }
            case  PROJECTFABRIC_DETAILS_FAIL:
                return {
                    ...state,
                    error: null
                }
            case  CLEAR_ERRORS:
                return {
                    ...state,
                    error: null
                }
            default: 
                 return state
        }
    }