import { 
    ALL_PROJECTLIGHTINGS_REQUEST,
    ALL_PROJECTLIGHTINGS_SUCCESS, 
    ALL_PROJECTLIGHTINGS_FAIL, 

    ADMIN_PROJECTLIGHTINGS_REQUEST, 
    ADMIN_PROJECTLIGHTINGS_SUCCESS, 
    ADMIN_PROJECTLIGHTINGS_FAIL, 

    NEW_PROJECTLIGHTING_REQUEST, 
    NEW_PROJECTLIGHTING_SUCCESS, 
    NEW_PROJECTLIGHTING_RESET,
    NEW_PROJECTLIGHTING_FAIL, 

    DELETE_PROJECTLIGHTING_REQUEST, 
    DELETE_PROJECTLIGHTING_SUCCESS, 
    DELETE_PROJECTLIGHTING_RESET,
    DELETE_PROJECTLIGHTING_FAIL, 

    UPDATE_PROJECTLIGHTING_REQUEST, 
    UPDATE_PROJECTLIGHTING_SUCCESS, 
    UPDATE_PROJECTLIGHTING_RESET,
    UPDATE_PROJECTLIGHTING_FAIL, 

    PROJECTLIGHTING_DETAILS_REQUEST,
    PROJECTLIGHTING_DETAILS_SUCCESS,
    PROJECTLIGHTING_DETAILS_FAIL,
    CLEAR_ERRORS 
 } from "../constants/projectlightingConstants";

 export const projectlightingsReducer = (state = { projectlightings: [] }, action) => {
    switch(action.type) {
        case ALL_PROJECTLIGHTINGS_REQUEST:
        case ADMIN_PROJECTLIGHTINGS_REQUEST:
            return {
                loading: true,
                projectlightings: [] 
            }
        case ALL_PROJECTLIGHTINGS_SUCCESS:
            return {
                loading: false,
                projectlightings: action.payload.projectlightings,
                projectlightingsCount: action.payload.projectlightingsCount,
                resPerPage: action.payload.resPerPage
               
            }

            case ADMIN_PROJECTLIGHTINGS_SUCCESS:
                return {
                    loading: false,
                    projectlightings: action.payload
                    }
        case ALL_PROJECTLIGHTINGS_FAIL:
            case ADMIN_PROJECTLIGHTINGS_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const newProjectlightingReducer = (state = { projectlighting: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTLIGHTING_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTLIGHTING_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectlighting: action.payload.projectlighting
            } 
               
        case NEW_PROJECTLIGHTING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTLIGHTING_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectlightingReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTLIGHTING_REQUEST:
        case UPDATE_PROJECTLIGHTING_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTLIGHTING_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTLIGHTING_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTLIGHTING_FAIL:
        case UPDATE_PROJECTLIGHTING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTLIGHTING_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTLIGHTING_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}



export const projectlightingDetailsReducer = (state = {projectlighting: {} }, action ) => {
    switch (action.type) {

        case  PROJECTLIGHTING_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTLIGHTING_DETAILS_SUCCESS:
            return {
                loading: false,
                projectlighting: action.payload
            }
        case  PROJECTLIGHTING_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}