import axios from 'axios'

import { 
    ALL_PROJECTCEILINGS_REQUEST, 
    ALL_PROJECTCEILINGS_SUCCESS, 
    ALL_PROJECTCEILINGS_FAIL, 

    ADMIN_PROJECTCEILINGS_REQUEST, 
    ADMIN_PROJECTCEILINGS_SUCCESS, 
    ADMIN_PROJECTCEILINGS_FAIL,

    NEW_PROJECTCEILING_REQUEST, 
    NEW_PROJECTCEILING_SUCCESS, 
    NEW_PROJECTCEILING_FAIL, 

    DELETE_PROJECTCEILING_REQUEST, 
    DELETE_PROJECTCEILING_SUCCESS,
    DELETE_PROJECTCEILING_FAIL,

    UPDATE_PROJECTCEILING_REQUEST, 
    UPDATE_PROJECTCEILING_SUCCESS,
    UPDATE_PROJECTCEILING_FAIL, 

    PROJECTCEILING_DETAILS_REQUEST,
    PROJECTCEILING_DETAILS_SUCCESS,
    PROJECTCEILING_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectceilingConstants";

export const getProjectceilings = ( currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTCEILINGS_REQUEST })

        let link = `/api/v1/projectceilings?page=${currentPage}`

        // const { data } = await axios.get(`/api/v1/projectceilings?page=${currentPage}`)
 
        if(category) {
          link = `/api/v1/projectceilings?page=${currentPage}&category=${category}`
        }
 
        const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTCEILINGS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTCEILINGS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectceiling = (projectceilingData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTCEILING_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectceiling/new`, projectceilingData, config)

        dispatch({
            type: NEW_PROJECTCEILING_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTCEILING_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectceiling (Admin)
export const deleteProjectceiling = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTCEILING_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectceiling/${id}`)

        dispatch({
            type: DELETE_PROJECTCEILING_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTCEILING_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectceiling (admin)
export const updateProjectceiling = (id, projectceilingData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTCEILING_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectceiling/${id}`, projectceilingData, config)

        dispatch({
            type: UPDATE_PROJECTCEILING_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTCEILING_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getProjectceilingDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTCEILING_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectceiling/${id}`)

        dispatch({
            type: PROJECTCEILING_DETAILS_SUCCESS,
            payload: data.projectceiling
        })

    } catch (error) {
        dispatch({
            type: PROJECTCEILING_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectceilings = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTCEILINGS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectceilings`)

        dispatch({
            type: ADMIN_PROJECTCEILINGS_SUCCESS,
            payload: data.projectceilings
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTCEILINGS_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}