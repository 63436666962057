import { 
    ALL_PROJECTLANDSCAPES_REQUEST,
    ALL_PROJECTLANDSCAPES_SUCCESS, 
    ALL_PROJECTLANDSCAPES_FAIL, 

    ADMIN_PROJECTLANDSCAPES_REQUEST, 
    ADMIN_PROJECTLANDSCAPES_SUCCESS, 
    ADMIN_PROJECTLANDSCAPES_FAIL, 

    NEW_PROJECTLANDSCAPE_REQUEST, 
    NEW_PROJECTLANDSCAPE_SUCCESS, 
    NEW_PROJECTLANDSCAPE_RESET,
    NEW_PROJECTLANDSCAPE_FAIL, 

    DELETE_PROJECTLANDSCAPE_REQUEST, 
    DELETE_PROJECTLANDSCAPE_SUCCESS, 
    DELETE_PROJECTLANDSCAPE_RESET,
    DELETE_PROJECTLANDSCAPE_FAIL, 

    UPDATE_PROJECTLANDSCAPE_REQUEST, 
    UPDATE_PROJECTLANDSCAPE_SUCCESS, 
    UPDATE_PROJECTLANDSCAPE_RESET,
    UPDATE_PROJECTLANDSCAPE_FAIL, 

    PROJECTLANDSCAPE_DETAILS_REQUEST,
    PROJECTLANDSCAPE_DETAILS_SUCCESS,
    PROJECTLANDSCAPE_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectlandscapeConstants";

export const projectlandscapesReducer = (state = { projectlandscapes: [] }, action )=> {
    switch(action.type) {
        case ALL_PROJECTLANDSCAPES_REQUEST:
        case ADMIN_PROJECTLANDSCAPES_REQUEST:
            return {
                loading: true,
                projectlandscapes: [] 
            }
        case ALL_PROJECTLANDSCAPES_SUCCESS:
            return {
                loading: false,
                projectlandscapes: action.payload.projectlandscapes,
                projectlandscapesCount: action.payload.projectlandscapesCount,
                resPerPage: action.payload.resPerPage
               
            }

            case ADMIN_PROJECTLANDSCAPES_SUCCESS:
                return {
                    loading: false,
                    projectlandscapes: action.payload
                    }
        case ALL_PROJECTLANDSCAPES_FAIL:
            case ADMIN_PROJECTLANDSCAPES_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}


export const newProjectlandscapeReducer = (state = { projectalandscape: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTLANDSCAPE_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTLANDSCAPE_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectlandscape: action.payload.projectlandscape
            } 
               
        case NEW_PROJECTLANDSCAPE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTLANDSCAPE_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectlandscapeReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTLANDSCAPE_REQUEST:
        case UPDATE_PROJECTLANDSCAPE_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTLANDSCAPE_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTLANDSCAPE_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTLANDSCAPE_FAIL:
        case UPDATE_PROJECTLANDSCAPE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTLANDSCAPE_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTLANDSCAPE_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}



export const projectlandscapeDetailsReducer = (state = {projectlandscape: {} }, action ) => {
    switch (action.type) {

        case  PROJECTLANDSCAPE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTLANDSCAPE_DETAILS_SUCCESS:
            return {
                loading: false,
                projectlandscape: action.payload
            }
        case  PROJECTLANDSCAPE_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}