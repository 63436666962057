import React, { Fragment, useEffect, useState } from "react";
import { Carousel, caousel } from "react-bootstrap";
import Pagination from 'react-js-pagination'

import MetaData from "./layout/MetaData";
import Epxy from "./floor/Epxy";
import Loader from './layout/Loader'

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getProjectfloors } from "../actions/projectfloorActions";

const Floor = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState('');

  const categories = [
    'Wooden floor',
    'Epoxy floor',
    'Acid stain concrete floor',
    'Wood stain concrete floor', 
    'Oxide floor',
    'Terrazo floor',
    'Clay Tiles',
    'Ceramic tiles'
  ]
  const alert = useAlert();
  const dispatch = useDispatch();

  const { loading, projectfloors, error, projectfloorsCount, resPerPage } = useSelector(
    (state) => state.projectfloors
  );

  useEffect(() => {
    if(error) {
      return alert.error(error)
    }
    dispatch(getProjectfloors(currentPage, category));
  }, [dispatch, alert, error, currentPage, category]);

  function setCurrentPageNo(pageNumber) {
    setCurrentPage(pageNumber);
  }

  return (
    <Fragment>
      {loading ? <Loader /> : (
        <Fragment>
                <MetaData title={"Floors"} />
      <div className="container container-fluid">
        <div className="sticky2 bg-light">

          <div className="scrollable-tabs-container font-rale-16 bg-light">
        {/*}    <div className="left-arrow ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </div> */}
            <ul>
              {categories.map(category => (
                <li  style={{cursor: 'pointer'}} key={category} onClick={() => setCategory(category) } >{category}</li>
              ))}
           
            </ul>
         {/*}   <div className="right-arrow ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div> */}
          </div>
        </div>



        <div className="page-container">
          {projectfloors &&
            projectfloors.map((projectfloor) => (
              <Epxy key={projectfloor._id} projectfloor={projectfloor} />
            ))}
        </div>
      </div>

      {resPerPage < projectfloorsCount && (
              <div className="d-flex justify-content-center mt-5">
              <Pagination
              activePage={currentPage}
              itemsCountPerPage={resPerPage}
              totalItemsCount={projectfloorsCount}
              onChange={setCurrentPageNo}
              nextPageText={'Next'}
              prevPageText={'Prev'}
              firstPageText={'First'}
              lastPageText={'Last'}
              itemClass="page-item"
              linkClass="page-link"
              >
      
              </Pagination>
            </div>
      )}

        </Fragment>
      )}
    </Fragment>
  );
};

export default Floor;
