import axios from 'axios'

import { 
    ALL_PROJECTFABRICS_REQUEST, 
    ALL_PROJECTFABRICS_SUCCESS, 
    ALL_PROJECTFABRICS_FAIL, 

    ADMIN_PROJECTFABRICS_REQUEST, 
    ADMIN_PROJECTFABRICS_SUCCESS, 
    ADMIN_PROJECTFABRICS_FAIL,

    NEW_PROJECTFABRIC_REQUEST, 
    NEW_PROJECTFABRIC_SUCCESS, 
    NEW_PROJECTFABRIC_FAIL, 

    DELETE_PROJECTFABRIC_REQUEST, 
    DELETE_PROJECTFABRIC_SUCCESS,
    DELETE_PROJECTFABRIC_FAIL,

    UPDATE_PROJECTFABRIC_REQUEST, 
    UPDATE_PROJECTFABRIC_SUCCESS,
    UPDATE_PROJECTFABRIC_FAIL, 

    PROJECTFABRIC_DETAILS_REQUEST,
    PROJECTFABRIC_DETAILS_SUCCESS,
    PROJECTFABRIC_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectfabricConstants";

export const getProjectfabrics = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTFABRICS_REQUEST })

        let link = `/api/v1/projectfabrics?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectfabrics?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectfabrics?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTFABRICS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTFABRICS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectfabric = (projectfabricData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTFABRIC_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectfabric/new`, projectfabricData, config)

        dispatch({
            type: NEW_PROJECTFABRIC_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTFABRIC_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectfabric (Admin)
export const deleteProjectfabric = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTFABRIC_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectfabric/${id}`)

        dispatch({
            type: DELETE_PROJECTFABRIC_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTFABRIC_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectfabric (admin)
export const updateProjectfabric = (id, projectfabricData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTFABRIC_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectfabric/${id}`, projectfabricData, config)

        dispatch({
            type: UPDATE_PROJECTFABRIC_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTFABRIC_FAIL,
            payload: error.response.data.message
        })
    }
}


export const getProjectfabricDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTFABRIC_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectfabric/${id}`)

        dispatch({
            type: PROJECTFABRIC_DETAILS_SUCCESS,
            payload: data.projectfabric
        })

    } catch (error) {
        dispatch({
            type: PROJECTFABRIC_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectfabrics = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTFABRICS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectfabrics`)

        dispatch({
            type: ADMIN_PROJECTFABRICS_SUCCESS,
            payload: data.projectfabrics
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTFABRICS_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}