import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = () => {
  return (
    <div className='sidebar-wrapper'>
        <nav id='sidebar'>
            <ul className='list-unstyled components'>
                <li>
                    <Link to='/dashboard'><i className='fa fa-tachometer'></i>Dashboard</Link>
                </li>

                <li>
                    <a href='#productSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Products
                    </a>

                   <ul className='collapse list-unstyled' id='productSubmenu'>
                    <li>
                        <Link to='/admin/products'><i className='fa fa-clipboard'></i>All Products</Link>
                    </li>

                    <li>
                        <Link to='/admin/product'><i className='fa fa-plus'></i>Create Product</Link>
                    </li>

                    </ul> 
                </li>


                <li>
                    <Link to='/admin/orders'><i className='fa fa-shopping-basket'></i>Orders</Link>
                </li>

                <li>
                    <Link to='/admin/users'><i className='fa fa-users'></i>Users</Link>
                </li>

                <li>
                    <Link to='/admin/reviews'><i className='fa fa-star'></i>Reviews</Link>
                </li>


                <li>
                    <a href='#projectaccesorySubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Accesories
                    </a>

                   <ul className='collapse list-unstyled' id='projectaccesorySubmenu'>
                    <li>
                        <Link to='/admin/projectaccesories'><i className='fa fa-clipboard'></i>All Accesories</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectaccesory'><i className='fa fa-plus'></i>Create Accesory Project</Link>
                    </li>

                    </ul> 
                </li>


                <li>
                    <a href='#projectarchSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Structures
                    </a>

                   <ul className='collapse list-unstyled' id='projectarchSubmenu'>
                    <li>
                        <Link to='/admin/projectarchs'><i className='fa fa-clipboard'></i>All Structures</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectarch'><i className='fa fa-plus'></i>Create Structure Project</Link>
                    </li>

                    </ul> 
                </li>


                <li>
                    <a href='#projectartSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Fine Art
                    </a>

                   <ul className='collapse list-unstyled' id='projectartSubmenu'>
                    <li>
                        <Link to='/admin/projectarts'><i className='fa fa-clipboard'></i>All Fine art</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectart'><i className='fa fa-plus'></i>Create Fine art Project</Link>
                    </li>

                    </ul> 
                </li>


                <li>
                    <a href='#projectceilingSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Ceilings
                    </a>

                   <ul className='collapse list-unstyled' id='projectceilingSubmenu'>
                    <li>
                        <Link to='/admin/projectceilings'><i className='fa fa-clipboard'></i>All Ceilings</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectceiling'><i className='fa fa-plus'></i>Create Ceiling Project</Link>
                    </li>

                    </ul> 
                </li>


                <li>
                    <a href='#projectfabricSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Fabrics
                    </a>

                   <ul className='collapse list-unstyled' id='projectfabricSubmenu'>
                    <li>
                        <Link to='/admin/projectfabrics'><i className='fa fa-clipboard'></i>All Fabrics</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectfabric'><i className='fa fa-plus'></i>Create Fabric Project</Link>
                    </li>

                    </ul> 
                </li>


                <li>
                    <a href='#projectfloorSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Floors
                    </a>

                   <ul className='collapse list-unstyled' id='projectfloorSubmenu'>
                    <li>
                        <Link to='/admin/projectfloors'><i className='fa fa-clipboard'></i>All Floors</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectfloor'><i className='fa fa-plus'></i>Create Floor Project</Link>
                    </li>

                    </ul> 
                </li>


                <li>
                    <a href='#projectfurnitureSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Furniture
                    </a>

                   <ul className='collapse list-unstyled' id='projectfurnitureSubmenu'>
                    <li>
                        <Link to='/admin/projectfurnitures'><i className='fa fa-clipboard'></i>All Furnitures</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectfurniture'><i className='fa fa-plus'></i>Create Furniture Project</Link>
                    </li>

                    </ul> 
                </li>


                <li>
                    <a href='#projectkitchenSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Kitchens
                    </a>

                   <ul className='collapse list-unstyled' id='projectkitchenSubmenu'>
                    <li>
                        <Link to='/admin/projectkitchens'><i className='fa fa-clipboard'></i>All Kitchens</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectkitchen'><i className='fa fa-plus'></i>Create Kitchen Project</Link>
                    </li>

                    </ul> 
                </li>


                <li>
                    <a href='#projectlandscapeSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Landscapes
                    </a>

                   <ul className='collapse list-unstyled' id='projectlandscapeSubmenu'>
                    <li>
                        <Link to='/admin/projectlandscapes'><i className='fa fa-clipboard'></i>All Landscapes</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectlandscape'><i className='fa fa-plus'></i>Create Landscape Project</Link>
                    </li>

                    </ul> 
                </li>


                <li>
                    <a href='#projectlightingSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Lightings
                    </a>

                   <ul className='collapse list-unstyled' id='projectlightingSubmenu'>
                    <li>
                        <Link to='/admin/projectlightings'><i className='fa fa-clipboard'></i>All Lightings</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectlighting'><i className='fa fa-plus'></i>Create Lighting Project</Link>
                    </li>

                    </ul> 
                </li>

                <li>
                    <a href='#projectpaintingSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        House painting
                    </a>

                   <ul className='collapse list-unstyled' id='projectpaintingSubmenu'>
                    <li>
                        <Link to='/admin/projectpaintings'><i className='fa fa-clipboard'></i>All House paintings</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectpainting'><i className='fa fa-plus'></i>Create House painting Project</Link>
                    </li>

                    </ul> 
                </li>

                <li>
                    <a href='#projectplantSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Plants & Flowers
                    </a>

                   <ul className='collapse list-unstyled' id='projectplantSubmenu'>
                    <li>
                        <Link to='/admin/projectplants'><i className='fa fa-clipboard'></i>All Plants</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectplant'><i className='fa fa-plus'></i>Create Plant Project</Link>
                    </li>

                    </ul> 
                </li>


                <li>
                    <a href='#projectsoftSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Soft Furnishing
                    </a>

                   <ul className='collapse list-unstyled' id='projectsoftSubmenu'>
                    <li>
                        <Link to='/admin/projectsofts'><i className='fa fa-clipboard'></i>All Soft Furnishing</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectsoft'><i className='fa fa-plus'></i>Create Soft Project</Link>
                    </li>

                    </ul> 
                </li>

                <li>
                    <a href='#projectwallSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Wall finishes
                    </a>

                   <ul className='collapse list-unstyled' id='projectwallSubmenu'>
                    <li>
                        <Link to='/admin/projectwalls'><i className='fa fa-clipboard'></i>All Wall finishes</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectwall'><i className='fa fa-plus'></i>Create Wall finish Project</Link>
                    </li>

                    </ul> 
                </li>

                <li>
                    <a href='#projectwaterSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Aquariums
                    </a>

                   <ul className='collapse list-unstyled' id='projectwaterSubmenu'>
                    <li>
                        <Link to='/admin/projectwaters'><i className='fa fa-clipboard'></i>All Aquariums</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectwater'><i className='fa fa-plus'></i>Create Aquarium Project</Link>
                    </li>

                    </ul> 
                </li>

                <li>
                    <a href='#projectweldingSubmenu' data-toggle='collapse' aria-expanded='false'
                    className='dropdown-toggle'>
                        <i className='fa fa-product-hunt'></i>
                        Weldings
                    </a>

                   <ul className='collapse list-unstyled' id='projectweldingSubmenu'>
                    <li>
                        <Link to='/admin/projectweldings'><i className='fa fa-clipboard'></i>All Weldings</Link>
                    </li>

                    <li>
                        <Link to='/admin/projectwelding'><i className='fa fa-plus'></i>Create Welding Project</Link>
                    </li>

                    </ul> 
                </li>

            </ul>
        </nav>
    </div>
  )
}

export default Sidebar