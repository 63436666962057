import { 
    ALL_PROJECTWATERS_REQUEST,
    ALL_PROJECTWATERS_SUCCESS, 
    ALL_PROJECTWATERS_FAIL,

    ADMIN_PROJECTWATERS_REQUEST, 
    ADMIN_PROJECTWATERS_SUCCESS, 
    ADMIN_PROJECTWATERS_FAIL, 

    NEW_PROJECTWATER_REQUEST, 
    NEW_PROJECTWATER_SUCCESS, 
    NEW_PROJECTWATER_RESET,
    NEW_PROJECTWATER_FAIL, 

    DELETE_PROJECTWATER_REQUEST, 
    DELETE_PROJECTWATER_SUCCESS, 
    DELETE_PROJECTWATER_RESET,
    DELETE_PROJECTWATER_FAIL, 

    UPDATE_PROJECTWATER_REQUEST, 
    UPDATE_PROJECTWATER_SUCCESS, 
    UPDATE_PROJECTWATER_RESET,
    UPDATE_PROJECTWATER_FAIL, 
    
    PROJECTWATER_DETAILS_REQUEST,
    PROJECTWATER_DETAILS_SUCCESS,
    PROJECTWATER_DETAILS_FAIL,
    CLEAR_ERRORS 
 } from "../constants/projectwaterConstants";

 export const projectwatersReducer = (state = { projectwaters: [] }, action) => {
    switch(action.type) {
        case ALL_PROJECTWATERS_REQUEST:
        case ADMIN_PROJECTWATERS_REQUEST:
            return {
                loading: true,
                projectwaters: [] 
            }
        case ALL_PROJECTWATERS_SUCCESS:
            return {
                loading: false,
                projectwaters: action.payload.projectwaters,
                projectwatersCount: action.payload.projectwatersCount,
                resPerPage: action.payload.resPerPage
               
            }

            
            case ADMIN_PROJECTWATERS_SUCCESS:
                return {
                    loading: false,
                    projectwaters: action.payload
                    }
        case ALL_PROJECTWATERS_FAIL:
            case ADMIN_PROJECTWATERS_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}


export const newProjectwaterReducer = (state = { projectwater: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTWATER_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTWATER_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectwater: action.payload.projectwater
            } 
               
        case NEW_PROJECTWATER_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTWATER_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectwaterReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTWATER_REQUEST:
        case UPDATE_PROJECTWATER_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTWATER_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTWATER_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTWATER_FAIL:
        case UPDATE_PROJECTWATER_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTWATER_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTWATER_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}



export const projectwaterDetailsReducer = (state = {projectwater: {} }, action ) => {
    switch (action.type) {

        case  PROJECTWATER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTWATER_DETAILS_SUCCESS:
            return {
                loading: false,
                projectwater: action.payload
            }
        case  PROJECTWATER_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}