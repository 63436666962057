import axios from 'axios'

import { 
    ALL_PROJECTPLANTS_REQUEST, 
    ALL_PROJECTPLANTS_SUCCESS, 
    ALL_PROJECTPLANTS_FAIL, 

    ADMIN_PROJECTPLANTS_REQUEST, 
    ADMIN_PROJECTPLANTS_SUCCESS, 
    ADMIN_PROJECTPLANTS_FAIL,

    NEW_PROJECTPLANT_REQUEST, 
    NEW_PROJECTPLANT_SUCCESS, 
    NEW_PROJECTPLANT_FAIL, 

    DELETE_PROJECTPLANT_REQUEST, 
    DELETE_PROJECTPLANT_SUCCESS,
    DELETE_PROJECTPLANT_FAIL,

    UPDATE_PROJECTPLANT_REQUEST, 
    UPDATE_PROJECTPLANT_SUCCESS,
    UPDATE_PROJECTPLANT_FAIL, 

    PROJECTPLANT_DETAILS_REQUEST,
    PROJECTPLANT_DETAILS_SUCCESS,
    PROJECTPLANT_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectplantConstants";

export const getProjectplants = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTPLANTS_REQUEST })

        let link = `/api/v1/projectplants?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectplants?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectplants?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTPLANTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTPLANTS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectplant = (projectplantData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTPLANT_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectplant/new`, projectplantData, config)

        dispatch({
            type: NEW_PROJECTPLANT_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTPLANT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectplant (Admin)
export const deleteProjectplant = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTPLANT_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectplant/${id}`)

        dispatch({
            type: DELETE_PROJECTPLANT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTPLANT_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectplant (admin)
export const updateProjectplant = (id, projectplantData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTPLANT_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectplant/${id}`, projectplantData, config)

        dispatch({
            type: UPDATE_PROJECTPLANT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTPLANT_FAIL,
            payload: error.response.data.message
        })
    }
}



export const getProjectplantDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTPLANT_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectplant/${id}`)

        dispatch({
            type: PROJECTPLANT_DETAILS_SUCCESS,
            payload: data.projectplant
        })

    } catch (error) {
        dispatch({
            type: PROJECTPLANT_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectplants = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTPLANTS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectplants`)

        dispatch({
            type: ADMIN_PROJECTPLANTS_SUCCESS,
            payload: data.projectplants
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTPLANTS_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}