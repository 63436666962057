import React, { Fragment } from "react";
import { Route } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { logout } from "../../actions/userActions";
import Search from "./Search";
import { Link } from "react-router-dom";
import "../../App.css";
const Header = () => {
  const alert = useAlert(); 
  const dispatch = useDispatch();

  const { user, loading } = useSelector((state) => state.auth);
  const { cartItems } = useSelector((state) => state.cart)

  const logoutHandler = () => {
    dispatch(logout());
    alert.success("Logged out succesfully");
  };
  return (
    <Fragment>
      <div className="sticky">
        <nav className="navbar navbar-light bg-light border-bottom">
          <div className="col-12 col-md-3">
            <div className="navbar-brand">
              <Link to={`../`}>
                <img src="../images/logofinal.png" className="logo"></img>
              </Link>
            </div>
          </div>


          <div className="col-12 col-md-6 mt-2 mt-md-0">
            <Search /> 
          </div>
        </nav>

        <div className="strip d-flex justify-content-between px-4 py-1 color-primary-bg">
          <div className="font-rale font-size-14 m-auto auth-buttons">
            <Link to="/shop" className="px-3 border-right ">
              Shop
            </Link> 
            <Link to="/cart" className="px-3 border-right ">
              Cart ({cartItems.length})
            </Link>

            {user ? (
              <div className=" dropdown d-inline">
                <Link
                  to="#!"
                  className="btn dropdown-toggle text-white mr-4"
                  type="button"
                  id="dropDownMenuButton"
                  data-toggle="dropdown"
                  aria-aria-haspopup="true"
                  aria-expanded="false"
                >
                  &nbsp;
                  <span>{user && user.name}</span>
                </Link>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropDownMenuButton"
                >
                  {user && user.role === "admin" && (
                       <Link className="dropdown-item text-dark" to="/dashboard">
                       Dashboard
                     </Link>
                  ) }
                     <Link className="dropdown-item text-dark" to="/orders/me">
                      Orders
                    </Link>
                  <Link className="dropdown-item text-dark" to="/me">
                    Profile
                  </Link>
                  <Link
                    className="dropdown-item text-danger"
                    to="/"
                    onClick={logoutHandler}
                  >
                    Logout
                  </Link>
                </div>
              </div>
            ) : (
              !loading && (
                <Link to="/login" className="px-3 border-right ">
                  Login
                </Link>
              )
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
