import axios from 'axios'

import { 
    ALL_PROJECTWALLS_REQUEST, 
    ALL_PROJECTWALLS_SUCCESS, 
    ALL_PROJECTWALLS_FAIL, 

    ADMIN_PROJECTWALLS_REQUEST, 
    ADMIN_PROJECTWALLS_SUCCESS, 
    ADMIN_PROJECTWALLS_FAIL,

    NEW_PROJECTWALL_REQUEST, 
    NEW_PROJECTWALL_SUCCESS, 
    NEW_PROJECTWALL_FAIL, 

    DELETE_PROJECTWALL_REQUEST, 
    DELETE_PROJECTWALL_SUCCESS,
    DELETE_PROJECTWALL_FAIL,

    UPDATE_PROJECTWALL_REQUEST, 
    UPDATE_PROJECTWALL_SUCCESS,
    UPDATE_PROJECTWALL_FAIL, 

    PROJECTWALL_DETAILS_REQUEST,
    PROJECTWALL_DETAILS_SUCCESS,
    PROJECTWALL_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectwallConstants";

export const getProjectwalls = (keyword = '', currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTWALLS_REQUEST })

        let link = `/api/v1/projectwalls?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectwalls?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectwalls?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTWALLS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTWALLS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectwall = (projectwallData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTWALL_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectwall/new`, projectwallData, config)

        dispatch({
            type: NEW_PROJECTWALL_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTWALL_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectwall (Admin)
export const deleteProjectwall = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTWALL_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectwall/${id}`)

        dispatch({
            type: DELETE_PROJECTWALL_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTWALL_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectwall (admin)
export const updateProjectwall = (id, projectwallData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTWALL_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectwall/${id}`, projectwallData, config)

        dispatch({
            type: UPDATE_PROJECTWALL_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTWALL_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getProjectwallDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTWALL_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectwall/${id}`)

        dispatch({
            type: PROJECTWALL_DETAILS_SUCCESS,
            payload: data.projectwall
        })

    } catch (error) {
        dispatch({
            type: PROJECTWALL_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectwalls = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTWALLS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectwalls`)

        dispatch({
            type: ADMIN_PROJECTWALLS_SUCCESS,
            payload: data.projectwalls
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTWALLS_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}