import { 
    ALL_PROJECTFLOORS_REQUEST,
    ALL_PROJECTFLOORS_SUCCESS, 
    ALL_PROJECTFLOORS_FAIL,
    
    ADMIN_PROJECTFLOORS_REQUEST, 
    ADMIN_PROJECTFLOORS_SUCCESS, 
    ADMIN_PROJECTFLOORS_FAIL, 

    NEW_PROJECTFLOOR_REQUEST, 
    NEW_PROJECTFLOOR_SUCCESS, 
    NEW_PROJECTFLOOR_RESET,
    NEW_PROJECTFLOOR_FAIL, 

    DELETE_PROJECTFLOOR_REQUEST, 
    DELETE_PROJECTFLOOR_SUCCESS, 
    DELETE_PROJECTFLOOR_RESET,
    DELETE_PROJECTFLOOR_FAIL, 

    UPDATE_PROJECTFLOOR_REQUEST, 
    UPDATE_PROJECTFLOOR_SUCCESS, 
    UPDATE_PROJECTFLOOR_RESET,
    UPDATE_PROJECTFLOOR_FAIL, 

    PROJECTFLOOR_DETAILS_REQUEST,
    PROJECTFLOOR_DETAILS_SUCCESS,
    PROJECTFLOOR_DETAILS_FAIL,
    
    CLEAR_ERRORS
 } from "../constants/projectfloorConstants";

 export const projectfloorsReducer = (state = { projectfloors: [] }, action) => {
    switch(action.type) {
        case ALL_PROJECTFLOORS_REQUEST:
        case ADMIN_PROJECTFLOORS_REQUEST:
            return {
                loading: true,
                projectfloors: [] 
            }
        case ALL_PROJECTFLOORS_SUCCESS:
            return {
                loading: false,
                projectfloors: action.payload.projectfloors,
                projectfloorsCount: action.payload.projectfloorsCount,
                resPerPage: action.payload.resPerPage
               
            }

            case ADMIN_PROJECTFLOORS_SUCCESS:
                return {
                    loading: false,
                    projectfloors: action.payload
                    }
        case ALL_PROJECTFLOORS_FAIL:
            case ADMIN_PROJECTFLOORS_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}


export const newProjectfloorReducer = (state = { projectfloor: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTFLOOR_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTFLOOR_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectfloor: action.payload.projectfloor
            } 
               
        case NEW_PROJECTFLOOR_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTFLOOR_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectfloorReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTFLOOR_REQUEST:
        case UPDATE_PROJECTFLOOR_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTFLOOR_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTFLOOR_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTFLOOR_FAIL:
        case UPDATE_PROJECTFLOOR_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTFLOOR_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTFLOOR_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectfloorDetailsReducer = (state = {projectfloor: {} }, action ) => {
    switch (action.type) {

        case  PROJECTFLOOR_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTFLOOR_DETAILS_SUCCESS:
            return {
                loading: false,
                projectfloor: action.payload
            }
        case  PROJECTFLOOR_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}