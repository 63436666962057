export const ADMIN_PROJECTKITCHENS_REQUEST = 'ADMIN_PROJECTKITCHENS_REQUEST'
export const ADMIN_PROJECTKITCHENS_SUCCESS = 'ADMIN_PROJECTKITCHENS_SUCCESS'
export const ADMIN_PROJECTKITCHENS_FAIL = 'ADMIN_PROJECTKITCHENS_FAIL'

export const ALL_PROJECTKITCHENS_REQUEST = 'ALL_PROJECTKITCHENS_REQUEST'
export const ALL_PROJECTKITCHENS_SUCCESS = 'ALL_PROJECTKITCHENS_SUCCESS'
export const ALL_PROJECTKITCHENS_FAIL = 'ALL_PROJECTKITCHENS_FAIL'

export const NEW_PROJECTKITCHEN_REQUEST = 'NEW_PROJECTKITCHEN_REQUEST'
export const NEW_PROJECTKITCHEN_SUCCESS = 'NEW_PROJECTKITCHEN_SUCCESS'
export const NEW_PROJECTKITCHEN_RESET = 'NEW_PROJECTKITCHEN_RESET'
export const NEW_PROJECTKITCHEN_FAIL = 'NEW_PROJECTKITCHEN_FAIL'

export const DELETE_PROJECTKITCHEN_REQUEST = 'DELETE_PROJECTKITCHEN_REQUEST'
export const DELETE_PROJECTKITCHEN_SUCCESS = 'DELETE_PROJECTKITCHEN_SUCCESS'
export const DELETE_PROJECTKITCHEN_RESET = 'DELETE_PROJECTKITCHEN_RESET'
export const DELETE_PROJECTKITCHEN_FAIL = 'DELETE_PROJECTKITCHEN_FAIL'

export const UPDATE_PROJECTKITCHEN_REQUEST = 'UPDATE_PROJECTKITCHEN_REQUEST'
export const UPDATE_PROJECTKITCHEN_SUCCESS = 'UPDATE_PROJECTKITCHEN_SUCCESS'
export const UPDATE_PROJECTKITCHEN_RESET = 'UPDATE_PROJECTKITCHEN_RESET'
export const UPDATE_PROJECTKITCHEN_FAIL = 'UPDATE_PROJECTKITCHEN_FAIL'

export const PROJECTKITCHEN_DETAILS_REQUEST = 'PROJECTKITCHEN_DETAILS_REQUEST'
export const PROJECTKITCHEN_DETAILS_SUCCESS = 'PROJECTKITCHEN_DETAILS_SUCCESS'
export const PROJECTKITCHEN_DETAILS_FAIL = 'PROJECTKITCHEN_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
