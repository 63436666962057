import axios from 'axios'

import { 
    ALL_PROJECTFURNITURES_REQUEST, 
    ALL_PROJECTFURNITURES_SUCCESS, 
    ALL_PROJECTFURNITURES_FAIL, 

    ADMIN_PROJECTFURNITURES_REQUEST, 
    ADMIN_PROJECTFURNITURES_SUCCESS, 
    ADMIN_PROJECTFURNITURES_FAIL,

    NEW_PROJECTFURNITURE_REQUEST, 
    NEW_PROJECTFURNITURE_SUCCESS, 
    NEW_PROJECTFURNITURE_FAIL, 

    DELETE_PROJECTFURNITURE_REQUEST, 
    DELETE_PROJECTFURNITURE_SUCCESS,
    DELETE_PROJECTFURNITURE_FAIL,

    UPDATE_PROJECTFURNITURE_REQUEST, 
    UPDATE_PROJECTFURNITURE_SUCCESS,
    UPDATE_PROJECTFURNITURE_FAIL, 

    PROJECTFURNITURE_DETAILS_REQUEST,
    PROJECTFURNITURE_DETAILS_SUCCESS,
    PROJECTFURNITURE_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectfurnitureConstants";

export const getProjectfurnitures = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTFURNITURES_REQUEST })

        let link = `/api/v1/projectfurnitures?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectfurnitures?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectfurnitures?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTFURNITURES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTFURNITURES_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectfurniture = (projectfurnitureData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTFURNITURE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectfurniture/new`, projectfurnitureData, config)

        dispatch({
            type: NEW_PROJECTFURNITURE_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTFURNITURE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectfurniture (Admin)
export const deleteProjectfurniture = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTFURNITURE_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectfurniture/${id}`)

        dispatch({
            type: DELETE_PROJECTFURNITURE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTFURNITURE_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectfurniture (admin)
export const updateProjectfurniture = (id, projectfurnitureData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTFURNITURE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectfurniture/${id}`, projectfurnitureData, config)

        dispatch({
            type: UPDATE_PROJECTFURNITURE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTFURNITURE_FAIL,
            payload: error.response.data.message
        })
    }
}



export const getProjectfurnitureDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTFURNITURE_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectfurniture/${id}`)

        dispatch({
            type: PROJECTFURNITURE_DETAILS_SUCCESS,
            payload: data.projectfurniture
        })

    } catch (error) {
        dispatch({
            type: PROJECTFURNITURE_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectfurnitures = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTFURNITURES_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectfurnitures`)

        dispatch({
            type: ADMIN_PROJECTFURNITURES_SUCCESS,
            payload: data.projectfurnitures
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTFURNITURES_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}