import axios from 'axios'

import { 
    ALL_PROJECTKITCHENS_REQUEST, 
    ALL_PROJECTKITCHENS_SUCCESS, 
    ALL_PROJECTKITCHENS_FAIL, 

    ADMIN_PROJECTKITCHENS_REQUEST, 
    ADMIN_PROJECTKITCHENS_SUCCESS, 
    ADMIN_PROJECTKITCHENS_FAIL,

    NEW_PROJECTKITCHEN_REQUEST, 
    NEW_PROJECTKITCHEN_SUCCESS, 
    NEW_PROJECTKITCHEN_FAIL, 

    DELETE_PROJECTKITCHEN_REQUEST, 
    DELETE_PROJECTKITCHEN_SUCCESS,
    DELETE_PROJECTKITCHEN_FAIL,

    UPDATE_PROJECTKITCHEN_REQUEST, 
    UPDATE_PROJECTKITCHEN_SUCCESS,
    UPDATE_PROJECTKITCHEN_FAIL, 

    PROJECTKITCHEN_DETAILS_REQUEST,
    PROJECTKITCHEN_DETAILS_SUCCESS,
    PROJECTKITCHEN_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectkitchenConstants";

export const getProjectkitchens = (currentPage, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTKITCHENS_REQUEST })

        let link = `/api/v1/projectkitchens?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectkitchens?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectkitchens?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTKITCHENS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTKITCHENS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectkitchen = (projectkitchenData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTKITCHEN_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectkitchen/new`, projectkitchenData, config)

        dispatch({
            type: NEW_PROJECTKITCHEN_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTKITCHEN_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectkitchen (Admin)
export const deleteProjectkitchen = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTKITCHEN_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectkitchen/${id}`)

        dispatch({
            type: DELETE_PROJECTKITCHEN_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTKITCHEN_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectkitchen (admin)
export const updateProjectkitchen = (id, projectkitchenData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTKITCHEN_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectkitchen/${id}`, projectkitchenData, config)

        dispatch({
            type: UPDATE_PROJECTKITCHEN_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTKITCHEN_FAIL,
            payload: error.response.data.message
        })
    }
}



export const getProjectkitchenDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTKITCHEN_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectkitchen/${id}`)

        dispatch({
            type: PROJECTKITCHEN_DETAILS_SUCCESS,
            payload: data.projectkitchen
        })

    } catch (error) {
        dispatch({
            type: PROJECTKITCHEN_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectkitchens = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTKITCHENS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectkitchens`)

        dispatch({
            type: ADMIN_PROJECTKITCHENS_SUCCESS,
            payload: data.projectkitchens
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTKITCHENS_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}