import React, { Fragment, useState, useEffect } from 'react'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import MetaData from "../layout/MetaData";


import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { register, clearErrors } from '../../actions/userActions'

const Register = () => {

    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
    })

    const { name, email, password } = user;
    const [visible, setVisible] = useState(false);
    
    //const [avatar, setAvatar] = useState('')
    //const [avatarPreview, setAvatarPreview] = useState('/images/dp.jpg')

    const alert = useAlert();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { isAuthenticated, error, loading } = useSelector((state) => state.auth);

    useEffect(() => {

        if(isAuthenticated) {
            navigate('/')
        }

        if(error) {
            alert.error(error);
            dispatch(clearErrors());
        }
    }, [dispatch, alert, isAuthenticated, error])

    const submitHandler = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.set('name', name);
        formData.set('email', email);
        formData.set('password', password);
       // formData.set('avatar', avatar);
        //var object = {};
        //formData.forEach((value, key) => object[key] = value);
        //var json = object
        //dispatch(register(json)) 


        dispatch(register(formData))
    }

    const onChange = e => {
        if(e.target.name === 'avatar') {

            const reader = new FileReader();

            reader.onload = () => {
                if(reader.readyState === 2) {
                  //  setAvatarPreview(reader.result)
                   // setAvatar(reader.result)
                }
            }

            reader.readAsDataURL(e.target.files[0])

        } else {

            setUser({ ...user, [e.target.name]: e.target.value})
        } 

        //setUser({ ...user, [e.target.name]: e.target.value})
    }

  return (
    <Fragment>

        <MetaData title={'Register User'} />

        <div className='row wrapper'>
            <div className='col-12 col-lg-5'>
                <form className='shadow-lg' onSubmit={submitHandler} encType='multipart/form-data'>
                    <h1 className='mb-3'>Register</h1>

                    <div className='form-group'>
                        <label htmlFor="email_field">Name</label>
                        <input 
                        type="name" 
                        id="name_field" 
                        className='form-control' 
                        name='name'
                        value={name}
                        onChange={onChange}
                        >

                        </input>
                    </div>

                    <div className='form-group'>
                        <label htmlFor="email_field">Email</label>
                        <input
                        type="email"
                        id="email_field"
                        className='form-control'
                        name='email'
                        value={email}
                        onChange={onChange}
                        ></input>
                    </div>

                    <div className='form-group'>
                        <label htmlFor="password_field">Password</label>
                        <input
                        type={visible ? "text" : "password"}
                        id="password_field"
                        className='form-control'
                        name='password'
                        value={password}
                        onChange={onChange}
                        ></input>

<div className='p-2 macho' onClick={() => setVisible(!visible)}>
                            {
                                visible ? <EyeOutlined/> : <EyeInvisibleOutlined/>
                            }
                        </div>
                    </div>

                   {/* <div className='form-group'>
                        <label htmlFor="avatar_upload">Avatar</label>
                        <div className='d-flex align-items-center'>
                            <div>
                                <figure className='avatar mr-3 item-rt1'>
                                    <img 
                                    src={avatarPreview}
                                    className='rounded-circle'
                                    alt="Avatar Preview"
                                    ></img>
                                </figure>
                            </div>

                            <div className='custom-file'>
                                <input 
                                type="file"
                                name='avatar'
                                className='custom-file-input'
                                id='customFile'
                                accept='images/*'
                                onChange={onChange}
                                ></input>

                                <label className='custom-file-label' htmlFor="customFile">
                                    Choose Avatar
                                </label>
                            </div>
                        </div>
  </div>  */}

                    <button
                    id="register_button"
                    type='submit'
                    className='btn btn-block py-3'
                    disabled={ loading ? true : false}
                    >REGISTER</button>
                </form>
            </div>
        </div>

    </Fragment>
  )
}

export default Register