import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { productsReducer, newProductReducer, productReducer, productDetailsReducer, newReviewReducer, productReviewsReducer, reviewReducer } from './reducers/productReducers'
import { projectaccesoriesReducer, newProjectaccesoryReducer, projectaccesoryReducer, projectaccesoryDetailsReducer } from './reducers/projectaccesoryReducers'
import { projectarchsReducer, newProjectarchReducer, projectarchReducer, projectarchDetailsReducer } from './reducers/projectarchReducers'
import { projectartsReducer, newProjectartReducer, projectartReducer, projectartDetailsReducer } from './reducers/projectartReducers'
import { projectceilingsReducer, newProjectceilingReducer, projectceilingReducer, projectceilingDetailsReducer } from './reducers/projectceilingReducers'
import { projectfabricsReducer, newProjectfabricReducer, projectfabricReducer, projectfabricDetailsReducer} from './reducers/projectfabricReducers'
import { projectfloorsReducer, newProjectfloorReducer, projectfloorReducer, projectfloorDetailsReducer} from './reducers/projectfloorReducers'
import { projectfurnituresReducer, newProjectfurnitureReducer, projectfurnitureReducer, projectfurnitureDetailsReducer} from './reducers/projectfurnitureReducers'
import { projectkitchensReducer, newProjectkitchenReducer, projectkitchenReducer, projectkitchenDetailsReducer} from './reducers/projectkitchenReducers'
import { projectlandscapesReducer, newProjectlandscapeReducer, projectlandscapeReducer, projectlandscapeDetailsReducer } from './reducers/projectlandscapeReducers'
import { projectlightingsReducer, newProjectlightingReducer, projectlightingReducer, projectlightingDetailsReducer } from './reducers/projectlightingReducers'
import { projectpaintingsReducer, newProjectpaintingReducer, projectpaintingReducer, projectpaintingDetailsReducer } from './reducers/projectpaintingReducers'
import { projectplantsReducer, newProjectplantReducer, projectplantReducer, projectplantDetailsReducer } from './reducers/projectplantReducers'
import { projectsoftsReducer, newProjectsoftReducer, projectsoftReducer, projectsoftDetailsReducer } from './reducers/projectsoftReducers'
import { projectwallsReducer, newProjectwallReducer, projectwallReducer, projectwallDetailsReducer } from './reducers/projectwallReducers'
import { projectwatersReducer, newProjectwaterReducer, projectwaterReducer, projectwaterDetailsReducer} from './reducers/projectwaterReducers'
import { projectweldingsReducer, newProjectweldingReducer, projectweldingReducer, projectweldingDetailsReducer } from './reducers/projectweldingReducers'
import { authReducer, userReducer, forgotPasswordReducer, allUsersReducer, userDetailsReducer } from './reducers/userReducers';

import { cartReducer } from './reducers/cartReducers';
import { newOrderReducer, myOrdersReducer, orderDetailsReducer, allOrdersReducer, orderReducer } from './reducers/orderReducers'

const reducer = combineReducers({
    products: productsReducer,
    productDetails: productDetailsReducer,
    newProduct: newProductReducer,
    product: productReducer,
    productReviews: productReviewsReducer,
    review: reviewReducer,

    projectaccesories: projectaccesoriesReducer,
    projectaccesoryDetails: projectaccesoryDetailsReducer,
    newProjectaccesory: newProjectaccesoryReducer,
    projectaccesory: projectaccesoryReducer,

    projectarchs: projectarchsReducer,
    projectarchDetails: projectarchDetailsReducer,
    newProjectarch: newProjectarchReducer,
    projectarch: projectarchReducer,

    projectarts: projectartsReducer,
    projectartDetails: projectartDetailsReducer,
    newProjectart: newProjectartReducer,
    projectart: projectartReducer,

    projectceilings: projectceilingsReducer,
    projectceilingDetails: projectceilingDetailsReducer,
    newProjectceiling: newProjectceilingReducer,
    projectceiling: projectceilingReducer,

    projectfabrics: projectfabricsReducer,
    projectfabricDetails: projectfabricDetailsReducer,
    newProjectfabric: newProjectfabricReducer,
    projectfabric: projectfabricReducer,

    projectfloors: projectfloorsReducer,
    projectfloorDetails: projectfloorDetailsReducer,
    newProjectfloor: newProjectfloorReducer,
    projectfloor: projectfloorReducer,

    projectfurnitures: projectfurnituresReducer,
    projectfurnitureDetails: projectfurnitureDetailsReducer,
    newProjectfurniture: newProjectfurnitureReducer,
    projectfurniture: projectfurnitureReducer,

    projectkitchens: projectkitchensReducer ,
    projectkitchenDetails: projectkitchenDetailsReducer,
    newProjectkitchen: newProjectkitchenReducer,
    projectkitchen: projectkitchenReducer,

    projectlandscapes: projectlandscapesReducer,
    projectlandscapeDetails: projectlandscapeDetailsReducer,
    newProjectlandscape: newProjectlandscapeReducer,
    projectlandscape: projectlandscapeReducer,

    projectlightings: projectlightingsReducer,
    projectlightingDetails: projectlightingDetailsReducer,
    newProjectlighting: newProjectlightingReducer,
    projectlighting: projectlightingReducer,

    projectpaintings: projectpaintingsReducer,
    projectpaintingDetails: projectpaintingDetailsReducer,
    newProjectpainting: newProjectpaintingReducer,
    projectpainting: projectpaintingReducer,

    projectplants: projectplantsReducer,
    projectplantDetails: projectplantDetailsReducer,
    newProjectplant: newProjectplantReducer,
    projectplant: projectplantReducer,

    projectsofts: projectsoftsReducer,
    projectsoftDetails: projectsoftDetailsReducer,
    newProjectsoft: newProjectsoftReducer,
    projectsoft: projectsoftReducer,

    projectwalls: projectwallsReducer ,
    projectwallDetails: projectwallDetailsReducer,
    newProjectwall: newProjectwallReducer,
    projectwall: projectwallReducer,

    projectwaters: projectwatersReducer,
    projectwaterDetails: projectwaterDetailsReducer,
    newProjectwater: newProjectwaterReducer,
    projectwater: projectwaterReducer,

    projectweldings: projectweldingsReducer,
    projectweldingDetails: projectweldingDetailsReducer,
    newProjectwelding: newProjectweldingReducer,
    projectwelding: projectweldingReducer,

    auth: authReducer,
    user: userReducer,
    allUsers: allUsersReducer,
    userDetails: userDetailsReducer,

    forgotPassword: forgotPasswordReducer,

    cart: cartReducer,
    newOrder: newOrderReducer,
    myOrders: myOrdersReducer,
    allOrders: allOrdersReducer,
    orderDetails: orderDetailsReducer,
    order: orderReducer,
    newReview: newReviewReducer




})

let initialState = {
    
   cart: {
    cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
    shippingInfo: localStorage.getItem('shippingInfo') ? JSON.parse(localStorage.getItem('shippingInfo')) : {}
 }

}

const middleware = [thunk];
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))


export default store;