import { 
    ALL_PROJECTKITCHENS_REQUEST,
    ALL_PROJECTKITCHENS_SUCCESS, 
    ALL_PROJECTKITCHENS_FAIL, 

    ADMIN_PROJECTKITCHENS_REQUEST, 
    ADMIN_PROJECTKITCHENS_SUCCESS, 
    ADMIN_PROJECTKITCHENS_FAIL, 

    NEW_PROJECTKITCHEN_REQUEST, 
    NEW_PROJECTKITCHEN_SUCCESS, 
    NEW_PROJECTKITCHEN_RESET,
    NEW_PROJECTKITCHEN_FAIL, 

    DELETE_PROJECTKITCHEN_REQUEST, 
    DELETE_PROJECTKITCHEN_SUCCESS, 
    DELETE_PROJECTKITCHEN_RESET,
    DELETE_PROJECTKITCHEN_FAIL, 

    UPDATE_PROJECTKITCHEN_REQUEST, 
    UPDATE_PROJECTKITCHEN_SUCCESS, 
    UPDATE_PROJECTKITCHEN_RESET,
    UPDATE_PROJECTKITCHEN_FAIL, 

    PROJECTKITCHEN_DETAILS_REQUEST,
    PROJECTKITCHEN_DETAILS_SUCCESS,
    PROJECTKITCHEN_DETAILS_FAIL,
    CLEAR_ERRORS
 } from "../constants/projectkitchenConstants";

 export const projectkitchensReducer = (state = { projectkitchens: [] }, action) => {
    switch(action.type) {
        case ALL_PROJECTKITCHENS_REQUEST:
        case ADMIN_PROJECTKITCHENS_REQUEST:
            return {
                loading: true,
                projectkitchens: [] 
            }
        case ALL_PROJECTKITCHENS_SUCCESS:
            return {
                loading: false,
                projectkitchens: action.payload.projectkitchens,
                projectkitchensCount: action.payload.projectkitchensCount,
                resPerPage: action.payload.resPerPage
               
            }


            case ADMIN_PROJECTKITCHENS_SUCCESS:
                return {
                    loading: false,
                    projectkitchens: action.payload
                    }
        case ALL_PROJECTKITCHENS_FAIL:
            case ADMIN_PROJECTKITCHENS_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}


export const newProjectkitchenReducer = (state = { projectkitchen: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTKITCHEN_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTKITCHEN_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectkitchen: action.payload.projectkitchen
            } 
               
        case NEW_PROJECTKITCHEN_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTKITCHEN_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectkitchenReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTKITCHEN_REQUEST:
        case UPDATE_PROJECTKITCHEN_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTKITCHEN_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTKITCHEN_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTKITCHEN_FAIL:
        case UPDATE_PROJECTKITCHEN_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTKITCHEN_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTKITCHEN_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}



export const projectkitchenDetailsReducer = (state = {projectkitchen: {} }, action ) => {
    switch (action.type) {

        case  PROJECTKITCHEN_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTKITCHEN_DETAILS_SUCCESS:
            return {
                loading: false,
                projectkitchen: action.payload
            }
        case  PROJECTKITCHEN_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}