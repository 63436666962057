import axios from 'axios'

import { 
    ALL_PROJECTWATERS_REQUEST, 
    ALL_PROJECTWATERS_SUCCESS, 
    ALL_PROJECTWATERS_FAIL, 

    ADMIN_PROJECTWATERS_REQUEST, 
    ADMIN_PROJECTWATERS_SUCCESS, 
    ADMIN_PROJECTWATERS_FAIL,

    NEW_PROJECTWATER_REQUEST, 
    NEW_PROJECTWATER_SUCCESS, 
    NEW_PROJECTWATER_FAIL, 

    DELETE_PROJECTWATER_REQUEST, 
    DELETE_PROJECTWATER_SUCCESS,
    DELETE_PROJECTWATER_FAIL,

    UPDATE_PROJECTWATER_REQUEST, 
    UPDATE_PROJECTWATER_SUCCESS,
    UPDATE_PROJECTWATER_FAIL, 

    PROJECTWATER_DETAILS_REQUEST,
    PROJECTWATER_DETAILS_SUCCESS,
    PROJECTWATER_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectwaterConstants";

export const getProjectwaters = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type:  ALL_PROJECTWATERS_REQUEST})

        let link = `/api/v1/projectwaters?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectwaters?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectwaters?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTWATERS_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.message)
        dispatch({
            type: ALL_PROJECTWATERS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectwater = (projectwaterData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTWATER_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectwater/new`, projectwaterData, config)

        dispatch({
            type: NEW_PROJECTWATER_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTWATER_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectwater (Admin)
export const deleteProjectwater = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTWATER_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectwater/${id}`)

        dispatch({
            type: DELETE_PROJECTWATER_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTWATER_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectwater (admin)
export const updateProjectwater = (id, projectwaterData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTWATER_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectwater/${id}`, projectwaterData, config)

        dispatch({
            type: UPDATE_PROJECTWATER_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTWATER_FAIL,
            payload: error.response.data.message
        })
    }
}


export const getProjectwaterDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTWATER_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectwater/${id}`)

        dispatch({
            type: PROJECTWATER_DETAILS_SUCCESS,
            payload: data.projectwater
        })

    } catch (error) {
        dispatch({
            type: PROJECTWATER_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectwaters = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTWATERS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectwaters`)

        dispatch({
            type: ADMIN_PROJECTWATERS_SUCCESS,
            payload: data.projectwaters
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTWATERS_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}