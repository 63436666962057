import axios from 'axios'

import { 
    ALL_PROJECTSOFTS_REQUEST, 
    ALL_PROJECTSOFTS_SUCCESS, 
    ALL_PROJECTSOFTS_FAIL, 

    ADMIN_PROJECTSOFTS_REQUEST, 
    ADMIN_PROJECTSOFTS_SUCCESS, 
    ADMIN_PROJECTSOFTS_FAIL,

    NEW_PROJECTSOFT_REQUEST, 
    NEW_PROJECTSOFT_SUCCESS, 
    NEW_PROJECTSOFT_FAIL, 

    DELETE_PROJECTSOFT_REQUEST, 
    DELETE_PROJECTSOFT_SUCCESS,
    DELETE_PROJECTSOFT_FAIL,

    UPDATE_PROJECTSOFT_REQUEST, 
    UPDATE_PROJECTSOFT_SUCCESS,
    UPDATE_PROJECTSOFT_FAIL, 

    PROJECTSOFT_DETAILS_REQUEST,
    PROJECTSOFT_DETAILS_SUCCESS,
    PROJECTSOFT_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectsoftConstants";

export const getProjectsofts = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTSOFTS_REQUEST })

        let link = `/api/v1/projectsofts?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectsofts?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectsofts?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTSOFTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTSOFTS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const newProjectsoft = (projectsoftData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTSOFT_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectsoft/new`, projectsoftData, config)

        dispatch({
            type: NEW_PROJECTSOFT_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTSOFT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectsoft (Admin)
export const deleteProjectsoft = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTSOFT_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectsoft/${id}`)

        dispatch({
            type: DELETE_PROJECTSOFT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTSOFT_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectsoft (admin)
export const updateProjectsoft = (id, projectsoftData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTSOFT_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectsoft/${id}`, projectsoftData, config)

        dispatch({
            type: UPDATE_PROJECTSOFT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTSOFT_FAIL,
            payload: error.response.data.message
        })
    }
}


export const getProjectsoftDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTSOFT_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectsoft/${id}`)

        dispatch({
            type: PROJECTSOFT_DETAILS_SUCCESS,
            payload: data.projectsoft
        })

    } catch (error) {
        dispatch({
            type: PROJECTSOFT_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectsofts = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTSOFTS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectsofts`)

        dispatch({
            type: ADMIN_PROJECTSOFTS_SUCCESS,
            payload: data.projectsofts
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTSOFTS_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}