import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom';

import MetaData from '../layout/MetaData';
import Loader from '../layout/Loader';
import Sidebar from './Sidebar';

import { useDispatch, useSelector } from 'react-redux';

import { getAdminProducts } from '../../actions/productActions';
import { getAdminProjectaccesories } from '../../actions/projectaccesoryActions';
import { getAdminProjectarchs } from '../../actions/projectarchActions';
import { getAdminProjectarts } from '../../actions/projectartActions';
import { getAdminProjectceilings } from '../../actions/projectceilingActions';
import { getAdminProjectfabrics } from '../../actions/projectfabricActions';
import { getAdminProjectfloors } from '../../actions/projectfloorActions';
import { getAdminProjectfurnitures } from '../../actions/projectfurnitureActions';
import { getAdminProjectkitchens } from '../../actions/projectkitchenActions';
import { getAdminProjectlandscapes } from '../../actions/projectlandscapeActions';
import { getAdminProjectlightings } from '../../actions/projectlightingActions';
import { getAdminProjectpaintings } from '../../actions/projectpaintingActions';
import { getAdminProjectplants } from '../../actions/projectplantActions';
import { getAdminProjectsofts } from '../../actions/projectsoftActions';
import { getAdminProjectwalls } from '../../actions/projectwallActions';
import { getAdminProjectwaters } from '../../actions/projectwaterActions';
import { getAdminProjectweldings } from '../../actions/projectweldingActions';

import { allOrders } from '../../actions/orderActions';
import { allUsers } from '../../actions/userActions'
const Dashboard = () => {

    const dispatch = useDispatch();
    const { products } = useSelector(state => state.products);
    const { projectaccesories } = useSelector(state => state.projectaccesories);
    const { projectarchs } = useSelector(state => state.projectarchs);
    const { projectarts } = useSelector(state => state.projectarts);
    const { projectceilings } = useSelector(state => state.projectceilings);
    const { projectfabrics } = useSelector(state => state.projectfabrics);
    const { projectfloors } = useSelector(state => state.projectfloors);
    const { projectfurnitures } = useSelector(state => state.projectfurnitures);
    const { projectkitchens } = useSelector(state => state.projectkitchens);
    const { projectlandscapes } = useSelector(state => state.projectlandscapes);
    const { projectlightings } = useSelector(state => state.projectlightings);
    const { projectpaintings } = useSelector(state => state.projectpaintings);
    const { projectplants } = useSelector(state => state.projectplants);
    const { projectsofts } = useSelector(state => state.projectsofts);
    const { projectwalls } = useSelector(state => state.projectwalls);
    const { projectwaters } = useSelector((state) => state.projectwaters);
    const { projectweldings } = useSelector((state) => state.projectweldings);

    const {orders, totalAmount, loading } = useSelector((state) => state.allOrders)
    const {users } = useSelector((state) => state.allUsers)



    let outOfStock = 0;
    products.forEach(product => {
        if(product.stock === 0) {
            outOfStock += 1;
        }
    })

    useEffect(() => {
        dispatch(getAdminProducts());
        dispatch(getAdminProjectaccesories());
        dispatch(getAdminProjectarchs());
        dispatch(getAdminProjectarts());
        dispatch(getAdminProjectceilings());
        dispatch(getAdminProjectfabrics());
        dispatch(getAdminProjectfloors());
        dispatch(getAdminProjectfurnitures());
        dispatch(getAdminProjectkitchens());
        dispatch(getAdminProjectlandscapes());
        dispatch(getAdminProjectlightings());
        dispatch(getAdminProjectpaintings());
        dispatch(getAdminProjectplants());
        dispatch(getAdminProjectsofts());
        dispatch(getAdminProjectwalls());
        dispatch(getAdminProjectwaters());
        dispatch(getAdminProjectweldings());
        dispatch(allOrders());
        dispatch(allUsers);
    }, [dispatch])
  return (
   <Fragment>
    <div className='row'>
        <div className='col-12 col-md-2'>
            <Sidebar /> 
        </div>

        <div className='col-12 col-md-10'>
            <h1 className='my-4'>Dashboard</h1>

            {loading ? <Loader /> : (
                <Fragment>
                    <MetaData title={'Admin Dashboard'} />
            <div className='row pr-4'>
                <div className='col-xl-12 col-sm-12 mb-3'>
                    <div className='card text-white bg-primary o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Total Amount<br />
                            <b>Ksh {totalAmount && totalAmount.toFixed(2)}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row pr-4'>
                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Products<br />
                            <b>{products && products.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/products'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>

                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-danger o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Orders<br /> <b>{orders && orders.length}</b> </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/orders'>
                        <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>

                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-danger o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Users<br /> <b>{users && users.length}</b> </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/users'>
                        <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>

                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-warning o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Out of Stock<br /> <b>{outOfStock}</b></div>
                        </div>
                    </div>
                </div>

                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Accesories<br />
                            <b>{projectaccesories && projectaccesories.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectaccesories'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Structures<br />
                            <b>{projectarchs && projectarchs.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectarchs'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Fine Art<br />
                            <b>{projectarts && projectarts.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectarts'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Ceilings<br />
                            <b>{projectceilings && projectceilings.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectceilings'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Fabrics<br />
                            <b>{projectfabrics && projectfabrics.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectfabrics'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Floors<br />
                            <b>{projectfloors && projectfloors.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectfloors'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Furnitures<br />
                            <b>{projectfurnitures && projectfurnitures.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectfurnitures'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Kitchens<br />
                            <b>{projectkitchens && projectkitchens.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectkitchens'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Landscapes<br />
                            <b>{projectlandscapes && projectlandscapes.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectlandscapes'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Lighting & accoustics<br />
                            <b>{projectlightings && projectlightings.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectlightings'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>House painting<br />
                            <b>{projectpaintings && projectpaintings.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectpaintings'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Plants & flowers<br />
                            <b>{projectplants && projectplants.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectplants'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Soft furnishes<br />
                            <b>{projectsofts && projectsofts.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectsofts'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Walls<br />
                            <b>{projectwalls && projectwalls.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectwalls'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Aquariums<br />
                            <b>{projectwaters && projectwaters.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectwaters'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>


                <div className='col-xl-3 col-sm-6 mb-3'>
                    <div className='card text-white bg-success o-hidden h-100'>
                        <div className='card-body'>
                            <div className='text-center card-font-size'>Welding<br />
                            <b>{projectweldings && projectweldings.length}</b>
                            </div>
                        </div>

                        <Link className='card-footer text-white clearfix small z-1' to='/admin/projectweldings'>
                            <span className='float-left'>View Details</span>
                            <span className='float-right'><i className='fa fa-angle-right'></i></span>
                        </Link>
                    </div>
                </div>
            </div>
            </Fragment>
            )}
            </div>
        </div>
    
   </Fragment>
  )
}

export default Dashboard