export const ADMIN_PROJECTACCESORIES_REQUEST = 'ADMIN_PROJECTACCESORIES_REQUEST'
export const ADMIN_PROJECTACCESORIES_SUCCESS = 'ADMIN_PROJECTACCESORIES_SUCCESS'
export const ADMIN_PROJECTACCESORIES_FAIL = 'ADMIN_PROJECTACCESORIES_FAIL'


export const ALL_PROJECTACCESORIES_REQUEST = 'ALL_PROJECTACCESORIES_REQUEST'
export const ALL_PROJECTACCESORIES_SUCCESS = 'ALL_PROJECTACCESORIES_SUCCESS'
export const ALL_PROJECTACCESORIES_FAIL = 'ALL_PROJECTACCESORIES_FAIL'

export const NEW_PROJECTACCESORY_REQUEST = 'NEW_PROJECTACCESORY_REQUEST'
export const NEW_PROJECTACCESORY_SUCCESS = 'NEW_PROJECTACCESORY_SUCCESS'
export const NEW_PROJECTACCESORY_RESET = 'NEW_PROJECTACCESORY_RESET'
export const NEW_PROJECTACCESORY_FAIL = 'NEW_PROJECTACCESORY_FAIL'

export const DELETE_PROJECTACCESORY_REQUEST = 'DELETE_PROJECTACCESORY_REQUEST'
export const DELETE_PROJECTACCESORY_SUCCESS = 'DELETE_PROJECTACCESORY_SUCCESS'
export const DELETE_PROJECTACCESORY_RESET = 'DELETE_PROJECTACCESORY_RESET'
export const DELETE_PROJECTACCESORY_FAIL = 'DELETE_PROJECTACCESORY_FAIL'

export const UPDATE_PROJECTACCESORY_REQUEST = 'UPDATE_PROJECTACCESORY_REQUEST'
export const UPDATE_PROJECTACCESORY_SUCCESS = 'UPDATE_PROJECTACCESORY_SUCCESS'
export const UPDATE_PROJECTACCESORY_RESET = 'UPDATE_PROJECTACCESORY_RESET'
export const UPDATE_PROJECTACCESORY_FAIL = 'UPDATE_PROJECTACCESORY_FAIL'

export const PROJECTACCESORY_DETAILS_REQUEST = 'PROJECTACCESORY_DETAILS_REQUEST'
export const PROJECTACCESORY_DETAILS_SUCCESS = 'PROJECTACCESORY_DETAILS_SUCCESS'
export const PROJECTACCESORY_DETAILS_FAIL = 'PROJECTACCESORY_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'