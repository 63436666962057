import axios from 'axios'

import { 
    ALL_PROJECTARTS_REQUEST, 
    ALL_PROJECTARTS_SUCCESS, 
    ALL_PROJECTARTS_FAIL, 

    ADMIN_PROJECTARTS_REQUEST, 
    ADMIN_PROJECTARTS_SUCCESS, 
    ADMIN_PROJECTARTS_FAIL,

    NEW_PROJECTART_REQUEST, 
    NEW_PROJECTART_SUCCESS, 
    NEW_PROJECTART_FAIL, 

    DELETE_PROJECTART_REQUEST, 
    DELETE_PROJECTART_SUCCESS,
    DELETE_PROJECTART_FAIL,

    UPDATE_PROJECTART_REQUEST, 
    UPDATE_PROJECTART_SUCCESS,
    UPDATE_PROJECTART_FAIL, 

    PROJECTART_DETAILS_REQUEST,
    PROJECTART_DETAILS_SUCCESS,
    PROJECTART_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectartConstants";

export const getProjectarts = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTARTS_REQUEST })

        let link = `/api/v1/projectarts?page=${currentPage}`

        // const { data } = await axios.get(`/api/v1/projectarts?page=${currentPage}`)
 
        if(category) {
          link = `/api/v1/projectarts?page=${currentPage}&category=${category}`
        }
 
        const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTARTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTARTS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectart = (projectartData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTART_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectart/new`, projectartData, config)

        dispatch({
            type: NEW_PROJECTART_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTART_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectart (Admin)
export const deleteProjectart = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTART_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectart/${id}`)

        dispatch({
            type: DELETE_PROJECTART_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTART_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectart (admin)
export const updateProjectart = (id, projectartData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTART_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectart/${id}`, projectartData, config)

        dispatch({
            type: UPDATE_PROJECTART_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTART_FAIL,
            payload: error.response.data.message
        })
    }
}


export const getProjectartDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTART_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectart/${id}`)

        dispatch({
            type: PROJECTART_DETAILS_SUCCESS,
            payload: data.projectart
        })

    } catch (error) {
        dispatch({
            type: PROJECTART_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectarts = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTARTS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectarts`)

        dispatch({
            type: ADMIN_PROJECTARTS_SUCCESS,
            payload: data.projectarts
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTARTS_FAIL,
            payload: error.response.data.message
        })
    }
} 
// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}