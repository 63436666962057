import { 
    ALL_PROJECTWALLS_REQUEST,
    ALL_PROJECTWALLS_SUCCESS, 
    ALL_PROJECTWALLS_FAIL,

    ADMIN_PROJECTWALLS_REQUEST, 
    ADMIN_PROJECTWALLS_SUCCESS, 
    ADMIN_PROJECTWALLS_FAIL, 

    NEW_PROJECTWALL_REQUEST, 
    NEW_PROJECTWALL_SUCCESS, 
    NEW_PROJECTWALL_RESET,
    NEW_PROJECTWALL_FAIL, 

    DELETE_PROJECTWALL_REQUEST, 
    DELETE_PROJECTWALL_SUCCESS, 
    DELETE_PROJECTWALL_RESET,
    DELETE_PROJECTWALL_FAIL, 

    UPDATE_PROJECTWALL_REQUEST, 
    UPDATE_PROJECTWALL_SUCCESS, 
    UPDATE_PROJECTWALL_RESET,
    UPDATE_PROJECTWALL_FAIL, 
    
    PROJECTWALL_DETAILS_REQUEST,
    PROJECTWALL_DETAILS_SUCCESS,
    PROJECTWALL_DETAILS_FAIL,
    CLEAR_ERRORS 
 } from "../constants/projectwallConstants";

 export const projectwallsReducer = (state = { projectwalls: [] }, action )=> {
    switch(action.type) {
        case ALL_PROJECTWALLS_REQUEST:
        case ADMIN_PROJECTWALLS_REQUEST:
            return {
                loading: true,
                projectwalls: [] 
            }
        case ALL_PROJECTWALLS_SUCCESS:
            return {
                loading: false,
                projectwalls: action.payload.projectwalls,
                projectwallsCount: action.payload.projectwallsCount,
                resPerPage: action.payload.resPerPage
               
            }

            case ADMIN_PROJECTWALLS_SUCCESS:
                return {
                    loading: false,
                    projectwalls: action.payload
                    }
        case ALL_PROJECTWALLS_FAIL:
            case ADMIN_PROJECTWALLS_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}


export const newProjectwallReducer = (state = { projectwall: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTWALL_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTWALL_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectwall: action.payload.projectwall
            } 
               
        case NEW_PROJECTWALL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTWALL_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectwallReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTWALL_REQUEST:
        case UPDATE_PROJECTWALL_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTWALL_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTWALL_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTWALL_FAIL:
        case UPDATE_PROJECTWALL_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTWALL_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTWALL_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectwallDetailsReducer = (state = {projectwall: {} }, action ) => {
    switch (action.type) {

        case  PROJECTWALL_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTWALL_DETAILS_SUCCESS:
            return {
                loading: false,
                projectwall: action.payload
            }
        case  PROJECTWALL_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}