import axios from 'axios'

import { 
    ALL_PROJECTLANDSCAPES_REQUEST, 
    ALL_PROJECTLANDSCAPES_SUCCESS, 
    ALL_PROJECTLANDSCAPES_FAIL, 

    ADMIN_PROJECTLANDSCAPES_REQUEST, 
    ADMIN_PROJECTLANDSCAPES_SUCCESS, 
    ADMIN_PROJECTLANDSCAPES_FAIL,


    NEW_PROJECTLANDSCAPE_REQUEST, 
    NEW_PROJECTLANDSCAPE_SUCCESS, 
    NEW_PROJECTLANDSCAPE_FAIL, 

    DELETE_PROJECTLANDSCAPE_REQUEST, 
    DELETE_PROJECTLANDSCAPE_SUCCESS,
    DELETE_PROJECTLANDSCAPE_FAIL,

    UPDATE_PROJECTLANDSCAPE_REQUEST, 
    UPDATE_PROJECTLANDSCAPE_SUCCESS,
    UPDATE_PROJECTLANDSCAPE_FAIL, 

    PROJECTLANDSCAPE_DETAILS_REQUEST,
    PROJECTLANDSCAPE_DETAILS_SUCCESS,
    PROJECTLANDSCAPE_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectlandscapeConstants";

export const getProjectlandscapes = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTLANDSCAPES_REQUEST })

        let link = `/api/v1/projectlandscapes?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectlandscapes?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectlandscapes?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTLANDSCAPES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTLANDSCAPES_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectlandscape = (projectlandscapeData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTLANDSCAPE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectlandscape/new`, projectlandscapeData, config)

        dispatch({
            type: NEW_PROJECTLANDSCAPE_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTLANDSCAPE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectlandscape (Admin)
export const deleteProjectlandscape = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTLANDSCAPE_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectlandscape/${id}`)

        dispatch({
            type: DELETE_PROJECTLANDSCAPE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTLANDSCAPE_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectlandscape (admin)
export const updateProjectlandscape = (id, projectlandscapeData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTLANDSCAPE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectlandscape/${id}`, projectlandscapeData, config)

        dispatch({
            type: UPDATE_PROJECTLANDSCAPE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTLANDSCAPE_FAIL,
            payload: error.response.data.message
        })
    }
}



export const getProjectlandscapeDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTLANDSCAPE_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectlandscape/${id}`)

        dispatch({
            type: PROJECTLANDSCAPE_DETAILS_SUCCESS,
            payload: data.projectlandscape
        })

    } catch (error) {
        dispatch({
            type: PROJECTLANDSCAPE_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectlandscapes = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTLANDSCAPES_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectlandscapes`)

        dispatch({
            type: ADMIN_PROJECTLANDSCAPES_SUCCESS,
            payload: data.projectlandscapes
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTLANDSCAPES_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}