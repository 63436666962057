export const ADMIN_PROJECTFURNITURES_REQUEST = 'ADMIN_PROJECTFURNITURES_REQUEST'
export const ADMIN_PROJECTFURNITURES_SUCCESS = 'ADMIN_PROJECTFURNITURES_SUCCESS'
export const ADMIN_PROJECTFURNITURES_FAIL = 'ADMIN_PROJECTFURNITURES_FAIL'

export const ALL_PROJECTFURNITURES_REQUEST = 'ALL_PROJECTFURNITURES_REQUEST'
export const ALL_PROJECTFURNITURES_SUCCESS = 'ALL_PROJECTFURNITURES_SUCCESS'
export const ALL_PROJECTFURNITURES_FAIL = 'ALL_PROJECTFURNITURES_FAIL'


export const NEW_PROJECTFURNITURE_REQUEST = 'NEW_PROJECTFURNITURE_REQUEST'
export const NEW_PROJECTFURNITURE_SUCCESS = 'NEW_PROJECTFURNITURE_SUCCESS'
export const NEW_PROJECTFURNITURE_RESET = 'NEW_PROJECTFURNITURE_RESET'
export const NEW_PROJECTFURNITURE_FAIL = 'NEW_PROJECTFURNITURE_FAIL'

export const DELETE_PROJECTFURNITURE_REQUEST = 'DELETE_PROJECTFURNITURE_REQUEST'
export const DELETE_PROJECTFURNITURE_SUCCESS = 'DELETE_PROJECTFURNITURE_SUCCESS'
export const DELETE_PROJECTFURNITURE_RESET = 'DELETE_PROJECTFURNITURE_RESET'
export const DELETE_PROJECTFURNITURE_FAIL = 'DELETE_PROJECTFURNITURE_FAIL'

export const UPDATE_PROJECTFURNITURE_REQUEST = 'UPDATE_PROJECTFURNITURE_REQUEST'
export const UPDATE_PROJECTFURNITURE_SUCCESS = 'UPDATE_PROJECTFURNITURE_SUCCESS'
export const UPDATE_PROJECTFURNITURE_RESET = 'UPDATE_PROJECTFURNITURE_RESET'
export const UPDATE_PROJECTFURNITURE_FAIL = 'UPDATE_PROJECTFURNITURE_FAIL'

export const PROJECTFURNITURE_DETAILS_REQUEST = 'PROJECTFURNITURE_DETAILS_REQUEST'
export const PROJECTFURNITURE_DETAILS_SUCCESS = 'PROJECTFURNITURE_DETAILS_SUCCESS'
export const PROJECTFURNITURE_DETAILS_FAIL = 'PROJECTFURNITURE_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'