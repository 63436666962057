import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'

import MetaData from '../layout/MetaData'
import Loader from '../layout/Loader'
import Sidebar from './Sidebar'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminProjectsofts, deleteProjectsoft, clearErrors } from '../../actions/projectsoftActions'
import { DELETE_PROJECTSOFT_RESET } from '../../constants/projectsoftConstants'


const ProjectsoftsList = () => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error, projectsofts } = useSelector(state => state.projectsofts);
    const { error: deleteError, isDeleted } = useSelector(state => state.projectsoft);

    useEffect(() => {
        dispatch(getAdminProjectsofts());

        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }

        if (deleteError) {
            alert.error(deleteError);
            dispatch(clearErrors())
        }

        if(isDeleted) {
            alert.success('Projectsoft deleted succesfully');
            navigate('/admin/projectsofts');
            dispatch({ type: DELETE_PROJECTSOFT_RESET })
        }
    }, [dispatch, alert, error, deleteError, isDeleted])

    const setProjectsofts = () => {
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'id',
                    sort: 'asc'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                },
            ],
            rows: []
        }

        projectsofts.forEach(projectsoft => {
            data.rows.push({
                id: projectsoft._id,
                name: projectsoft.name,
                actions: <Fragment>
                    <Link to={`/admin/projectsoft/${projectsoft._id}`} className="btn btn-primary py-1 px-2">
                        <i className="fa fa-pencil"></i>
                    </Link>

                    <button className='btn btn-danger py-1 px-2 ml-2' onClick={() => deleteProjectsoftHandler(projectsoft._id)}>
                        <i className="fa fa-trash"></i></button>
                    </Fragment>
            })
        })

        return data;
    }

    const deleteProjectsoftHandler = (id) => {
        dispatch(deleteProjectsoft(id))
    }

  return (
   <Fragment>
    <MetaData title={'All Softs'} />
    <div className='row'>
        <div className='col-12 col-md-2'>
            <Sidebar/>
        </div>

        <div className='col-12 col-md-10'>
            <Fragment>
                <h1 className='my-5'>All Softs</h1>

                {loading ? <Loader /> : (
                      <MDBDataTable
                      data={setProjectsofts()}
                      className="px-3"
                      bordered
                      striped
                      hover
                  />
                )}
            </Fragment>
        </div>
    </div>
   </Fragment>
  )
}

export default ProjectsoftsList 