import React from "react";
import { Link } from "react-router-dom";

const Product = ({ product }) => {
  return (
    <div className="page-list">
      <Link to={`/product/${product._id}`}>
        <img
          src={product.images[0].url}
          className="card-img-top mx-auto product-thumb"
        ></img>
      </Link>

      <div className="title font-rale text-dark text-center font-size-16">
       
          <Link to={`/product/${product._id}`}><h6>{product.name}</h6></Link>
          

        <span> Ksh {product.price}</span>

        <div className="big-relative">
        <p id="product_id" className="role">
          <strong>Read Product role...</strong>
        </p>

        <div className="buy-dropdown-menu">
         <Link to={`/product/${product._id}`}> <small>View Details</small></Link>

          <div className="project-items ">
            <p>{product.role}</p>
          </div>
        </div>
    </div>

        <Link
          type="button"
          to={`/product/${product._id}`}
          id="view_btn"
          className="btn btn-block "
        >
          View Details
        </Link>

      </div>
    </div>
  );
};

export default Product;
