import { 
    ALL_PROJECTACCESORIES_REQUEST, 
    ALL_PROJECTACCESORIES_SUCCESS, 
    ALL_PROJECTACCESORIES_FAIL, 

    ADMIN_PROJECTACCESORIES_REQUEST, 
    ADMIN_PROJECTACCESORIES_SUCCESS, 
    ADMIN_PROJECTACCESORIES_FAIL, 

    NEW_PROJECTACCESORY_REQUEST, 
    NEW_PROJECTACCESORY_SUCCESS, 
    NEW_PROJECTACCESORY_RESET,
    NEW_PROJECTACCESORY_FAIL, 

    DELETE_PROJECTACCESORY_REQUEST, 
    DELETE_PROJECTACCESORY_SUCCESS, 
    DELETE_PROJECTACCESORY_RESET,
    DELETE_PROJECTACCESORY_FAIL, 

    UPDATE_PROJECTACCESORY_REQUEST, 
    UPDATE_PROJECTACCESORY_SUCCESS, 
    UPDATE_PROJECTACCESORY_RESET,
    UPDATE_PROJECTACCESORY_FAIL, 

    PROJECTACCESORY_DETAILS_REQUEST,
    PROJECTACCESORY_DETAILS_SUCCESS,
    PROJECTACCESORY_DETAILS_FAIL,

    CLEAR_ERRORS 
} from "../constants/projectaccesoryConstants";

export const projectaccesoriesReducer = (state = { projectaccesories: [] }, action) => {
    switch(action.type) {
        case ALL_PROJECTACCESORIES_REQUEST:
        case ADMIN_PROJECTACCESORIES_REQUEST:
            return {
                loading: true,
                projectaccesories: [] 
            }
        case ALL_PROJECTACCESORIES_SUCCESS:
            return {
                loading: false,
                projectaccesories: action.payload.projectaccesories,
                projectaccesoriesCount: action.payload.projectaccesoriesCount,
                resPerPage: action.payload.resPerPage
               
            }

        case ADMIN_PROJECTACCESORIES_SUCCESS:
            return {
                loading: false,
                projectaccesories: action.payload
            }
        
        
        case ALL_PROJECTACCESORIES_FAIL:
        case ADMIN_PROJECTACCESORIES_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}


export const newProjectaccesoryReducer = (state = { projectaccesory: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTACCESORY_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTACCESORY_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectaccesory: action.payload.projectaccesory
            } 
               
        case NEW_PROJECTACCESORY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTACCESORY_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectaccesoryReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTACCESORY_REQUEST:
        case UPDATE_PROJECTACCESORY_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTACCESORY_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTACCESORY_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTACCESORY_FAIL:
        case UPDATE_PROJECTACCESORY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTACCESORY_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTACCESORY_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectaccesoryDetailsReducer = (state = {projectaccesory: {} }, action ) => {
    switch (action.type) {

        case  PROJECTACCESORY_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTACCESORY_DETAILS_SUCCESS:
            return {
                loading: false,
                projectaccesory: action.payload
            }
        case  PROJECTACCESORY_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}