export const ADMIN_PROJECTWATERS_REQUEST = 'ADMIN_PROJECTWATERS_REQUEST'
export const ADMIN_PROJECTWATERS_SUCCESS = 'ADMIN_PROJECTWATERS_SUCCESS'
export const ADMIN_PROJECTWATERS_FAIL = 'ADMIN_PROJECTWATERS_FAIL'

export const ALL_PROJECTWATERS_REQUEST = 'ALL_PROJECTWATERS_REQUEST'
export const ALL_PROJECTWATERS_SUCCESS = 'ALL_PROJECTWATERS_SUCCESS'
export const ALL_PROJECTWATERS_FAIL = 'ALL_PROJECTWATERS_FAIL'

export const NEW_PROJECTWATER_REQUEST = 'NEW_PROJECTWATER_REQUEST'
export const NEW_PROJECTWATER_SUCCESS = 'NEW_PROJECTWATER_SUCCESS'
export const NEW_PROJECTWATER_RESET = 'NEW_PROJECTWATER_RESET'
export const NEW_PROJECTWATER_FAIL = 'NEW_PROJECTWATER_FAIL'

export const DELETE_PROJECTWATER_REQUEST = 'DELETE_PROJECTWATER_REQUEST'
export const DELETE_PROJECTWATER_SUCCESS = 'DELETE_PROJECTWATER_SUCCESS'
export const DELETE_PROJECTWATER_RESET = 'DELETE_PROJECTWATER_RESET'
export const DELETE_PROJECTWATER_FAIL = 'DELETE_PROJECTWATER_FAIL'

export const UPDATE_PROJECTWATER_REQUEST = 'UPDATE_PROJECTWATER_REQUEST'
export const UPDATE_PROJECTWATER_SUCCESS = 'UPDATE_PROJECTWATER_SUCCESS'
export const UPDATE_PROJECTWATER_RESET = 'UPDATE_PROJECTWATER_RESET'
export const UPDATE_PROJECTWATER_FAIL = 'UPDATE_PROJECTWATER_FAIL'

export const PROJECTWATER_DETAILS_REQUEST = 'PROJECTWATER_DETAILS_REQUEST'
export const PROJECTWATER_DETAILS_SUCCESS = 'PROJECTWATER_DETAILS_SUCCESS'
export const PROJECTWATER_DETAILS_FAIL = 'PROJECTWATER_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'