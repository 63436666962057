import React, { Fragment, useEffect, useState } from "react";
import Searchwelding from "../layout/Searchwelding";
import { Carousel } from 'react-bootstrap'
//import Carousel from "react-elastic-carousel";
//import Slider from "react-slick";
import Loader from "../layout/Loader";
import Product from "../product/Product";
import MetaData from "../layout/MetaData";
import { Link, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectweldingDetails,
  clearErrors,
} from "../../actions/projectweldingActions";
import { addItemToCart } from "../../actions/cartActions";
import { getProducts } from "../../actions/productActions";

const breakPoints = [
  { width: 1, itemsToShow: 2 },
  { width: 550, itemsToShow: 3 },
  { width: 768, itemsToShow: 4 },
  { width: 1024, itemsToShow: 5 },
];

const ProjectweldingDetails = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const params = useParams();

  const { loading, error, projectwelding } = useSelector(
    (state) => state.projectweldingDetails
  );

  const { products } = useSelector((state) => state.products);

  //const { connector, setConnector } = useState()

  useEffect(() => {
    dispatch(getProjectweldingDetails(params.id));
    dispatch(getProducts());

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, alert, error, params.id]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={projectwelding.name} />
          <div className="container">
            <div className="row d-flex justify-content-around">
            <div
                className="col-12 col-lg-7 col-xs-12 img-fluid"
                id="product_image"
              >
                {/* <img src='../images/bathtub.jpg' alt='sdf' height='500' width='500'></img> */}
                <Carousel pause="hover">
                  {projectwelding.images &&
                    projectwelding.images.map((image) => (
                      <Carousel.Item key={image.public_id}>
                        <img
                          src={image.url}
                          alt={projectwelding.title}
                          className="w-100"
                        ></img>
                      </Carousel.Item>
                    ))}
                </Carousel>
              </div>

              <div className="col-12 col-lg-5 mt-5">
                <h3 className=" bluishColor ">{projectwelding.name}</h3>
                <p id="product_id">Project # {projectwelding._id}</p>

                <p
                  id="product_seller mb-3 bluishColor"
                  className="font-size-16 font-rale"
                >
                  You can have this or better
                  <strong className=" bluishColor">
                    &nbsp; Build your design...with us
                  </strong>
                  <span>&nbsp;Call 0796320745</span>
                </p>

                <hr></hr>

                <h4 className="mt-2  bluishColor">Description</h4>
                <p className='maelezo'>{projectwelding.description}</p>

                <hr></hr>

                <section>
                  <h6 className="mt-2 bluishColor">
                    In the search below, please type this Project's title and
                    click to see the products & materials that have been used to
                    design this project.
                  </h6>
                  <hr></hr>

                  <p>
                    Project Title=&nbsp; <b>{projectwelding.name}</b>
                  </p>

                  <div className="col-12 mt-2 mt-md-0">
                    <Searchwelding />
                  </div>
                </section>

                <h5 className="mt-2  bluishColor">
                  Fundis &nbsp;
                  <span className="font-size-12 font-rale">
                    For accountability, kindly notify us of any engagements with
                    our fundis. Call/Whatsapp-0796320745
                  </span>
                </h5>
                <p className='maelezo'>{projectwelding.fundi}</p>
              </div>
            </div>

           
            <h6 className="mt-2 bluishColor">Shop</h6>
            <hr></hr>

            <div className="media-scroller">
            {products &&
                    products.map((product) => (
                      <div className="media-element">
                        <Link to={`/product/${product._id}`}>
                          <img
                            src={product.images[0].url}
                            class="card-img-top mx-auto product-thumb"
                          ></img>
                        </Link>

                        <div className=" title font-rale text-dark text-center font-size-16 ">
                          <Link to={`/product/${product._id}`}>
                            <h6>{product.name}</h6>
                          </Link>

                          <span> Ksh {product.price}</span>


                          <div className="big-relative">
                            <p id="product_id" className="role">
                              <strong>Read Product role...</strong>
                            </p>

                            <div className="buy-dropdown-menu ">
                              <Link to={`/product/${product._id}`}>
                                <small>View Details</small>
                              </Link>

                              <div className="project-items ">
                                <p>{product.role}</p>
                              </div>
                            </div>
                          </div>

                          <Link
                            type="button"
                            to={`/product/${product._id}`}
                            id="view_btn"
                            className="btn btn-block "
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    ))}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProjectweldingDetails;
