import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <Fragment>

  <footer id="footer" class="bg-dark text-white py-5 mt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-12">
          <h4 class="font-rubik font-size-20">MOODBOARD</h4>
          <p class="font-size-14 font-rale text-white-50">A mood board is a set of designs and ideas around which you create your own design. This platform displays several designs for various aspects of a living space from which you can get insipiration. You can order all the materials and tools that have been used to put up the projects on display. Feel free to order the products such as seats, bulbs, appliances & anything that appears in any project image. Lastly, call us to enjoy our discounted design services. Our strength is creativity, originality & functionality. </p>
        </div>
        <div class="col-lg-4 col-12">
        <h4 class="font-rubik font-size-20">ABOUT US</h4>
          <p class="font-size-14 font-rale text-white-50">We are a team of professionals made up of Interior designers, Architects, Engineers, Fine artists, Qs's and all specialists required to put up functional living spaces. Call 0796320745 for inquiries. </p>
        </div>
        <div class="col-lg-4 col-12">
        <h4 class="font-rubik font-size-20">HOW TO BUY</h4>
          <p class="font-size-14 font-rale text-white-50">For every project, there are products, tools, materials, services and expertise that have been used to put up the projects. To get products, either go directly to the <Link to="/shop">shop</Link>, and if viewing a project; To get anything in the project, just type the project's name in the search component under the project. This will redireact you to a page containing every product, tool or material used to build that project. To get fundis/specialists, go to project details by clicking view more, you will see a list of all fundis below the project images. For professional services, call 0796320745 for discounted professional services</p>
        </div>
      </div>
    </div>
  </footer>
  <div class="copyright text-center bg-dark text-white py-2">
    <p>@Build your design &copy;2023</p>
  </div>

    </Fragment>
  )
}

export default Footer
