export const ADMIN_PROJECTFLOORS_REQUEST = 'ADMIN_PROJECTFLOORS_REQUEST'
export const ADMIN_PROJECTFLOORS_SUCCESS = 'ADMIN_PROJECTFLOORS_SUCCESS'
export const ADMIN_PROJECTFLOORS_FAIL = 'ADMIN_PROJECTFLOORS_FAIL'

export const ALL_PROJECTFLOORS_REQUEST = 'ALL_PROJECTFLOORS_REQUEST'
export const ALL_PROJECTFLOORS_SUCCESS = 'ALL_PROJECTFLOORS_SUCCESS'
export const ALL_PROJECTFLOORS_FAIL = 'ALL_PROJECTFLOORS_FAIL'


export const NEW_PROJECTFLOOR_REQUEST = 'NEW_PROJECTFLOOR_REQUEST'
export const NEW_PROJECTFLOOR_SUCCESS = 'NEW_PROJECTFLOOR_SUCCESS'
export const NEW_PROJECTFLOOR_RESET = 'NEW_PROJECTFLOOR_RESET'
export const NEW_PROJECTFLOOR_FAIL = 'NEW_PROJECTFLOOR_FAIL'

export const DELETE_PROJECTFLOOR_REQUEST = 'DELETE_PROJECTFLOOR_REQUEST'
export const DELETE_PROJECTFLOOR_SUCCESS = 'DELETE_PROJECTFLOOR_SUCCESS'
export const DELETE_PROJECTFLOOR_RESET = 'DELETE_PROJECTFLOOR_RESET'
export const DELETE_PROJECTFLOOR_FAIL = 'DELETE_PROJECTFLOOR_FAIL'

export const UPDATE_PROJECTFLOOR_REQUEST = 'UPDATE_PROJECTFLOOR_REQUEST'
export const UPDATE_PROJECTFLOOR_SUCCESS = 'UPDATE_PROJECTFLOOR_SUCCESS'
export const UPDATE_PROJECTFLOOR_RESET = 'UPDATE_PROJECTFLOOR_RESET'
export const UPDATE_PROJECTFLOOR_FAIL = 'UPDATE_PROJECTFLOOR_FAIL'

export const PROJECTFLOOR_DETAILS_REQUEST = 'PROJECTFLOOR_DETAILS_REQUEST'
export const PROJECTFLOOR_DETAILS_SUCCESS = 'PROJECTFLOOR_DETAILS_SUCCESS'
export const PROJECTFLOOR_DETAILS_FAIL = 'PROJECTFLOOR_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'