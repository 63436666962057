import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'

import MetaData from '../layout/MetaData'
import Loader from '../layout/Loader'
import Sidebar from './Sidebar'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminProjectlandscapes, deleteProjectlandscape, clearErrors } from '../../actions/projectlandscapeActions'
import { DELETE_PROJECTLANDSCAPE_RESET } from '../../constants/projectlandscapeConstants'


const ProjectlandscapesList = () => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error, projectlandscapes } = useSelector(state => state.projectlandscapes);
    const { error: deleteError, isDeleted } = useSelector(state => state.projectlandscape)

    useEffect(() => {
        dispatch(getAdminProjectlandscapes());

        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }

        if (deleteError) {
            alert.error(deleteError);
            dispatch(clearErrors())
        }

        if(isDeleted) {
            alert.success('Projectlandscape deleted succesfully');
            navigate('/admin/projectlandscapes');
            dispatch({ type: DELETE_PROJECTLANDSCAPE_RESET })
        }
    }, [dispatch, alert, error, deleteError, isDeleted])

    const setProjectlandscapes = () => {
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'id',
                    sort: 'asc'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                },
            ],
            rows: []
        }

        projectlandscapes.forEach(projectlandscape => {
            data.rows.push({
                id: projectlandscape._id,
                name: projectlandscape.name,
                actions: <Fragment>
                    <Link to={`/admin/projectlandscape/${projectlandscape._id}`} className="btn btn-primary py-1 px-2">
                        <i className="fa fa-pencil"></i>
                    </Link>

                    <button className='btn btn-danger py-1 px-2 ml-2' onClick={() => deleteProjectlandscapeHandler(projectlandscape._id)}>
                        <i className="fa fa-trash"></i></button>
                    </Fragment>
            })
        })

        return data;
    }

    const deleteProjectlandscapeHandler = (id) => {
        dispatch(deleteProjectlandscape(id))
    }

  return (
   <Fragment>
    <MetaData title={'All Landscapes'} />
    <div className='row'>
        <div className='col-12 col-md-2'>
            <Sidebar/>
        </div>

        <div className='col-12 col-md-10'>
            <Fragment>
                <h1 className='my-5'>All Landscapes</h1>

                {loading ? <Loader /> : (
                      <MDBDataTable
                      data={setProjectlandscapes()}
                      className="px-3"
                      bordered
                      striped
                      hover
                  />
                )}
            </Fragment>
        </div>
    </div>
   </Fragment>
  )
}

export default ProjectlandscapesList 