import React, { Fragment, useEffect } from 'react'

import { Link } from 'react-router-dom'
import MetaData from './layout/MetaData'

import { useDispatch, useSelector } from 'react-redux';


import { getProjectaccesories } from '../actions/projectaccesoryActions';
import { getProjectarchs } from '../actions/projectarchActions';
import { getProjectarts } from '../actions/projectartActions';
import { getProjectceilings } from '../actions/projectceilingActions';
import { getProjectfabrics } from '../actions/projectfabricActions';
import { getProjectfloors } from '../actions/projectfloorActions';
import { getProjectfurnitures } from '../actions/projectfurnitureActions';
import { getProjectkitchens } from '../actions/projectkitchenActions';
import { getProjectlandscapes } from '../actions/projectlandscapeActions';
import { getProjectlightings } from '../actions/projectlightingActions';
import { getProjectpaintings } from '../actions/projectpaintingActions';
import { getProjectplants } from '../actions/projectplantActions';
import { getProjectsofts } from '../actions/projectsoftActions';
import { getProjectwalls } from '../actions/projectwallActions';
import { getProjectwaters } from '../actions/projectwaterActions';
import { getProjectweldings } from '../actions/projectweldingActions';




const Home = () => {

  const dispatch = useDispatch();

  const { projectaccesories } = useSelector(state => state.projectaccesories);
  const { projectarchs } = useSelector(state => state.projectarchs);
  const { projectarts } = useSelector(state => state.projectarts);
  const { projectceilings } = useSelector(state => state.projectceilings);
  const { projectfabrics } = useSelector(state => state.projectfabrics);
  const { projectfloors } = useSelector(state => state.projectfloors);
  const { projectfurnitures } = useSelector(state => state.projectfurnitures);
  const { projectkitchens } = useSelector(state => state.projectkitchens);
  const { projectlandscapes } = useSelector(state => state.projectlandscapes);
  const { projectlightings } = useSelector(state => state.projectlightings);
  const { projectpaintings } = useSelector(state => state.projectpaintings);
  const { projectplants } = useSelector(state => state.projectplants);
  const { projectsofts } = useSelector(state => state.projectsofts);
  const { projectwalls } = useSelector(state => state.projectwalls);
  const { projectwaters } = useSelector((state) => state.projectwaters);
  const { projectweldings } = useSelector((state) => state.projectweldings);


  useEffect(() => {
 
    dispatch(getProjectaccesories());
    dispatch(getProjectarchs());
    dispatch(getProjectarts());
    dispatch(getProjectceilings());
    dispatch(getProjectfabrics());
    dispatch(getProjectfloors());
    dispatch(getProjectfurnitures());
    dispatch(getProjectkitchens());
    dispatch(getProjectlandscapes());
    dispatch(getProjectlightings());
    dispatch(getProjectpaintings());
    dispatch(getProjectplants());
    dispatch(getProjectsofts());
    dispatch(getProjectwalls());
    dispatch(getProjectwaters());
    dispatch(getProjectweldings());
}, [dispatch])



  return (

   
    <Fragment>
       <MetaData title={'Projects'} />

       <div className="mission-statement top-tag font-size-16 font-rale text-dark  bg-light ">

       <input id="ch" type="checkbox"></input>

    <h1 className='top-tag font-size-16 font-rale text-dark'>A mood board is a set of designs and ideas around which you create your own design.... </h1>  <span className='moreText'>  This platform displays several designs for various aspects of a living space from which you can get insipiration. You can order all the materials, tools & products that have been used to put up the projects displayed here. Feel free to order products such as seats, bulbs, appliances & anything that appears in any project image. Lastly, call us to enjoy our discounted design services. Our strength is creativity, functionality and originality. <label htmlFor="ch"><bold className='bodl'>Show less</bold></label> </span> 

    <label htmlFor="ch"><bold className='bodl'>Read More</bold></label>

    </div>
    


    <div className='page-container'>
    
      <div className="page-list text-center">
        <Link to={`/Ceiling`}><img src="./images/moodceiling.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Ceiling`} className="text-dark font-rubik font-size-16">Ceiling design&nbsp;({projectceilings && projectceilings.length} projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Painting`} ><img src="./images/Interior20Paint.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Painting`} className="text-dark font-rubik font-size-16">House painting&nbsp;({projectpaintings && projectpaintings.length} projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Walls`}><img src="./images/Walls.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Walls`} className="text-dark font-rubik font-size-16">Wall finishes&nbsp;({projectwalls && projectwalls.length} projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Floor`}><img src="./images/epoxxy.jpeg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Floor`} className="text-dark font-rubik font-size-16">Floor finishes&nbsp;({projectfloors && projectfloors.length} projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Kitchen`} ><img src="./images/island-modular-kitchen-design.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Kitchen`}  className="text-dark font-rubik font-size-16">Kitchen Design&nbsp;({projectkitchens && projectkitchens.length} projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Lighting`}><img src="./images/lighting.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Lighting`} className="text-dark font-rubik font-size-16">Lighting Design&nbsp;({projectlightings && projectlightings.length} projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Furniture`}><img src="./images/VITI.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Furniture`} className="text-dark font-rubik font-size-16">Furniture Design&nbsp;({projectfurnitures && projectfurnitures.length} projects)</Link>
          </div>
        </div>
      </div>
      <div className="page-list text-center">
        <Link to={`/Softs`}><img src="./images/pleat.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Softs`} className="text-dark font-rubik font-size-16">Soft furnishing&nbsp;({projectsofts && projectsofts.length} projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Plants`} ><img src="./images/flowers.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Plants`}  className="text-dark font-rubik font-size-16">Flowers & plants for
              interiors&nbsp;({projectplants && projectplants.length} Projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Accesory`} ><img src="./images/bathtub.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Accesory`}  className="text-dark font-rubik font-size-16"> Accessories&nbsp;({projectaccesories && projectaccesories.length} Projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Welding`} ><img src="./images/grill.jpeg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Welding`}  className="text-dark font-rubik font-size-16">Metal work&nbsp;({projectweldings && projectweldings.length} Projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Architecture`} ><img src="./images/architecture.jpeg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Architecture`}  className="text-dark font-rubik font-size-16">Structures & Architecture&nbsp;({projectarchs && projectarchs.length} Projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Fineart`} ><img src="./images/mosaic2.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Fineart`}  className="text-dark font-rubik font-size-16">Fine Art&nbsp;({projectarts && projectarts.length} Projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Landscape`} ><img src="./images/Landscape.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Landscape`}  className="text-dark font-rubik font-size-16">Landscape design&nbsp;({projectlandscapes && projectlandscapes.length} Projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Fabric`} ><img src="./images/fabric.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Fabric`}  className="text-dark font-rubik font-size-16">Fabrics&nbsp;({projectfabrics && projectfabrics.length} Projects)</Link>
          </div>
        </div>
      </div>

      <div className="page-list text-center">
        <Link to={`/Water`} ><img src="./images/Feng-shui-fish-acquarium.jpg" className="thumbnail image-resize2"/></Link>
        <div className="flex-div">
          <div className="page-info">
            <Link to={`/Water`}  className="text-dark font-rubik font-size-16">Aquariums & Fountains&nbsp;({projectwaters && projectwaters.length} Projects)</Link>
          </div>
        </div>
      </div>
      <h1 className='font-size-16 font-rale text-dark text-center'>
                      MoodBoard-0796320745
                    </h1>
      </div>
      </Fragment>
   

  )
}

export default Home