export const ADMIN_PROJECTARTS_REQUEST = 'ADMIN_PROJECTARTS_REQUEST'
export const ADMIN_PROJECTARTS_SUCCESS = 'ADMIN_PROJECTARTS_SUCCESS'
export const ADMIN_PROJECTARTS_FAIL = 'ADMIN_PROJECTARTS_FAIL'

export const ALL_PROJECTARTS_REQUEST = 'ALL_PROJECTARTS_REQUEST'
export const ALL_PROJECTARTS_SUCCESS = 'ALL_PROJECTARTS_SUCCESS'
export const ALL_PROJECTARTS_FAIL = 'ALL_PROJECTARTS_FAIL'

export const NEW_PROJECTART_REQUEST = 'NEW_PROJECTART_REQUEST'
export const NEW_PROJECTART_SUCCESS = 'NEW_PROJECTART_SUCCESS'
export const NEW_PROJECTART_RESET = 'NEW_PROJECTART_RESET'
export const NEW_PROJECTART_FAIL = 'NEW_PROJECTART_FAIL'

export const DELETE_PROJECTART_REQUEST = 'DELETE_PROJECTART_REQUEST'
export const DELETE_PROJECTART_SUCCESS = 'DELETE_PROJECTART_SUCCESS'
export const DELETE_PROJECTART_RESET = 'DELETE_PROJECTART_RESET'
export const DELETE_PROJECTART_FAIL = 'DELETE_PROJECTART_FAIL'

export const UPDATE_PROJECTART_REQUEST = 'UPDATE_PROJECTART_REQUEST'
export const UPDATE_PROJECTART_SUCCESS = 'UPDATE_PROJECTART_SUCCESS'
export const UPDATE_PROJECTART_RESET = 'UPDATE_PROJECTART_RESET'
export const UPDATE_PROJECTART_FAIL = 'UPDATE_PROJECTART_FAIL'

export const PROJECTART_DETAILS_REQUEST = 'PROJECTART_DETAILS_REQUEST'
export const PROJECTART_DETAILS_SUCCESS = 'PROJECTART_DETAILS_SUCCESS'
export const PROJECTART_DETAILS_FAIL = 'PROJECTART_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'