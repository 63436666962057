export const ADMIN_PROJECTWALLS_REQUEST = 'ADMIN_PROJECTWALLS_REQUEST'
export const ADMIN_PROJECTWALLS_SUCCESS = 'ADMIN_PROJECTWALLS_SUCCESS'
export const ADMIN_PROJECTWALLS_FAIL = 'ADMIN_PROJECTWALLS_FAIL'

export const ALL_PROJECTWALLS_REQUEST = 'ALL_PROJECTWALLS_REQUEST'
export const ALL_PROJECTWALLS_SUCCESS = 'ALL_PROJECTWALLS_SUCCESS'
export const ALL_PROJECTWALLS_FAIL = 'ALL_PROJECTWALLS_FAIL'

export const NEW_PROJECTWALL_REQUEST = 'NEW_PROJECTWALL_REQUEST'
export const NEW_PROJECTWALL_SUCCESS = 'NEW_PROJECTWALL_SUCCESS'
export const NEW_PROJECTWALL_RESET = 'NEW_PROJECTWALL_RESET'
export const NEW_PROJECTWALL_FAIL = 'NEW_PROJECTWALL_FAIL'

export const DELETE_PROJECTWALL_REQUEST = 'DELETE_PROJECTWALL_REQUEST'
export const DELETE_PROJECTWALL_SUCCESS = 'DELETE_PROJECTWALL_SUCCESS'
export const DELETE_PROJECTWALL_RESET = 'DELETE_PROJECTWALL_RESET'
export const DELETE_PROJECTWALL_FAIL = 'DELETE_PROJECTWALL_FAIL'

export const UPDATE_PROJECTWALL_REQUEST = 'UPDATE_PROJECTWALL_REQUEST'
export const UPDATE_PROJECTWALL_SUCCESS = 'UPDATE_PROJECTWALL_SUCCESS'
export const UPDATE_PROJECTWALL_RESET = 'UPDATE_PROJECTWALL_RESET'
export const UPDATE_PROJECTWALL_FAIL = 'UPDATE_PROJECTWALL_FAIL'

export const PROJECTWALL_DETAILS_REQUEST = 'PROJECTWALL_DETAILS_REQUEST'
export const PROJECTWALL_DETAILS_SUCCESS = 'PROJECTWALL_DETAILS_SUCCESS'
export const PROJECTWALL_DETAILS_FAIL = 'PROJECTWALL_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'