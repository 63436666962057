export const ADMIN_PROJECTFABRICS_REQUEST = 'ADMIN_PROJECTFABRICS_REQUEST'
export const ADMIN_PROJECTFABRICS_FAIL = 'ADMIN_PROJECTFABRICS_FAIL'
export const ADMIN_PROJECTFABRICS_SUCCESS = 'ADMIN_PROJECTFABRICS_SUCCESS'

export const ALL_PROJECTFABRICS_REQUEST = 'ALL_PROJECTFABRICS_REQUEST'
export const ALL_PROJECTFABRICS_SUCCESS = 'ALL_PROJECTFABRICS_SUCCESS'
export const ALL_PROJECTFABRICS_FAIL = 'ALL_PROJECTFABRICS_FAIL'

export const NEW_PROJECTFABRIC_REQUEST = 'NEW_PROJECTFABRIC_REQUEST'
export const NEW_PROJECTFABRIC_SUCCESS = 'NEW_PROJECTFABRIC_SUCCESS'
export const NEW_PROJECTFABRIC_RESET = 'NEW_PROJECTFABRIC_RESET'
export const NEW_PROJECTFABRIC_FAIL = 'NEW_PROJECTFABRIC_FAIL'

export const DELETE_PROJECTFABRIC_REQUEST = 'DELETE_PROJECTFABRIC_REQUEST'
export const DELETE_PROJECTFABRIC_SUCCESS = 'DELETE_PROJECTFABRIC_SUCCESS'
export const DELETE_PROJECTFABRIC_RESET = 'DELETE_PROJECTFABRIC_RESET'
export const DELETE_PROJECTFABRIC_FAIL = 'DELETE_PROJECTFABRIC_FAIL'

export const UPDATE_PROJECTFABRIC_REQUEST = 'UPDATE_PROJECTFABRIC_REQUEST'
export const UPDATE_PROJECTFABRIC_SUCCESS = 'UPDATE_PROJECTFABRIC_SUCCESS'
export const UPDATE_PROJECTFABRIC_RESET = 'UPDATE_PROJECTFABRIC_RESET'
export const UPDATE_PROJECTFABRIC_FAIL = 'UPDATE_PROJECTFABRIC_FAIL'

export const PROJECTFABRIC_DETAILS_REQUEST = 'PROJECTFABRIC_DETAILS_REQUEST'
export const PROJECTFABRIC_DETAILS_SUCCESS = 'PROJECTFABRIC_DETAILS_SUCCESS'
export const PROJECTFABRIC_DETAILS_FAIL = 'PROJECTFABRIC_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'