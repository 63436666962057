import axios from 'axios'

import { 
    ALL_PROJECTWELDINGS_REQUEST, 
    ALL_PROJECTWELDINGS_SUCCESS, 
    ALL_PROJECTWELDINGS_FAIL, 

    ADMIN_PROJECTWELDINGS_REQUEST, 
    ADMIN_PROJECTWELDINGS_SUCCESS, 
    ADMIN_PROJECTWELDINGS_FAIL,

    NEW_PROJECTWELDING_REQUEST, 
    NEW_PROJECTWELDING_SUCCESS, 
    NEW_PROJECTWELDING_FAIL, 

    DELETE_PROJECTWELDING_REQUEST, 
    DELETE_PROJECTWELDING_SUCCESS,
    DELETE_PROJECTWELDING_FAIL,

    UPDATE_PROJECTWELDING_REQUEST, 
    UPDATE_PROJECTWELDING_SUCCESS,
    UPDATE_PROJECTWELDING_FAIL, 

    PROJECTWELDING_DETAILS_REQUEST,
    PROJECTWELDING_DETAILS_SUCCESS,
    PROJECTWELDING_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectweldingConstants";

export const getProjectweldings = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTWELDINGS_REQUEST })

        let link = `/api/v1/projectweldings?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectweldings?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectweldings?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTWELDINGS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTWELDINGS_FAIL,
            payload: error.response.data.message
        })
    }
}



export const newProjectwelding = (projectweldingData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTWELDING_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectwelding/new`, projectweldingData, config)

        dispatch({
            type: NEW_PROJECTWELDING_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTWELDING_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectwelding (Admin)
export const deleteProjectwelding = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTWELDING_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectwelding/${id}`)

        dispatch({
            type: DELETE_PROJECTWELDING_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTWELDING_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectwelding (admin)
export const updateProjectwelding = (id, projectweldingData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTWELDING_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectwelding/${id}`, projectweldingData, config)

        dispatch({
            type: UPDATE_PROJECTWELDING_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTWELDING_FAIL,
            payload: error.response.data.message
        })
    }
}




export const getProjectweldingDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTWELDING_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectwelding/${id}`)

        dispatch({
            type: PROJECTWELDING_DETAILS_SUCCESS,
            payload: data.projectwelding
        })

    } catch (error) {
        dispatch({
            type: PROJECTWELDING_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectweldings = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTWELDINGS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectweldings`)

        dispatch({
            type: ADMIN_PROJECTWELDINGS_SUCCESS,
            payload: data.projectweldings
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTWELDINGS_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}