export const ADMIN_PROJECTLANDSCAPES_REQUEST = 'ADMIN_PROJECTLANDSCAPES_REQUEST'
export const ADMIN_PROJECTLANDSCAPES_SUCCESS = 'ADMIN_PROJECTLANDSCAPES_SUCCESS'
export const ADMIN_PROJECTLANDSCAPES_FAIL = 'ADMIN_PROJECTLANDSCAPES_FAIL'

export const ALL_PROJECTLANDSCAPES_REQUEST = 'ALL_PROJECTLANDSCAPES_REQUEST'
export const ALL_PROJECTLANDSCAPES_SUCCESS = 'ALL_PROJECTLANDSCAPES_SUCCESS'
export const ALL_PROJECTLANDSCAPES_FAIL = 'ALL_PROJECTLANDSCAPES_FAIL'

export const NEW_PROJECTLANDSCAPE_REQUEST = 'NEW_PROJECTLANDSCAPE_REQUEST'
export const NEW_PROJECTLANDSCAPE_SUCCESS = 'NEW_PROJECTLANDSCAPE_SUCCESS'
export const NEW_PROJECTLANDSCAPE_RESET = 'NEW_PROJECTLANDSCAPE_RESET'
export const NEW_PROJECTLANDSCAPE_FAIL = 'NEW_PROJECTLANDSCAPE_FAIL'

export const DELETE_PROJECTLANDSCAPE_REQUEST = 'DELETE_PROJECTLANDSCAPE_REQUEST'
export const DELETE_PROJECTLANDSCAPE_SUCCESS = 'DELETE_PROJECTLANDSCAPE_SUCCESS'
export const DELETE_PROJECTLANDSCAPE_RESET = 'DELETE_PROJECTLANDSCAPE_RESET'
export const DELETE_PROJECTLANDSCAPE_FAIL = 'DELETE_PROJECTLANDSCAPE_FAIL'

export const UPDATE_PROJECTLANDSCAPE_REQUEST = 'UPDATE_PROJECTLANDSCAPE_REQUEST'
export const UPDATE_PROJECTLANDSCAPE_SUCCESS = 'UPDATE_PROJECTLANDSCAPE_SUCCESS'
export const UPDATE_PROJECTLANDSCAPE_RESET = 'UPDATE_PROJECTLANDSCAPE_RESET'
export const UPDATE_PROJECTLANDSCAPE_FAIL = 'UPDATE_PROJECTLANDSCAPE_FAIL'

export const PROJECTLANDSCAPE_DETAILS_REQUEST = 'PROJECTLANDSCAPE_DETAILS_REQUEST'
export const PROJECTLANDSCAPE_DETAILS_SUCCESS = 'PROJECTLANDSCAPE_DETAILS_SUCCESS'
export const PROJECTLANDSCAPE_DETAILS_FAIL = 'PROJECTLANDSCAPE_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'