import axios from 'axios'

import { 
    ALL_PROJECTACCESORIES_REQUEST, 
    ALL_PROJECTACCESORIES_SUCCESS, 
    ALL_PROJECTACCESORIES_FAIL,

    ADMIN_PROJECTACCESORIES_REQUEST, 
    ADMIN_PROJECTACCESORIES_SUCCESS, 
    ADMIN_PROJECTACCESORIES_FAIL,

    NEW_PROJECTACCESORY_REQUEST, 
    NEW_PROJECTACCESORY_SUCCESS, 
    NEW_PROJECTACCESORY_FAIL, 

    DELETE_PROJECTACCESORY_REQUEST, 
    DELETE_PROJECTACCESORY_SUCCESS,
    DELETE_PROJECTACCESORY_FAIL,

    UPDATE_PROJECTACCESORY_REQUEST, 
    UPDATE_PROJECTACCESORY_SUCCESS,
    UPDATE_PROJECTACCESORY_FAIL, 
    
    PROJECTACCESORY_DETAILS_REQUEST,
    PROJECTACCESORY_DETAILS_SUCCESS,
    PROJECTACCESORY_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectaccesoryConstants";

export const getProjectaccesories = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTACCESORIES_REQUEST})

        let link = `/api/v1/projectaccesories?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectaccesories?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectaccesories?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTACCESORIES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTACCESORIES_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectaccesory = (projectaccesoryData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTACCESORY_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectaccesory/new`, projectaccesoryData, config)

        dispatch({
            type: NEW_PROJECTACCESORY_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTACCESORY_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectaccesory (Admin)
export const deleteProjectaccesory = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTACCESORY_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectaccesory/${id}`)

        dispatch({
            type: DELETE_PROJECTACCESORY_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTACCESORY_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectaccesory (admin)
export const updateProjectaccesory = (id, projectaccesoryData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTACCESORY_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectaccesory/${id}`, projectaccesoryData, config)

        dispatch({
            type: UPDATE_PROJECTACCESORY_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTACCESORY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getProjectaccesoryDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTACCESORY_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectaccesory/${id}`)

        dispatch({
            type: PROJECTACCESORY_DETAILS_SUCCESS,
            payload: data.projectaccesory
        })

    } catch (error) {
        dispatch({
            type: PROJECTACCESORY_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectaccesories = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTACCESORIES_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectaccesories`)

        dispatch({
            type: ADMIN_PROJECTACCESORIES_SUCCESS,
            payload: data.projectaccesories
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTACCESORIES_FAIL,
            payload: error.response.data.message
        })
    }
}



// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}