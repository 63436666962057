export const ADMIN_PROJECTLIGHTINGS_REQUEST = 'ADMIN_PROJECTLIGHTINGS_REQUEST'
export const ADMIN_PROJECTLIGHTINGS_SUCCESS = 'ADMIN_PROJECTLIGHTINGS_SUCCESS'
export const ADMIN_PROJECTLIGHTINGS_FAIL = 'ADMIN_PROJECTLIGHTINGS_FAIL'

export const ALL_PROJECTLIGHTINGS_REQUEST = 'ALL_PROJECTLIGHTINGS_REQUEST'
export const ALL_PROJECTLIGHTINGS_SUCCESS = 'ALL_PROJECTLIGHTINGS_SUCCESS'
export const ALL_PROJECTLIGHTINGS_FAIL = 'ALL_PROJECTLIGHTINGS_FAIL'

export const NEW_PROJECTLIGHTING_REQUEST = 'NEW_PROJECTLIGHTING_REQUEST'
export const NEW_PROJECTLIGHTING_SUCCESS = 'NEW_PROJECTLIGHTING_SUCCESS'
export const NEW_PROJECTLIGHTING_RESET = 'NEW_PROJECTLIGHTING_RESET'
export const NEW_PROJECTLIGHTING_FAIL = 'NEW_PROJECTLIGHTING_FAIL'

export const DELETE_PROJECTLIGHTING_REQUEST = 'DELETE_PROJECTLIGHTING_REQUEST'
export const DELETE_PROJECTLIGHTING_SUCCESS = 'DELETE_PROJECTLIGHTING_SUCCESS'
export const DELETE_PROJECTLIGHTING_RESET = 'DELETE_PROJECTLIGHTING_RESET'
export const DELETE_PROJECTLIGHTING_FAIL = 'DELETE_PROJECTLIGHTING_FAIL'

export const UPDATE_PROJECTLIGHTING_REQUEST = 'UPDATE_PROJECTLIGHTING_REQUEST'
export const UPDATE_PROJECTLIGHTING_SUCCESS = 'UPDATE_PROJECTLIGHTING_SUCCESS'
export const UPDATE_PROJECTLIGHTING_RESET = 'UPDATE_PROJECTLIGHTING_RESET'
export const UPDATE_PROJECTLIGHTING_FAIL = 'UPDATE_PROJECTLIGHTING_FAIL'

export const PROJECTLIGHTING_DETAILS_REQUEST = 'PROJECTLIGHTING_DETAILS_REQUEST'
export const PROJECTLIGHTING_DETAILS_SUCCESS = 'PROJECTLIGHTING_DETAILS_SUCCESS'
export const PROJECTLIGHTING_DETAILS_FAIL = 'PROJECTLIGHTING_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'