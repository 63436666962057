import { 
    ALL_PROJECTARTS_REQUEST,
    ALL_PROJECTARTS_SUCCESS, 
    ALL_PROJECTARTS_FAIL, 

    ADMIN_PROJECTARTS_REQUEST, 
    ADMIN_PROJECTARTS_SUCCESS, 
    ADMIN_PROJECTARTS_FAIL, 

    NEW_PROJECTART_REQUEST, 
    NEW_PROJECTART_SUCCESS, 
    NEW_PROJECTART_RESET,
    NEW_PROJECTART_FAIL, 

    DELETE_PROJECTART_REQUEST, 
    DELETE_PROJECTART_SUCCESS, 
    DELETE_PROJECTART_RESET,
    DELETE_PROJECTART_FAIL, 

    UPDATE_PROJECTART_REQUEST, 
    UPDATE_PROJECTART_SUCCESS, 
    UPDATE_PROJECTART_RESET,
    UPDATE_PROJECTART_FAIL, 

    PROJECTART_DETAILS_REQUEST,
    PROJECTART_DETAILS_SUCCESS,
    PROJECTART_DETAILS_FAIL,
    CLEAR_ERRORS
 } from "../constants/projectartConstants";

 export const projectartsReducer = (state = { projectarts: [] }, action) => {
    switch(action.type) {
        case ALL_PROJECTARTS_REQUEST:
        case ADMIN_PROJECTARTS_REQUEST:    
            return {
                loading: true,
                projectarts: [] 
            }
        case ALL_PROJECTARTS_SUCCESS:
            return {
                loading: false,
                projectarts: action.payload.projectarts,
                projectartsCount: action.payload.projectartsCount,
                resPerPage: action.payload.resPerPage
               
            }

        case ADMIN_PROJECTARTS_SUCCESS:
            return {
                    loading: false,
            projectarts: action.payload
                }    
        case ALL_PROJECTARTS_FAIL:
            case ADMIN_PROJECTARTS_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const newProjectartReducer = (state = { projectart: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTART_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTART_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectart: action.payload.projectart
            } 
               
        case NEW_PROJECTART_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTART_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectartReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTART_REQUEST:
        case UPDATE_PROJECTART_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTART_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTART_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTART_FAIL:
        case UPDATE_PROJECTART_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTART_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTART_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectartDetailsReducer = (state = {projectart: {} },  action ) => {
    switch (action.type) {

        case  PROJECTART_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTART_DETAILS_SUCCESS:
            return {
                loading: false,
                projectart: action.payload
            }
        case  PROJECTART_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}