export const ADMIN_PROJECTARCHS_REQUEST = 'ADMIN_PROJECTARCHS_REQUEST'
export const ADMIN_PROJECTARCHS_SUCCESS = 'ADMIN_PROJECTARCHS_SUCCESS'
export const ADMIN_PROJECTARCHS_FAIL = 'ADMIN_PROJECTARCHS_FAIL'

export const ALL_PROJECTARCHS_REQUEST = 'ALL_PROJECTARCHS_REQUEST'
export const ALL_PROJECTARCHS_SUCCESS = 'ALL_PROJECTARCHS_SUCCESS'
export const ALL_PROJECTARCHS_FAIL = 'ALL_PROJECTARCHS_FAIL'

export const NEW_PROJECTARCH_REQUEST = 'NEW_PROJECTARCH_REQUEST'
export const NEW_PROJECTARCH_SUCCESS = 'NEW_PROJECTARCH_SUCCESS'
export const NEW_PROJECTARCH_RESET = 'NEW_PROJECTARCH_RESET'
export const NEW_PROJECTARCH_FAIL = 'NEW_PROJECTARCH_FAIL'

export const DELETE_PROJECTARCH_REQUEST = 'DELETE_PROJECTARCH_REQUEST'
export const DELETE_PROJECTARCH_SUCCESS = 'DELETE_PROJECTARCH_SUCCESS'
export const DELETE_PROJECTARCH_RESET = 'DELETE_PROJECTARCH_RESET'
export const DELETE_PROJECTARCH_FAIL = 'DELETE_PROJECTARCH_FAIL'

export const UPDATE_PROJECTARCH_REQUEST = 'UPDATE_PROJECTARCH_REQUEST'
export const UPDATE_PROJECTARCH_SUCCESS = 'UPDATE_PROJECTARCH_SUCCESS'
export const UPDATE_PROJECTARCH_RESET = 'UPDATE_PROJECTARCH_RESET'
export const UPDATE_PROJECTARCH_FAIL = 'UPDATE_PROJECTARCH_FAIL'

export const PROJECTARCH_DETAILS_REQUEST = 'PROJECTARCH_DETAILS_REQUEST'
export const PROJECTARCH_DETAILS_SUCCESS = 'PROJECTARCH_DETAILS_SUCCESS'
export const PROJECTARCH_DETAILS_FAIL = 'PROJECTARCH_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'