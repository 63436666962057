import { 
    ALL_PROJECTSOFTS_REQUEST,
    ALL_PROJECTSOFTS_SUCCESS, 
    ALL_PROJECTSOFTS_FAIL,

    ADMIN_PROJECTSOFTS_REQUEST, 
    ADMIN_PROJECTSOFTS_SUCCESS, 
    ADMIN_PROJECTSOFTS_FAIL, 

    NEW_PROJECTSOFT_REQUEST, 
    NEW_PROJECTSOFT_SUCCESS, 
    NEW_PROJECTSOFT_RESET,
    NEW_PROJECTSOFT_FAIL, 

    DELETE_PROJECTSOFT_REQUEST, 
    DELETE_PROJECTSOFT_SUCCESS, 
    DELETE_PROJECTSOFT_RESET,
    DELETE_PROJECTSOFT_FAIL, 

    UPDATE_PROJECTSOFT_REQUEST, 
    UPDATE_PROJECTSOFT_SUCCESS, 
    UPDATE_PROJECTSOFT_RESET,
    UPDATE_PROJECTSOFT_FAIL, 
    
    PROJECTSOFT_DETAILS_REQUEST,
    PROJECTSOFT_DETAILS_SUCCESS,
    PROJECTSOFT_DETAILS_FAIL,
    CLEAR_ERRORS 
 } from "../constants/projectsoftConstants";

 export const projectsoftsReducer = (state = { projectsofts: [] }, action )=> {
    switch(action.type) {
        case ALL_PROJECTSOFTS_REQUEST:
        case ADMIN_PROJECTSOFTS_REQUEST:
            return {
                loading: true,
                projectsofts: [] 
            }
        case ALL_PROJECTSOFTS_SUCCESS:
            return {
                loading: false,
                projectsofts: action.payload.projectsofts,
                projectsoftsCount: action.payload.projectsoftsCount,
                resPerPage: action.payload.resPerPage
               
            }

            case ADMIN_PROJECTSOFTS_SUCCESS:
                return {
                    loading: false,
                    projectsofts: action.payload
                    }
        case ALL_PROJECTSOFTS_FAIL:
            case ADMIN_PROJECTSOFTS_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}


export const newProjectsoftReducer = (state = { projectsoft: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTSOFT_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTSOFT_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectsoft: action.payload.projectsoft
            } 
               
        case NEW_PROJECTSOFT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTSOFT_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectsoftReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTSOFT_REQUEST:
        case UPDATE_PROJECTSOFT_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTSOFT_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTSOFT_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTSOFT_FAIL:
        case UPDATE_PROJECTSOFT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTSOFT_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTSOFT_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectsoftDetailsReducer = (state = {projectsoft: {} }, action ) => {
    switch (action.type) {

        case  PROJECTSOFT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTSOFT_DETAILS_SUCCESS:
            return {
                loading: false,
                projectsoft: action.payload
            }
        case  PROJECTSOFT_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}