import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import MetaData from '../layout/MetaData';
import CheckoutSteps from './CheckoutSteps';

import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart } from "../../actions/cartActions";
import { createOrder, clearErrors } from '../../actions/orderActions';

//import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'

import axios from 'axios'



{/*const options = {
    style: {
        base: {
            fontSize: '16px'
        },
        invalid: {
            color: '#9e2146'
        }
    }
} */}

const Payment = () => {

    const alert = useAlert();
    //const stripe = useStripe();
    //const elements = useElements();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth)
    const { cartItems, shippingInfo } = useSelector((state) => state.cart);
    const { error } = useSelector((state) => state.newOrder)


    useEffect(() => {

       if (error) {
            alert.error(error)
            dispatch(clearErrors())
       } 

    }, [dispatch, alert, error])

    const order = {
        orderItems: cartItems,
        shippingInfo
    } 

    const orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'));
    if (orderInfo) {
        order.itemsPrice = orderInfo.itemsPrice
       // order.shippingPrice = orderInfo.shippingPrice
        order.taxPrice = orderInfo.taxPrice
        order.totalPrice = orderInfo.totalPrice
    } 

 {/*   const paymentData = {
        amount: Math.round(orderInfo.totalPrice * 100)
    } */}

    const submitHandler = async (e) => {
        e.preventDefault();

        document.querySelector('#pay_btn').disabled = true;

        dispatch(createOrder(order));
        dispatch(clearCart(cartItems));

        navigate('/success')

      
    }  




    return (
        <Fragment>
            <MetaData title={'Payment'} />

            <CheckoutSteps shipping confirmOrder payment />



           <div className="container">
                <div className="col-10 col-lg-5">
              <h6>All transactions are done through the following accounts:</h6>
              <ul>
                <li><b>MPESA No.:&nbsp; 0796320745</b></li>
                <li><b>ABSA BANK ACC No:&nbsp;2046167611</b></li>
              </ul>

              <button id="pay_btn" className="btn btn-primary btn-block" onClick={submitHandler}>Click to finish</button>
                </div>
            </div> 

        </Fragment>
    )
}

export default Payment