export const ADMIN_PROJECTCEILINGS_FAIL = 'ADMIN_PROJECTCEILINGS_FAIL'
export const ADMIN_PROJECTCEILINGS_REQUEST = 'ADMIN_PROJECTCEILINGS_REQUEST'
export const ADMIN_PROJECTCEILINGS_SUCCESS = 'ADMIN_PROJECTCEILINGS_SUCCESS'

export const ALL_PROJECTCEILINGS_REQUEST = 'ALL_PROJECTCEILINGS_REQUEST'
export const ALL_PROJECTCEILINGS_SUCCESS = 'ALL_PROJECTCEILINGS_SUCCESS'
export const ALL_PROJECTCEILINGS_FAIL = 'ALL_PROJECTCEILINGS_FAIL'

export const NEW_PROJECTCEILING_REQUEST = 'NEW_PROJECTCEILING_REQUEST'
export const NEW_PROJECTCEILING_SUCCESS = 'NEW_PROJECTCEILING_SUCCESS'
export const NEW_PROJECTCEILING_RESET = 'NEW_PROJECTCEILING_RESET'
export const NEW_PROJECTCEILING_FAIL = 'NEW_PROJECTCEILING_FAIL'

export const DELETE_PROJECTCEILING_REQUEST = 'DELETE_PROJECTCEILING_REQUEST'
export const DELETE_PROJECTCEILING_SUCCESS = 'DELETE_PROJECTCEILING_SUCCESS'
export const DELETE_PROJECTCEILING_RESET = 'DELETE_PROJECTCEILING_RESET'
export const DELETE_PROJECTCEILING_FAIL = 'DELETE_PROJECTCEILING_FAIL'

export const UPDATE_PROJECTCEILING_REQUEST = 'UPDATE_PROJECTCEILING_REQUEST'
export const UPDATE_PROJECTCEILING_SUCCESS = 'UPDATE_PROJECTCEILING_SUCCESS'
export const UPDATE_PROJECTCEILING_RESET = 'UPDATE_PROJECTCEILING_RESET'
export const UPDATE_PROJECTCEILING_FAIL = 'UPDATE_PROJECTCEILING_FAIL'


export const PROJECTCEILING_DETAILS_REQUEST = 'PROJECTCEILING_DETAILS_REQUEST'
export const PROJECTCEILING_DETAILS_SUCCESS = 'PROJECTCEILING_DETAILS_SUCCESS'
export const PROJECTCEILING_DETAILS_FAIL = 'PROJECTCEILING_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'