import { 
    ALL_PROJECTPLANTS_REQUEST,
    ALL_PROJECTPLANTS_SUCCESS, 
    ALL_PROJECTPLANTS_FAIL,
    
    ADMIN_PROJECTPLANTS_REQUEST, 
    ADMIN_PROJECTPLANTS_SUCCESS, 
    ADMIN_PROJECTPLANTS_FAIL, 

    NEW_PROJECTPLANT_REQUEST, 
    NEW_PROJECTPLANT_SUCCESS, 
    NEW_PROJECTPLANT_RESET,
    NEW_PROJECTPLANT_FAIL, 

    DELETE_PROJECTPLANT_REQUEST, 
    DELETE_PROJECTPLANT_SUCCESS, 
    DELETE_PROJECTPLANT_RESET,
    DELETE_PROJECTPLANT_FAIL, 

    UPDATE_PROJECTPLANT_REQUEST, 
    UPDATE_PROJECTPLANT_SUCCESS, 
    UPDATE_PROJECTPLANT_RESET,
    UPDATE_PROJECTPLANT_FAIL, 

    PROJECTPLANT_DETAILS_REQUEST,
    PROJECTPLANT_DETAILS_SUCCESS,
    PROJECTPLANT_DETAILS_FAIL,
    CLEAR_ERRORS 
 } from "../constants/projectplantConstants";

 export const projectplantsReducer = (state = { projectplants: [] }, action )=> {
    switch(action.type) {
        case ALL_PROJECTPLANTS_REQUEST:
        case ADMIN_PROJECTPLANTS_REQUEST:
            return {
                loading: true,
                projectplants: [] 
            }
        case ALL_PROJECTPLANTS_SUCCESS:
            return {
                loading: false,
                projectplants: action.payload.projectplants,
                projectplantsCount: action.payload.projectplantsCount,
                resPerPage: action.payload.resPerPage
               
            }

            case ADMIN_PROJECTPLANTS_SUCCESS:
                return {
                    loading: false,
                    projectplants: action.payload
                    }
        case ALL_PROJECTPLANTS_FAIL:
            case ADMIN_PROJECTPLANTS_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}


export const newProjectplantReducer = (state = { projectplant: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTPLANT_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTPLANT_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectplant: action.payload.projectplant
            } 
               
        case NEW_PROJECTPLANT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTPLANT_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectplantReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTPLANT_REQUEST:
        case UPDATE_PROJECTPLANT_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTPLANT_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTPLANT_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTPLANT_FAIL:
        case UPDATE_PROJECTPLANT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTPLANT_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTPLANT_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}


export const projectplantDetailsReducer = (state = {projectplant: {} }, action ) => {
    switch (action.type) {

        case  PROJECTPLANT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTPLANT_DETAILS_SUCCESS:
            return {
                loading: false,
                projectplant: action.payload
            }
        case  PROJECTPLANT_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}