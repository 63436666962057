import React, { Fragment, useEffect, useState } from "react";

//import Pagination from 'react-js-pagination'

import MetaData from "./layout/MetaData";
import Product from './product/Product'
import Loader from './layout/Loader'

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getProducts } from "../actions/productActions";

import { useParams } from "react-router-dom";


const Shop = () => {

  const [currentPage, setCurrentPage] = useState(1)
  const [category, setCategory] = useState('');

  const categories = [
    'Furniture',
    'Lighting & Accoustics',
    'Floor finishes',
    'Ceiling design', 
    'Wall finishes',
    'Kitchen design',
    'Bathroom accessories',
    'Flowers & Plants for interiors',
    'Soft furnishing',
    'House painting',
    'Metal work',
    'Landscape design',
    'Structures & Architecture',
    'Fine Art',
    'Accesories',
    'Fabrics',
    'Home'
  ]

  const alert = useAlert();
  const dispatch = useDispatch();

  const params = useParams();

  const { loading, products, error, productsCount } = useSelector(
    (state) => state.products
  );

  const keyword = params.keyword;

  useEffect(() => {
    if(error) {
      return alert.error(error);
      
    }
    dispatch(getProducts(keyword, currentPage, category));

  }, [dispatch, alert, error, keyword, currentPage, category]);

  function setCurrentPageNo(pageNumber) {
    setCurrentPage(pageNumber);
  }

  return (
    <Fragment>
      {loading ? <Loader /> : (
        <Fragment>
                <MetaData title={"Hardware"} />
      <div className="container container-fluid">
        <h6 id="products_heading">
          Can't find a product? <strong>Call/Whatsapp 0796320745</strong> for
          immediate assistance
        </h6>
        
          <div className="page-container">
            {products &&
              products.map((product) => (
                <Product key={product._id} product={product} />
              ))}
          </div>
       
      </div>

 {/*}   {resPerPage <
       productsCount && (
              <div className="d-flex justify-content-center mt-5">
              <Pagination
              activePage={currentPage}
              itemsCountPerPage={resPerPage}
              totalItemsCount={productsCount}
              onChange={setCurrentPageNo}
              nextPageText={'Next'}
              prevPageText={'Prev'}
              firstPageText={'First'}
              lastPageText={'Last'}
              itemClass="page-item"
              linkClass="page-link"
              >
      
              </Pagination>
            </div> 
      )}  */}
        </Fragment>
      )}

    </Fragment>
  );
};

export default Shop;
