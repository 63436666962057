import axios from 'axios'

import { 
    ALL_PROJECTARCHS_REQUEST, 
    ALL_PROJECTARCHS_SUCCESS, 
    ALL_PROJECTARCHS_FAIL, 

    ADMIN_PROJECTARCHS_REQUEST, 
    ADMIN_PROJECTARCHS_SUCCESS, 
    ADMIN_PROJECTARCHS_FAIL,

    NEW_PROJECTARCH_REQUEST, 
    NEW_PROJECTARCH_SUCCESS, 
    NEW_PROJECTARCH_FAIL, 

    DELETE_PROJECTARCH_REQUEST, 
    DELETE_PROJECTARCH_SUCCESS,
    DELETE_PROJECTARCH_FAIL,

    UPDATE_PROJECTARCH_REQUEST, 
    UPDATE_PROJECTARCH_SUCCESS,
    UPDATE_PROJECTARCH_FAIL, 

    PROJECTARCH_DETAILS_REQUEST,
    PROJECTARCH_DETAILS_SUCCESS,
    PROJECTARCH_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectarchConstants";

export const getProjectarchs = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTARCHS_REQUEST })

        let link = `/api/v1/projectarchs?page=${currentPage}`

        // const { data } = await axios.get(`/api/v1/projectarchs?page=${currentPage}`)
 
        if(category) {
          link = `/api/v1/projectarchs?page=${currentPage}&category=${category}`
        }
 
        const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTARCHS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTARCHS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectarch = (projectarchData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTARCH_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectarch/new`, projectarchData, config)

        dispatch({
            type: NEW_PROJECTARCH_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTARCH_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectarch (Admin)
export const deleteProjectarch = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTARCH_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectarch/${id}`)

        dispatch({
            type: DELETE_PROJECTARCH_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTARCH_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectarch (admin)
export const updateProjectarch = (id, projectarchData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTARCH_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectarch/${id}`, projectarchData, config)

        dispatch({
            type: UPDATE_PROJECTARCH_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTARCH_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getProjectarchDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTARCH_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectarch/${id}`)

        dispatch({
            type: PROJECTARCH_DETAILS_SUCCESS,
            payload: data.projectarch
        })

    } catch (error) {
        dispatch({
            type: PROJECTARCH_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectarchs = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTARCHS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectarchs`)

        dispatch({
            type: ADMIN_PROJECTARCHS_SUCCESS,
            payload: data.projectarchs
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTARCHS_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}