import axios from 'axios'

import { 
    ALL_PROJECTFLOORS_REQUEST, 
    ALL_PROJECTFLOORS_SUCCESS, 
    ALL_PROJECTFLOORS_FAIL, 

    ADMIN_PROJECTFLOORS_REQUEST, 
    ADMIN_PROJECTFLOORS_SUCCESS, 
    ADMIN_PROJECTFLOORS_FAIL,

    NEW_PROJECTFLOOR_REQUEST, 
    NEW_PROJECTFLOOR_SUCCESS, 
    NEW_PROJECTFLOOR_FAIL, 

    DELETE_PROJECTFLOOR_REQUEST, 
    DELETE_PROJECTFLOOR_SUCCESS,
    DELETE_PROJECTFLOOR_FAIL,

    UPDATE_PROJECTFLOOR_REQUEST, 
    UPDATE_PROJECTFLOOR_SUCCESS,
    UPDATE_PROJECTFLOOR_FAIL, 

    PROJECTFLOOR_DETAILS_REQUEST,
    PROJECTFLOOR_DETAILS_SUCCESS,
    PROJECTFLOOR_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectfloorConstants";

export const getProjectfloors = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTFLOORS_REQUEST })

        let link = `/api/v1/projectfloors?page=${currentPage}`

        // const { data } = await axios.get(`/api/v1/projectfloors?page=${currentPage}`)
 
        if(category) {
          link = `/api/v1/projectfloors?page=${currentPage}&category=${category}`
        }
 
        const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTFLOORS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTFLOORS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectfloor = (projectfloorData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTFLOOR_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectfloor/new`, projectfloorData, config)

        dispatch({
            type: NEW_PROJECTFLOOR_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTFLOOR_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectfloor (Admin)
export const deleteProjectfloor = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTFLOOR_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectfloor/${id}`)

        dispatch({
            type: DELETE_PROJECTFLOOR_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTFLOOR_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectfloor (admin)
export const updateProjectfloor = (id, projectfloorData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTFLOOR_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectfloor/${id}`, projectfloorData, config)

        dispatch({
            type: UPDATE_PROJECTFLOOR_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTFLOOR_FAIL,
            payload: error.response.data.message
        })
    }
}


export const getProjectfloorDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTFLOOR_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectfloor/${id}`)

        dispatch({
            type: PROJECTFLOOR_DETAILS_SUCCESS,
            payload: data.projectfloor
        })

    } catch (error) {
        dispatch({
            type: PROJECTFLOOR_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getAdminProjectfloors = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTFLOORS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectfloors`)

        dispatch({
            type: ADMIN_PROJECTFLOORS_SUCCESS,
            payload: data.projectfloors
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTFLOORS_FAIL,
            payload: error.response.data.message
        })
    }
} 
// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}