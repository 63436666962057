import axios from 'axios'

import { 
    ALL_PROJECTLIGHTINGS_REQUEST, 
    ALL_PROJECTLIGHTINGS_SUCCESS, 
    ALL_PROJECTLIGHTINGS_FAIL, 

    ADMIN_PROJECTLIGHTINGS_REQUEST, 
    ADMIN_PROJECTLIGHTINGS_SUCCESS, 
    ADMIN_PROJECTLIGHTINGS_FAIL,

    NEW_PROJECTLIGHTING_REQUEST, 
    NEW_PROJECTLIGHTING_SUCCESS, 
    NEW_PROJECTLIGHTING_FAIL, 

    DELETE_PROJECTLIGHTING_REQUEST, 
    DELETE_PROJECTLIGHTING_SUCCESS,
    DELETE_PROJECTLIGHTING_FAIL,

    UPDATE_PROJECTLIGHTING_REQUEST, 
    UPDATE_PROJECTLIGHTING_SUCCESS,
    UPDATE_PROJECTLIGHTING_FAIL, 

    PROJECTLIGHTING_DETAILS_REQUEST,
    PROJECTLIGHTING_DETAILS_SUCCESS,
    PROJECTLIGHTING_DETAILS_FAIL,
    CLEAR_ERRORS 
} from "../constants/projectlightingConstants";

export const getProjectlightings = (currentPage = 1, category) => async (dispatch) => {
    try{

        dispatch({ type: ALL_PROJECTLIGHTINGS_REQUEST })

        let link = `/api/v1/projectlightings?page=${currentPage}`

       // const { data } = await axios.get(`/api/v1/projectlightings?page=${currentPage}`)

       if(category) {
         link = `/api/v1/projectlightings?page=${currentPage}&category=${category}`
       }

       const { data } = await axios.get(link)

        dispatch({
            type: ALL_PROJECTLIGHTINGS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROJECTLIGHTINGS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const newProjectlighting = (projectlightingData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PROJECTLIGHTING_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post(`/api/v1/admin/projectlighting/new`, projectlightingData, config)

        dispatch({
            type: NEW_PROJECTLIGHTING_SUCCESS,
            payload: data
        })

    } catch (error) {
        console.log(error.response);
        dispatch({
            type: NEW_PROJECTLIGHTING_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete projectlighting (Admin)
export const deleteProjectlighting = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PROJECTLIGHTING_REQUEST })



        const { data } = await axios.delete(`/api/v1/admin/projectlighting/${id}`)

        dispatch({
            type: DELETE_PROJECTLIGHTING_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_PROJECTLIGHTING_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update projectlighting (admin)
export const updateProjectlighting = (id, projectlightingData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROJECTLIGHTING_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/projectlighting/${id}`, projectlightingData, config)

        dispatch({
            type: UPDATE_PROJECTLIGHTING_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROJECTLIGHTING_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getProjectlightingDetails = (id) => async (dispatch) => {
    try{

        dispatch({ type: PROJECTLIGHTING_DETAILS_REQUEST})

        const { data } = await axios.get(`/api/v1/projectlighting/${id}`)

        dispatch({
            type: PROJECTLIGHTING_DETAILS_SUCCESS,
            payload: data.projectlighting
        })

    } catch (error) {
        dispatch({
            type: PROJECTLIGHTING_DETAILS_FAIL,
            payload: error.response.data.message
        })
    }
}


export const getAdminProjectlightings = () => async (dispatch) => {
    try{

        dispatch({ type: ADMIN_PROJECTLIGHTINGS_REQUEST})

        const { data } = await axios.get(`/api/v1/admin/projectlightings`)

        dispatch({
            type: ADMIN_PROJECTLIGHTINGS_SUCCESS,
            payload: data.projectlightings
        })

    } catch (error) {
        dispatch({
            type: ADMIN_PROJECTLIGHTINGS_FAIL,
            payload: error.response.data.message
        })
    }
} 

// Clear errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}