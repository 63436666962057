import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

import MetaData from '../layout/MetaData'
import CheckoutSteps from './CheckoutSteps'

import { useSelector } from 'react-redux'

const ConfirmOrder = () => {

    const { cartItems, shippingInfo } = useSelector((state) => state.cart)
    const { user } = useSelector(state => state.auth)

    const navigate = useNavigate();

    // Calculate Order Prices
    const itemsPrice = cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)

    const taxPrice = Number((0.18 * itemsPrice).toFixed(2))
    const totalPrice = (itemsPrice + taxPrice).toFixed(2)

    const proceedToPayment = () => {
        const data = {  
            itemsPrice: itemsPrice.toFixed(2),
            //shippingPrice,
            taxPrice,
            totalPrice
        }

        sessionStorage.setItem('orderInfo', JSON.stringify(data))
        navigate('/payment')
    }


  return (
    <Fragment>

    <MetaData title={'Confirm Order'} />

    <CheckoutSteps shipping confirmOrder />

    <div className="row d-flex justify-content-between">
        <div className="col-12 col-lg-8 mt-1 order-confirm">

            <h4 className="mb-3">Shipping Info</h4>
            <p><b>Name:</b> {user && user.name}</p>
            <p><b>Phone:</b> {shippingInfo.phoneNo}</p>
            <p className="mb-4"><b>Address:</b> {` ${shippingInfo.city}, ${shippingInfo.county}, ${shippingInfo.email}`}</p>

            <hr />
            <h4 className="mt-4">Your Cart Items:</h4>

            {cartItems.map(item => (
                <Fragment>
                    <hr />
                    <div className="cart-item my-1" key={item.product}>
                        <div className="row">
                            <div className="col-12 col-lg-2">
                                <img src={item.image} alt="Laptop" height="45" width="65" />
                            </div>

                            <div className="col-5 col-lg-6">
                                <Link to={`/product/${item.product}`}>{item.name}</Link>
                            </div>


                            <div className="col-12 col-lg-4 mt-1 mt-lg-0">
                                <p>{item.quantity} x Ksh {item.price} = <b>Ksh  {(item.quantity * item.price).toFixed(2)}</b></p>
                            </div>

                        </div>
                    </div>
                    <hr />
                </Fragment>
            ))}



        </div>

        <div className="col-12 col-lg-3 my-4">
            <div id="order_summary">
                <h4>Order Summary</h4>
                <hr />
                <p>Subtotal:  <span className="order-summary-values">Ksh {itemsPrice}</span></p>
                <p>Shipping: <span className="order-summary-values">Varies</span></p>
                <p>Tax:  <span className="order-summary-values">Ksh {taxPrice}</span></p>

                <hr />

                <p>Total: <span className="order-summary-values">Ksh {totalPrice}</span></p>

                <hr />
                <button id="checkout_btn" className="btn btn-primary btn-block" onClick={proceedToPayment}>How to Pay</button>
            </div>
        </div>


    </div>

</Fragment>
  )
}

export default ConfirmOrder