import React, { useEffect } from 'react';
import Searchfabric from "../layout/Searchfabric";
import { Link } from 'react-router-dom';
import Product from '../product/Product';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel, caousel } from "react-bootstrap";
import { getProducts } from "../../actions/productActions";

const Kitambaa = ({ projectfabric }) => {

  const dispatch = useDispatch();

  const {  products } = useSelector((state) => state.products);

  useEffect(() => {

    dispatch(getProducts());


  }, [dispatch, alert])


  return (
    <div  className="page-list">
    <Carousel pause="hover">
      {projectfabric.images && projectfabric.images.map((image) => (
              <Carousel.Item interval={1800}>
              <img
                className="card-img-top mx-auto product-thumb"
                src={image.url} alt={projectfabric.title}
              ></img>
            </Carousel.Item>
      ))}

    </Carousel>

    <div className="product-body text-center py-1">
      <h5 className="title font-rale text-dark text-center font-size-16">
        <Link to={`/projectfabric/${projectfabric._id}`}>{projectfabric.name}</Link>
      </h5>

      <Link
        type="button"
        to={`/projectfabric/${projectfabric._id}`}
        id="see_btn"
        className="btn btn-block font-rubik-16 "
      >
        View Details
      </Link>

      <div className="big-relative">
        <button
          className="btn btn-block drop-buy"
          id="buy_btn"
      
        >
          Shop Materials & Tools
        </button>

        <div className="buy-dropdown-menu2">
          <small>Enter <bold className="font-size-16 font-rale">{projectfabric.name}</bold> to buy anything in this project</small>

         <div className="col-12  mt-2 mt-md-0 mb-2">
                    <Searchfabric />
                  </div>

                  <Link to={`/projectfabric/${projectfabric._id}`}>View details</Link>


        </div>
      </div>

    </div>
  </div>
  )
}

export default Kitambaa