import React, { Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import MetaData from '../layout/MetaData'

import CheckoutSteps from './CheckoutSteps'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { saveShippingInfo } from '../../actions/cartActions'

const Shipping = () => {

  const { shippingInfo } = useSelector((state) => state.cart)

  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo)
  const [city, setCity] = useState(shippingInfo.city)
  const [county, setCounty] = useState(shippingInfo.county)
  const [email, setEmail] = useState(shippingInfo.email)

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const submitHandler = (e) => {
    e.preventDefault()

    dispatch(saveShippingInfo({ phoneNo, city, county, email }))
    navigate('/order/confirm')
}




  return (

    <Fragment>
      <MetaData title={'Shipping info'} />

    <CheckoutSteps shipping /> 

      <div className="row wrapper">
    <div className="col-12 col-lg-5">
        <form className="shadow-lg" onSubmit={submitHandler}>
            <h1 className="mb-4">Shipping Info</h1>
            <div className="form-group">
                <label htmlFor="address_field">Phone no</label>
                <input
                   type="phone"
                   id="phone_field"
                    className="form-control"
                    value={phoneNo}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="city_field">City/Town/market</label>
                <input
                    type="text"
                    id="city_field"
                    className="form-control"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="phone_field">County</label>
                <input
                    type="text"
                    id="county_field"
                    className="form-control"
                    value={county}
                    onChange={(e) => setCounty(e.target.value)}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="postal_code_field">Email</label>
                <input
                    type="text"
                    id="email_field"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>


            <button
                id="shipping_btn"
                type="submit"
                className="btn btn-block py-3"
            >
                CONTINUE
                
                </button>
                

        </form>
    </div>
</div>
    </Fragment>

  )
}

export default Shipping