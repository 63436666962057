import React, { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import MetaData from '../layout/MetaData'
import Sidebar from './Sidebar'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { newProjectplant, clearErrors } from '../../actions/projectplantActions'
import { NEW_PROJECTPLANT_RESET } from '../../constants/projectplantConstants'

const NewProjectplant = () => {

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [fundi, setFundi] = useState('');
    const [images, setImages] = useState([]);
    const [imagesPreview, setImagesPreview] = useState([])


    const categories = [
        'cactus',
        'indoor palm',
        'hanging basket',
        'climbing plants', 
        'low light plants',
        'foliage plants',
        'flowering plants',
        'bulbous plants',
        'christmas plants',
        'tree type plants',
        'unusual plants',
        'office type plants',
        'succulents',
        'fern type plants',
        'living stone type plants',
        'low light plants',
        'large plants'
      ]


      const alert = useAlert();
      const dispatch = useDispatch();
      const navigate = useNavigate();
  
      const { loading, error, success } = useSelector((state) => state.newProjectplant);
  
      useEffect(() => {
  
          if (error) {
              alert.error(error);
              dispatch(clearErrors())
          }

          if(success) {
            navigate('/admin/projectplants');
            alert.success('Projectplant created succesfully')
            dispatch({ type: NEW_PROJECTPLANT_RESET })
          }
      }, [dispatch, alert, error, success, navigate])

      const submitHandler = (e) => {
        e.preventDefault();

        const data = { name, description, category, fundi, images }

        dispatch(newProjectplant(data))
    }

    const onChange = e => {

        const files = Array.from(e.target.files)

        setImagesPreview([]);
        setImages([]);
        files.forEach(file => {
            const reader = new FileReader();

            reader.onload = () => {
                if(reader.readyState === 2) {
                    setImagesPreview(oldArray => [...oldArray, reader.result]);
                    setImages(oldArray => [...oldArray, reader.result]);
                }
            }

            reader.readAsDataURL(file)   
        })
    }


  return (
    <Fragment>
    <MetaData title={'New Projectplant'} />
    <div className='row'>
        <div className='col-12 col-md-2'>
            <Sidebar/>
        </div>

        <div className='col-12 col-md-10'>
            <Fragment>
            <div className="wrapper my-5">
                            <form className="shadow-lg" onSubmit={submitHandler} encType='multipart/form-data'>
                                <h1 className="mb-4">New Plant project</h1>

                                <div className="form-group">
                                    <label htmlFor="name_field">Name</label>
                                    <input
                                        type="text"
                                        id="name_field"
                                        className="form-control"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="description_field">Description</label>
                                    <textarea className="form-control" id="description_field" rows="8" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="description_field">Fundis</label>
                                    <textarea className="form-control" id="description_field" rows="8" value={fundi} onChange={(e) => setFundi(e.target.value)}></textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="category_field">Category</label>
                                    <select className="form-control" id="category_field" value={category} onChange={(e) => setCategory(e.target.value)}>
                                        {categories.map(category => (
                                            <option key={category} value={category} >{category}</option>
                                        ))}

                                    </select>
                                </div>

                                <div className='form-group'>
                                    <label>Images</label>

                                    <div className='custom-file'>
                                        <input
                                            type='file'
                                            name='product_images'
                                            className='custom-file-input'
                                            id='customFile'
                                            onChange={onChange}
                                            multiple
                                        />
                                        <label className='custom-file-label' htmlFor='customFile'>
                                            Choose Images
                                     </label>
                                    </div>

                                    {imagesPreview.map(img => (
                                        <img src={img} key={img} alt="Images Preview" className="mt-3 mr-2" width="55" height="52" />
                                    ))}

                                </div>


                                <button
                                    id="login_button"
                                    type="submit"
                                    className="btn btn-block py-3"
                                    disabled={loading ? true : false}
                                >
                                    CREATE
                                </button>

                            </form>
                        </div>
            </Fragment>
        </div>
    </div>
   </Fragment>
  )
}

export default NewProjectplant
