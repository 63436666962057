import { 
    ALL_PROJECTARCHS_REQUEST, 
    ALL_PROJECTARCHS_SUCCESS, 
    ALL_PROJECTARCHS_FAIL, 

    ADMIN_PROJECTARCHS_REQUEST, 
    ADMIN_PROJECTARCHS_SUCCESS, 
    ADMIN_PROJECTARCHS_FAIL,
    
    NEW_PROJECTARCH_REQUEST, 
    NEW_PROJECTARCH_SUCCESS, 
    NEW_PROJECTARCH_RESET,
    NEW_PROJECTARCH_FAIL, 

    DELETE_PROJECTARCH_REQUEST, 
    DELETE_PROJECTARCH_SUCCESS, 
    DELETE_PROJECTARCH_RESET,
    DELETE_PROJECTARCH_FAIL, 

    UPDATE_PROJECTARCH_REQUEST, 
    UPDATE_PROJECTARCH_SUCCESS, 
    UPDATE_PROJECTARCH_RESET,
    UPDATE_PROJECTARCH_FAIL, 

    PROJECTARCH_DETAILS_REQUEST,
    PROJECTARCH_DETAILS_SUCCESS,
    PROJECTARCH_DETAILS_FAIL, 

    CLEAR_ERRORS 
} from "../constants/projectarchConstants";

export const projectarchsReducer = (state = { projectarchs: [] }, action) => {
    switch(action.type) {
        case ALL_PROJECTARCHS_REQUEST:
        case ADMIN_PROJECTARCHS_REQUEST:
            return {
                loading: true,
                projectarchs: [] 
            }
        case ALL_PROJECTARCHS_SUCCESS:
            return {
                loading: false,
                projectarchs: action.payload.projectarchs,
                projectarchsCount: action.payload.projectarchsCount,
                resPerPage: action.payload.resPerPage
               
            }

        case ADMIN_PROJECTARCHS_SUCCESS:
            return {
                loading: false,
                projectarchs: action.payload
                }
        case ALL_PROJECTARCHS_FAIL:
        case ADMIN_PROJECTARCHS_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const newProjectarchReducer = (state = { projectarch: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTARCH_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTARCH_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectarch: action.payload.projectarch
            } 
               
        case NEW_PROJECTARCH_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTARCH_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}


export const projectarchReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTARCH_REQUEST:
        case UPDATE_PROJECTARCH_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTARCH_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTARCH_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTARCH_FAIL:
        case UPDATE_PROJECTARCH_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTARCH_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTARCH_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}


export const projectarchDetailsReducer = (state = {projectarch: {} }, action ) => {
    switch (action.type) {

        case  PROJECTARCH_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTARCH_DETAILS_SUCCESS:
            return {
                loading: false,
                projectarch: action.payload
            }
        case  PROJECTARCH_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}