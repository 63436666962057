import React, { Fragment, useEffect, useState } from "react";
import { Carousel, caousel } from "react-bootstrap";
import Pagination from 'react-js-pagination'
import MetaData from "./layout/MetaData";
import Gypsum from "./ceiling/Gypsum";
import Loader from './layout/Loader'

import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getProjectceilings } from "../actions/projectceilingActions";

import { useParams } from "react-router-dom";

const Ceiling = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState('');

  const categories = [
      'Coffered ceiling',
                'Cove ceiling',
                'Beam ceiling',
                'Tray ceiling', 
                'Cathedral ceiling',
                'Post-mordern ceiling'
  ]
  const alert = useAlert();
  const dispatch = useDispatch();

  const params = useParams();

  const { loading, projectceilings, error, projectceilingsCount, resPerPage } = useSelector(
    (state) => state.projectceilings
  );

  const keyword = params.keyword;

  useEffect(() => {
    if(error) {
      return alert.error(error)
    }
    dispatch(getProjectceilings( currentPage, category));
  }, [dispatch, alert, error, currentPage, category]);

  function setCurrentPageNo(pageNumber) {
    setCurrentPage(pageNumber);
  }

  return (
    <Fragment>
      {loading ? <Loader /> : (
        <Fragment>
                <MetaData title={"Ceiling design"} />
      <div className="container container-fluid" >
      

        <div className="sticky2 bg-light">
          <div className="scrollable-tabs-container font-rale-16 bg-light">
          {/*}  <div className="left-arrow ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </div> */}
            <ul>
              {categories.map(category => (
                <li  style={{cursor: 'pointer'}} key={category} onClick={() => setCategory(category) } >{category}</li>
              ))}
           
            </ul>
        {/*}    <div className="right-arrow ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div> */}
          </div>
        </div>

        <div className="page-container">
          {projectceilings &&
            projectceilings.map((projectceiling) => (
              <Gypsum key={projectceiling._id} projectceiling={projectceiling} />
            ))}
        </div>
      </div>


      {resPerPage < projectceilingsCount && (
              <div className="d-flex justify-content-center mt-5">
              <Pagination
              activePage={currentPage}
              itemsCountPerPage={resPerPage}
              totalItemsCount={projectceilingsCount}
              onChange={setCurrentPageNo}
              nextPageText={'Next'}
              prevPageText={'Prev'}
              firstPageText={'First'}
              lastPageText={'Last'}
              itemClass="page-item"
              linkClass="page-link"
              >
      
              </Pagination>
            </div>
      )}

        </Fragment>
      )}

    </Fragment>
  );
};

export default Ceiling;
