import { 
    ALL_PROJECTPAINTINGS_REQUEST,
    ALL_PROJECTPAINTINGS_SUCCESS, 
    ALL_PROJECTPAINTINGS_FAIL, 

    ADMIN_PROJECTPAINTINGS_REQUEST, 
    ADMIN_PROJECTPAINTINGS_SUCCESS, 
    ADMIN_PROJECTPAINTINGS_FAIL,
    
    NEW_PROJECTPAINTING_REQUEST, 
    NEW_PROJECTPAINTING_SUCCESS, 
    NEW_PROJECTPAINTING_RESET,
    NEW_PROJECTPAINTING_FAIL, 

    DELETE_PROJECTPAINTING_REQUEST, 
    DELETE_PROJECTPAINTING_SUCCESS, 
    DELETE_PROJECTPAINTING_RESET,
    DELETE_PROJECTPAINTING_FAIL, 

    UPDATE_PROJECTPAINTING_REQUEST, 
    UPDATE_PROJECTPAINTING_SUCCESS, 
    UPDATE_PROJECTPAINTING_RESET,
    UPDATE_PROJECTPAINTING_FAIL, 

    PROJECTPAINTING_DETAILS_REQUEST,
    PROJECTPAINTING_DETAILS_SUCCESS,
    PROJECTPAINTING_DETAILS_FAIL,
    
    CLEAR_ERRORS 
 } from "../constants/projectpaintingConstants";

 export const projectpaintingsReducer = (state = { projectpaintings: [] }, action )=> {
    switch(action.type) {
        case ALL_PROJECTPAINTINGS_REQUEST:
        case ADMIN_PROJECTPAINTINGS_REQUEST:
            return {
                loading: true,
                projectpaintings: [] 
            }
        case ALL_PROJECTPAINTINGS_SUCCESS:
            return {
                loading: false,
                projectpaintings: action.payload.projectpaintings,
                projectpaintingsCount: action.payload.projectpaintingsCount,
                resPerPage: action.payload.resPerPage
               
            }

            case ADMIN_PROJECTPAINTINGS_SUCCESS:
                return {
                    loading: false,
                    projectpaintings: action.payload
                    }
        case ALL_PROJECTPAINTINGS_FAIL:
            case ADMIN_PROJECTPAINTINGS_FAIL:
            return {
               loading: false,
               error: action.payload 
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}


export const newProjectpaintingReducer = (state = { projectpainting: {} }, action ) => {
    switch(action.type) {
        case NEW_PROJECTPAINTING_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case NEW_PROJECTPAINTING_SUCCESS:
                
            return {
                loading: false,
                success: action.payload.success,
                projectpainting: action.payload.projectpainting
            } 
               
        case NEW_PROJECTPAINTING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROJECTPAINTING_RESET:
            return {
                ...state,
                success: false
            }    
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}

export const projectpaintingReducer = (state = {}, action ) => {
    switch(action.type) {
        case DELETE_PROJECTPAINTING_REQUEST:
        case UPDATE_PROJECTPAINTING_REQUEST:
            return {
                ...state,
                loading: true
            }
            
        case DELETE_PROJECTPAINTING_SUCCESS:    
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            } 

        case UPDATE_PROJECTPAINTING_SUCCESS:  
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            } 
               
        case DELETE_PROJECTPAINTING_FAIL:
        case UPDATE_PROJECTPAINTING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_PROJECTPAINTING_RESET:
            return {
                ...state,
                isDeleted: false
            }  
            
            case UPDATE_PROJECTPAINTING_RESET:
                return {
                    ...state,
                    isUpdate: false
                }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

       default:
        return state 
    }
}


export const projectpaintingDetailsReducer = (state = {projectpainting: {} }, action ) => {
    switch (action.type) {

        case  PROJECTPAINTING_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case  PROJECTPAINTING_DETAILS_SUCCESS:
            return {
                loading: false,
                projectpainting: action.payload
            }
        case  PROJECTPAINTING_DETAILS_FAIL:
            return {
                ...state,
                error: null
            }
        case  CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: 
             return state
    }
}