import React, { Fragment, useState, useEffect } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';


import Loader from "../layout/Loader";
import MetaData from "../layout/MetaData";


import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { login, clearErrors } from '../../actions/userActions'

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [visible, setVisible] = useState(false);

    const alert = useAlert();
    const dispatch = useDispatch();

    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { isAuthenticated, error, loading } = useSelector((state) => state.auth);

    //const search = new URLSearchParams(document.location.search)

   // const redirect = location.search ? `${location.search.split('=')[1]}` : '/'

    const redirect = location.search ? location.search.split('=')[1] : '/' 
    //const redirect = search.has('redirect') ? search.get('redirect') : '/'

   // const redirect = searchParams.get("redirect") || '/';

    useEffect(() => {

        if(isAuthenticated) {
           // navigate(`redirect`);
            navigate(`${redirect}`); 
         // navigate(`/${redirect}`, { replace: true });
        // navigate(`/${shipping}`, { redirect: true });
        }

        if(error) {
            alert.error(error);
            dispatch(clearErrors());
        }
    }, [dispatch, navigate, alert, isAuthenticated, error])

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(login(email, password))
    }
  return (
    <Fragment>
        {loading ? <Loader/> : (
            <Fragment>
               <MetaData title={'Login'} />

               <div className='row wrapper'>
                <div className='col-12 col-lg-5 '>
                    <form className='shadow-lg' onSubmit={submitHandler}>
                        <h1 className='mb-3'>Login</h1>
                        <div className='form-group'>
                            <label htmlFor="email_field">Email</label>
                            <input
                            type="email"
                            id="email_field"
                            class="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className='form-group'>
                            <label htmlFor="password_field">Password</label>
                            <input
                            type={visible ? "text" : "password"}
                            id="password_field"
                            class="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            />

<div className='p-2 macho' onClick={() => setVisible(!visible)}>
                            {
                                visible ? <EyeOutlined/> : <EyeInvisibleOutlined/>
                            }
                        </div>
                        </div>

                        <Link to ='/password/forgot' class="float-right mb-4">Forgot Password?</Link>

                        <button
                        id='login_button'
                        type='submit'
                        class='btn btn-block py-3'

                        >
                            LOGIN
                        </button>

                        <Link to='/register' class="float-right mt-3">New User?</Link>
                    </form>
                </div>
               </div>
            </Fragment>
        )}
    </Fragment>
  )
}

export default Login